import * as PIXI from 'pixi.js';
import { Point } from '../models';
import { environment } from '../../../../environments/environment';
import { Currency, GameStatus } from '../../../shared/enums';
import { PlayerStatus, VariantType2 } from '../../../shared/enums/poker-types';
import { Common } from './common';
import * as CONST from './player.const';
import { PlayerTimerStandard } from './player-timer-standard';
import { PlayerTimer } from './player-timer';
import { ChatBubble } from './chat-bubble';
import { CurrencyDeviderPipe } from '../../../shared/utils/currency-devider.pipe';
import { CurrencyPipe } from '../../../shared/utils/currency.pipe';
import { GameComponent } from '../game.component';
import { PlayerCardsController } from './cards-cotrollers/player-cards-controller';
import { CardData, PlayerSpellsEarnedSpell } from '../../../shared/models';
import { cardDecoder } from '../../../shared/helpers/card-decoder';
import { ProgressBar } from './progress-bars/progress-bar';
import { AnchorPosition } from './const';
import { PlayerBounty } from './player-bounty';
import { PlayerCardReplaceInfo } from './player-card-replace-info';
import { NoteColors } from '../../../shared/enums/note-color';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { CurrencyInfo } from '../../../shared/models/currency-info';
import { AssetsLoaderService } from '../../../core/services/assets-loader.service';
import { AnimatedGIF } from '@pixi/gif';
import { Howl } from 'howler';
import { interval, Observable, of, Subject, timer } from 'rxjs';
import { CallTimeStatus } from '../../../core/services/manager.service';
import { ca } from 'date-fns/locale';
import * as moment from 'moment-timezone';
import { ConfigService } from '../../../core/services/config.service';
import { BountySpellVariant, SpellStatus } from '../../../shared/models/bounty-spell';
import { SortCards } from '../../../shared/enums/sort-card';

export enum PlayerGameStatus {
    default = 'default',
    active = 'active',
    fold = 'fold',
    sitout = 'sitout',
    disconnected = 'disconnected',
    seating = 'seating'
}

export class Player {
    public id: number;
    public nameString: string;
    public realNameString: string;
    public money = 0;
    public currentCheckValue = 0;
    public currentAllIn = 0;
    public currentPot = 0;
    public numberOfCardsHolding = 0;
    public alreadyBetInThisRound = 0;
    public seatNumber: number;
    public status: PlayerStatus = PlayerStatus.Ready;

    private timer = 0;
    private normalTime: number;
    private bankTime: number;

    private currency: CurrencyInfo;
    private textures;
    private normalTimeExpired = false;
    private isMyTurn = false;
    private shouldRevertToDefaultStatus = false;
    private isLeftSided: boolean;
    private revertTimer = 0;
    private revertTime = 2000;

    public container: PIXI.Container;
    private timerGraphics: PlayerTimer | ProgressBar;

    private name: PIXI.Text;

    private moneyAmountText: PIXI.Text;
    private moneyAmountCurrencyText: PIXI.Text;
    private moneyAmountDecimalText: PIXI.Text;

    private amountStyle: PIXI.TextStyle;
    private amountDecimalStyle: PIXI.TextStyle;
    private amountCurrencyStyle: PIXI.TextStyle;



    private realName: PIXI.Text;

    private infoBoxSprite: PIXI.Sprite;
    private infoBoxBlinkSprite: PIXI.Sprite;
    // private infoBoxPositionRight: Point = { x: -12, y: 44 };

    private avatarSprite: PIXI.Sprite;
    private avatarBackgroundSprite: PIXI.Sprite;
    private avatarBackgroundBlinkSprite: PIXI.Sprite;

    private avatarMask: PIXI.Graphics;

    public dealer: PIXI.Sprite;
    public straddle: PIXI.Sprite;
    public nbRebuyUsed: PIXI.Sprite;
    public nbRebuyUsedCount: PIXI.Text
    public nbRebuy: number = 0;



    private colorMatrix: any;

    private timebankBackground: PIXI.Graphics;
    private timebankText: PIXI.Text;
    private timebankTimerText: PIXI.Text;
    amITheOne = false;

    private levelStars: PIXI.Sprite[] = [];
    private cardsConstructorParam;

    public hasFold: boolean;
    public gameComponent: GameComponent;
    private noteSprite: PIXI.Sprite;
    private noteText: string;
    private noteColor: string;
    private enumNoteColors = NoteColors
    private noteColors: string[] = Object.keys(this.enumNoteColors).slice(Object.keys(this.enumNoteColors).length / 2)


    cardsController: PlayerCardsController;
    private bounty: PlayerBounty;
    private bountyAmount: number;

    private blinkAlpha = 0;
    private blinkSpeed = 0.8; // lower is slower, higher is faster
    private blinkDirection = 1;

    private replacedCardsInfo: PlayerCardReplaceInfo[] = [];

    private proPlayer: boolean = false
    private proPlayerSprite: PIXI.Sprite;


    newVersion: boolean = true


    private position: Point;
    public avatarUrl: string;
    private level; // Show Player Rank
    private cards;




    private cardReplaceInfoBackground: PIXI.Graphics
    private cardReplaceInfoLine1: PIXI.Graphics
    private cardReplaceInfoLine2: PIXI.Graphics
    private cardReplaceInfoNo1: PIXI.Text
    private cardReplaceInfoNo2: PIXI.Text
    private cardReplaceInfoNo3: PIXI.Text

    private cardsControllerAdded: boolean;

    private useVertical: boolean;

    private showBigCardsActive: boolean;
    private reactionPlaying = false
    private assetsLoader;
    private emoticons: any[] = [];

    private bountyCurrency: CurrencyInfo


    private callTime: {
        background: PIXI.Sprite
        text: PIXI.Text,
        status: CallTimeStatus
    } = { background: null, text: null, status: null }

    private callTimeStatusAttention: PIXI.Sprite;
    private callTimeStatusCheck: PIXI.Sprite;

    private bigBlind: number;
    private stacksInBB: boolean;

    private config: any;


    // 27 Dec :
    private isSitOut: boolean = false;
    private shouldRevertToStacksTimer: number = 0;
    private shouldRevertToStacks: boolean = false;

    private isCelebrity: boolean = false;

    allSpells: any[] = []
    earnedSpells: any[] = [];

    public zoneGraphic;
    public zoneSprite: PIXI.Sprite;

    private renderer: PIXI.Renderer | PIXI.AbstractRenderer;

    spellContainer = new PIXI.Container();

    initBountySpell?: any;
    initEarnedSpells?: any;
    isSpellTournament?: boolean


    disconnectProtectionOverlay: PIXI.Graphics;
    disconnectProtectionOverlaySprite: PIXI.Sprite;
    disconnectProtectionIconSprite: PIXI.Sprite;

    disconnectProtectionText: PIXI.Text;
    disconnectProtectionTimer: PIXI.Text;
    disconnectProtectionExpiresAt: moment.Moment;

    country: string;

    moreSpellSprite: PIXI.Sprite;
    isNoSpell: boolean = false;

    avatarSeatChangeSprite: PIXI.Sprite;
    sortCards: SortCards = SortCards.DEFAULT;

    constructor(
        renderer: PIXI.Renderer | PIXI.AbstractRenderer,
        textures: any,
        id: number,
        seatNumber: number,
        position: Point,
        name: string,
        avatarUrl: string,
        moneyAmount: number,
        normalTime: number,
        currency: CurrencyInfo,
        gameComponent: GameComponent,
        useVertical: boolean,
        bigBlind: number,
        stacksInBB: boolean,
        config: any,
        isCelebrity: boolean,
        country: string,

        cards?: any[],
        level?: number,
        hasFold?: boolean,
        bountyAmount?: number,
        realName?: string,
        mark?: number,
        assetsLoader?: AssetsLoaderService,
        emoticons?: any[],
        bountyCurrency?: CurrencyInfo,

        bountySpell?: any,
        earnedSpells?: any,
        isSpellTournament?: boolean
    ) {
        this.renderer = renderer;
        // ######################
        // ## LOAD DATA FROM CONSTRUCTOR
        // ######################
        this.textures = textures;
        this.id = id;
        this.seatNumber = seatNumber;
        this.position = position;
        this.nameString = name;
        this.avatarUrl = avatarUrl;
        this.money = moneyAmount ?? 0;
        this.normalTime = normalTime * 1000; //## Multiply normal time to get value in ms
        this.currency = currency;
        this.gameComponent = gameComponent;
        this.useVertical = useVertical;

        this.bigBlind = bigBlind;
        this.stacksInBB = stacksInBB;
        this.config = config;

        this.cards = cards;
        this.level = level;
        this.hasFold = hasFold === undefined ? false : true;
        this.bountyAmount = bountyAmount;
        this.realNameString = realName;
        this.proPlayer = mark === 1;
        this.assetsLoader = assetsLoader
        this.emoticons = emoticons
        this.bountyCurrency = bountyCurrency

        this.isCelebrity = isCelebrity
        this.country = country;
        // ======================
        this.initBountySpell = bountySpell
        this.initEarnedSpells = earnedSpells
        this.isSpellTournament = isSpellTournament
        /// ===


        console.log("@set player")
        this.setPlayer();



    }

    setSortCards(sortCards: SortCards) {
        this.sortCards = sortCards
        this.cardsController.setSortCards(sortCards)
    }
    showSeatChange() {
        this.avatarSeatChangeSprite.visible = true;
    }
    hideSeatChange() {
        this.avatarSeatChangeSprite.visible = false;
    }
    setPlayer(fakeSeatNumber?: number) {
        if (isNaN(fakeSeatNumber)) {
            // ## Get Player Position
            this.isLeftSided = Common.isLeftSided(this.seatNumber, this.gameComponent.getMaxPlayers(), this.useVertical);
            // ## Create Container
            this.container = new PIXI.Container();
        } else {
            // ## Get Player Position
            this.isLeftSided = Common.isLeftSided(fakeSeatNumber, this.gameComponent.getMaxPlayers(), this.useVertical);

            // ## Get Cards from cardsController before delete all container layers
            this.cards = this.cardsController.cards.map(el => el.cardData)

            // ## Empty
            this.container.removeChildren();
            this.cardsControllerAdded = false
        }

        // this.isLeftSided = false // TEST

        this.container.position.set(this.position.x - CONST.containerSize.x / 2, this.position.y - CONST.containerSize.y / 2);


        // ## Player zone
        this.zoneGraphic = new PIXI.Graphics();
        this.zoneGraphic.beginFill(0xFF0000, 0.5);
        this.zoneGraphic.drawRoundedRect(
            0, // x
            0, // y
            550, // Width
            400, // Height
            0 // Radius
        );
        this.zoneGraphic.endFill()

        const zoneTexture = this.renderer.generateTexture(this.zoneGraphic)
        this.zoneSprite = new PIXI.Sprite(zoneTexture);
        this.zoneSprite.interactive = true;
        this.zoneSprite.buttonMode = true;
        this.zoneSprite.cursor = 'pointer';


        this.zoneSprite.on('mousedown', (event) => {
            const onClickColorFilter = new PIXI.filters.ColorMatrixFilter();
            onClickColorFilter.sepia(false); // Adjust brightness
            onClickColorFilter.contrast(1.3, false); // Add a bit of greyscale
            onClickColorFilter.saturate(1.5, false); // Apply hue shift
            this.infoBoxSprite.filters = [onClickColorFilter];
            this.avatarSprite.filters = [onClickColorFilter];


            if (this.gameComponent.getMemberProfile().Id !== this.id || this.isSpellTournament) {
                this.gameComponent.clickPlayer(this.id, this.noteText, this.noteColor, this.nameString);
            }
        });

        this.zoneSprite.on('mouseup', (event) => {
            this.infoBoxSprite.filters = [];
            this.avatarSprite.filters = [];

            if (this.spellContainer.visible === true) {
                this.spellContainer.visible = false
            } else {
                if (this.earnedSpells?.length > 0) {
                    this.spellContainer.visible = true
                }
            }
        });


        this.zoneSprite.on('mouseupoutside', (event) => {
            this.infoBoxSprite.filters = [];
            this.avatarSprite.filters = [];
            console.log("## here 111")
            this.spellContainer.visible = false

        });


        this.zoneSprite.on('touchendoutside', (event) => {
            this.infoBoxSprite.filters = [];
            this.avatarSprite.filters = [];
            this.spellContainer.visible = false
            console.log("## here 333")


        });
        this.zoneSprite.on('touchstart', (event) => {
            console.log("@touch Start")

            const onClickColorFilter = new PIXI.filters.ColorMatrixFilter();
            onClickColorFilter.sepia(false); // Adjust brightness
            onClickColorFilter.contrast(1.3, false); // Add a bit of greyscale
            onClickColorFilter.saturate(1.5, false); // Apply hue shift
            this.infoBoxSprite.filters = [onClickColorFilter];
            this.avatarSprite.filters = [onClickColorFilter];

            if (this.gameComponent.getMemberProfile().Id !== this.id || this.isSpellTournament) {
                this.gameComponent.clickPlayer(this.id, this.noteText, this.noteColor, this.nameString);
            }


        });
        this.zoneSprite.on('touchend', (event) => {
            console.log("## here 444")

            this.infoBoxSprite.filters = [];
            this.avatarSprite.filters = [];

            if (this.spellContainer.visible === true) {
                this.spellContainer.visible = false
            } else {
                if (this.earnedSpells?.length > 0) {
                    this.spellContainer.visible = true
                }
            }
        });


        if (this.isSpellTournament) {
            this.zoneSprite.alpha = 0, //0.9
                this.container.addChild(this.zoneSprite)
        }

        // ## Cards Controller
        if (isNaN(fakeSeatNumber)) {
            this.cardsController = new PlayerCardsController(this.textures, this.isLeftSided, this.gameComponent.getVariant(), this.gameComponent, this.sortCards);

            this.cardsController.container.scale.set(0.9)
        } else {

            this.cardsController.changeSide(this.isLeftSided);
        }

        this.container.addChild(this.cardsController.container);
        this.cardsControllerAdded = true


        // ## Avatar
        this.createAvatar();

        // ## Info Box Main
        this.createInfoBox();

        // ## Info Text [Player name, Amount]
        this.createInfoText();


        this.updateBalance(this.money)


        /// ## Time Bank [Text, Progress bar]
        this.createTimebankElements();

        // ## Dealer
        this.createDealerButton();

        // ## Display Straddle
        this.createStraddleDisplay();

        // ## Display NbRebuyUsed
        this.createNbRebuyUsedDisplay();


        // ## Player Star Rank
        if (false) { //environment.settings.showPlayerRank
            this.createStars(this.level);
        }

        // ## Create Grayscale filter
        this.colorMatrix = new PIXI.filters.ColorMatrixFilter();
        this.colorMatrix.greyscale(0.4, false);


        // ## Remove all cards because of the rearrange elements
        if (!isNaN(fakeSeatNumber)) {
            this.cardsController.removeAllCards();
        }
        // ## If I already have cards, set them
        if (this.cards !== undefined && this.cards.length && !this.hasFold) {
            this.receiveCards(this.cards);

            // // If it's my cards
            // if (!this.cards[0].IsHidden) {
            //     this.receiveCards(this.cards);
            // } else { // If it's other player cards

            //     console.log("@ getVariant", this.gameComponent.getVariant())
            //     this.setHiddenCards(this.cards.length);
            //     this.moveAllCardsToBack();
            // }
        }

        // ## Bonuty / Target
        if (this.bountyAmount) {
            this.showBounty(this.bountyAmount, this.isCelebrity);
        }

        // ## 2-7 triple draw [ Standpat / Discard ]
        this.createCardReplaceInfo();

        // ## Pro Player set by admin
        if (this.proPlayer) {
            const proPlayerCircle = new PIXI.Graphics();
            proPlayerCircle.beginFill(0x000000, 0.6);
            proPlayerCircle.lineStyle(0);

            if (this.isLeftSided) {
                proPlayerCircle.drawCircle(194, 42, 35);
            } else {
                proPlayerCircle.drawCircle(355, 42, 35);
            }


            this.container.addChild(proPlayerCircle);

            this.proPlayerSprite = new PIXI.Sprite(this.textures[`playerProMark`].texture);
            this.proPlayerSprite.anchor.set(0.5, 0.5);
            this.proPlayerSprite.scale.set(0.65);

            if (this.isLeftSided) {
                this.proPlayerSprite.position.set(194, 42);
            } else {
                this.proPlayerSprite.position.set(355, 42);
            }

            this.container.addChild(this.proPlayerSprite);
        }


        // call time

        this.callTime.background = new PIXI.Sprite(this.textures[`callTimeBackground`].texture);
        this.callTime.background.anchor.set(0.5, 0.5);
        this.callTime.background.position.set(275, 25);

        // this.callTime.background.beginFill(0xFC4848, 1);
        // this.callTime.background.lineStyle(0);
        // this.callTime.background.drawCircle(275, 25, 35);
        this.callTime.background.alpha = 0
        this.container.addChild(this.callTime.background);

        this.callTime.text = new PIXI.Text('', {
            fontFamily: 'Saira Semi Condensed',
            fontSize: 40,
            fill: '#333333',
            fontWeight: '500'
        });
        this.callTime.text.anchor.set(0.5, 0.5);
        this.callTime.text.position.set(275, 25);
        this.callTime.text.alpha = 0
        this.container.addChild(this.callTime.text);

        this.callTimeStatusAttention = new PIXI.Sprite(this.textures[`callTimeAttention`].texture);
        this.callTimeStatusAttention.anchor.set(0.5, 0.5);
        this.callTimeStatusAttention.position.set(275, 25);
        this.callTimeStatusAttention.alpha = 0

        this.container.addChild(this.callTimeStatusAttention);

        this.callTimeStatusCheck = new PIXI.Sprite(this.textures[`callTimeCheck`].texture);
        this.callTimeStatusCheck.anchor.set(0.5, 0.5);
        this.callTimeStatusCheck.position.set(275, 25);
        this.callTimeStatusCheck.alpha = 0

        this.container.addChild(this.callTimeStatusCheck);


        console.log('@Player INIT', this.initBountySpell, this.initEarnedSpells)

        if (this.isSpellTournament) {
            if (this.initBountySpell && this.initBountySpell.ShowSpell) {
                this.setBountySpell(this.initBountySpell.SpellVariant, this.initBountySpell.Status)
            }

            if (this.initEarnedSpells) {
                this.addEarnedBounty(this.initEarnedSpells)
            }
        }


        try {


            if (this.country) {
                // Country Flag
                const countryFlag = new PIXI.Sprite(this.textures[this.country.toLocaleLowerCase().split(' ').join('_')].texture);
                countryFlag.anchor.set(0.5, 0.5);
                countryFlag.position.set(90, 285);
                this.container.addChild(countryFlag);

            }
        } catch (error) {
            console.log("Error in country flag", error)
        }


        // ===
        //this.startDisconnectProtection(60)
    }

    startDisconnectProtection(expiresSeconds: number) {
        this.disconnectProtectionExpiresAt = moment.utc().add(expiresSeconds, 'seconds')
        this.disconnectProtectionText.visible = true;
        this.disconnectProtectionTimer.visible = true;
        // this.disconnectProtectionOverlay.visible = true;
        this.disconnectProtectionOverlaySprite.visible = true;
        this.disconnectProtectionIconSprite.visible = true;

    }

    update(dt) {
        if (this.disconnectProtectionExpiresAt) {
            const diff = this.disconnectProtectionExpiresAt.diff(moment.utc())
            const formatTime = moment.utc(diff).format("mm:ss");
            if (diff < 0) {
                this.removeDisconnectProtection();
            } else {
                this.disconnectProtectionTimer.text = formatTime;
            }

        }
        if (this.bounty) {
            this.bounty.update(dt)
        }
        if (this.callTime.status) {


            const activatedAt = moment.utc(this.callTime.status.ActivatedAt)
            const expiresAt = activatedAt.add(this.callTime.status.CallTimeDuration, 'seconds')
            const duration = moment.duration(expiresAt.diff(moment.utc())).asMilliseconds()

            // const expirationDate = new Date(this.callTime.status.ActivatedAt.getTime() + this.callTime.status.CallTimeDuration * 1000)//+1min
            // const expirationDateUTC = new Date(expirationDate.getTime() + expirationDate.getTimezoneOffset() * 60000)

            const timer = this.dhmsCountdownTimer(duration)
            this.callTime.text.text = `${timer}`
            if (!this.callTime.status.CanLeaveTable && !this.callTime.status.IsActivated) {
                this.callTimeStatusAttention.alpha = 1
                this.callTimeStatusCheck.alpha = 0

                this.callTime.text.alpha = 0
                this.callTime.background.alpha = 0
            } else if (!this.callTime.status.CanLeaveTable && timer > 0) {
                this.callTimeStatusAttention.alpha = 0
                this.callTimeStatusCheck.alpha = 0

                this.callTime.text.alpha = 1
                this.callTime.background.alpha = 1

            } else if (this.callTime.status.IsActivated && (this.callTime.status.CallTimeExpired || timer === 0) && expiresAt.year() > 1) {
                // Expired State
                this.callTimeStatusAttention.alpha = 0
                this.callTimeStatusCheck.alpha = 1

                this.callTime.text.alpha = 0
                this.callTime.background.alpha = 0


            } else {
                // Idle State
                this.callTimeStatusAttention.alpha = 0
                this.callTimeStatusCheck.alpha = 0

                this.callTime.text.alpha = 0
                this.callTime.background.alpha = 0
            }



            // if (timer === 0) {
            //     this.callTime.status = null
            // }
        } else {
            // Idle State
            this.callTimeStatusAttention.alpha = 0
            this.callTimeStatusCheck.alpha = 0

            this.callTime.text.alpha = 0
            this.callTime.background.alpha = 0
        }

        // @ 27 Dec
        // console.log("@@ this.processShouldSetMoney", this.processShouldSetMoney, this.shouldRevertToStacks)
        if (this.isSitOut) {
            this.shouldRevertToStacksTimer += dt;
            if (this.shouldRevertToStacksTimer >= 5000) {
                this.shouldRevertToStacksTimer = 0;

                if (this.shouldRevertToStacks) {
                    this.setMoney()
                } else {
                    this.moneyAmountText.text = this.checkForStatusLength('Sitout');
                    this.moneyAmountText.visible = true
                    this.moneyAmountDecimalText.visible = false
                    this.moneyAmountCurrencyText.visible = false
                    this.shouldRevertToStacks = true
                }

            }
        }

        if (this.isMyTurn) {
            this.timer += dt;
            if (!this.normalTimeExpired) {
                if (this.timer <= this.normalTime) {
                    this.timerGraphics.update(1 - (this.timer / this.normalTime));
                } else {
                    this.timerGraphics.update(0);

                }
            } else {
                if (this.timer <= this.bankTime) {
                    this.timerGraphics.update(1 - (this.timer / this.bankTime));
                    this.timebankTimerText.text = Math.round((this.bankTime - this.timer) / 1000).toString();
                } else {
                    this.timerGraphics.update(0);
                }
            }
            if (this.blinkAlpha <= 0) {
                this.blinkDirection = 1
                this.blinkAlpha = 0
            } else if (this.blinkAlpha >= 1) {
                this.blinkDirection = -1
                this.blinkAlpha = 1

            }


            if (this.blinkDirection === 1) {
                this.blinkAlpha += (dt / 1000) * this.blinkSpeed;
            } else {
                this.blinkAlpha -= (dt / 1000) * this.blinkSpeed;

            }
            // this.blinkAlpha -= (dt / 1000) * this.blinkSpeed;

            this.infoBoxBlinkSprite.alpha = this.blinkAlpha;
            this.avatarBackgroundBlinkSprite.alpha = this.blinkAlpha;

        }

        if (this.shouldRevertToDefaultStatus) {
            this.revertTimer += dt;
            if (this.revertTimer >= 2000) {
                // this.setStatus(PlayerGameStatus.default);
                this.setStatusText(this.checkForNameLength());
                this.shouldRevertToDefaultStatus = false;
                this.revertTimer = 0;
            }
        }

        // update all cards
        this.cardsController.update(dt);

        if (this.cardsControllerAdded && !this.showBigCardsActive) {
            this.updateCardControllIndex();

        }
    }

    displayStraddle(display: boolean) {

    }

    rearrangeElements(position: Point, useVertical: boolean, fakeSeatNumber: number) {

        this.position = position;
        this.useVertical = useVertical;
        this.setPlayer(fakeSeatNumber)
    }

    setCardSelection(status: boolean) {
        this.cardsController.setCardSelection(status)


        this.infoBoxSprite.off('mousedown')
        this.infoBoxSprite.off('touchstart')

        if (this.gameComponent.getMemberProfile().Id === this.id && (this.gameComponent.handsetPortrait || this.gameComponent.handsetLandscape)) {
            if (status) {

                this.infoBoxSprite.interactive = true;
                this.infoBoxSprite.buttonMode = true;
                this.infoBoxSprite.cursor = 'pointer';
                this.infoBoxSprite.on('mousedown', (event) => {

                    event.stopPropagation();
                    if (this.showBigCardsActive) {
                        this.hideBigCards()
                    } else {
                        this.showBigCards()
                    }
                });
                this.infoBoxSprite.on('touchstart', (event) => {
                    event.stopPropagation();

                    if (this.showBigCardsActive) {
                        this.hideBigCards()
                    } else {
                        this.showBigCards()
                    }
                });
            } else {

                this.infoBoxSprite.interactive = false;
                this.infoBoxSprite.buttonMode = false;
                this.infoBoxSprite.off('mousedown')
                this.infoBoxSprite.off('touchstart')

                this.hideBigCards()

            }
        }
    }

    setTimer(newTime: number) {
        console.log("🟣 setTimer :: ", newTime);
        this.timerGraphics.update(1);
        this.timerGraphics.reset();

        this.timer = this.normalTime - newTime * 1000;
    }

    takeTurn(isReplay?: boolean) {
        console.log("START TURN", this.id, this.nameString)

        if (isReplay !== undefined) {
            return;
        }
        this.isMyTurn = true;
        this.timerGraphics.container.visible = true;

        this.infoBoxBlinkSprite.visible = true;
        this.avatarBackgroundBlinkSprite.visible = true;

        if (this.amITheOne) {
            this.timebankText.visible = true;
            this.timebankBackground.visible = true;
        }
    }

    endTurn() {
        console.log("END TURN", this.id, this.nameString)
        this.isMyTurn = false;
        this.stopTimer()
    }

    stopTimer() {
        this.normalTimeExpired = false;

        this.timer = 0;
        this.timerGraphics.changeToDefaultColor();
        this.timerGraphics.update(1);
        this.timerGraphics.reset();
        this.timerGraphics.container.visible = false;

        this.timebankText.visible = false;
        this.timebankTimerText.visible = false;
        this.timebankBackground.visible = false;
        this.infoBoxBlinkSprite.visible = false;
        this.avatarBackgroundBlinkSprite.visible = false;

        this.blinkAlpha = 1;
    }

    setPlayerLevel(playerLevel: number) {
        if (true) { return; } //environment.settings.showPlayerRank
        if (playerLevel === undefined || playerLevel === 0) { return; }
        this.levelStars.forEach((star, index) => {
            star.tint = CONST.starsColors[playerLevel - 1];
            if (index < playerLevel) {
                star.visible = true;
            } else {
                star.visible = false;
            }
        });
    }

    removeDisconnectProtection() {
        this.disconnectProtectionText.visible = false;
        this.disconnectProtectionTimer.visible = false;
        // this.disconnectProtectionOverlay.visible = false;
        this.disconnectProtectionOverlaySprite.visible = false;
        this.disconnectProtectionIconSprite.visible = false;


        this.disconnectProtectionExpiresAt = undefined;
    }

    setStatus(playerGameStatus: PlayerGameStatus) {

        this.removeDisconnectProtection();

        if (this.isSitOut) {
            this.isSitOut = false;
            this.setMoney()
        }

        switch (playerGameStatus) {
            case PlayerGameStatus.seating:

                this.infoBoxSprite.texture = this.textures['playerDefault'].texture;
                this.avatarBackgroundSprite.texture = this.textures['avatarDefault'].texture;
                this.name.style = CONST.nameDefaultStyle;
                this.moneyAmountText.style = this.amountStyle;
                this.infoBoxSprite.filters = [];
                this.avatarSprite.filters = [];
                // this.name.text = this.checkForNameHiding();
                break;
            case PlayerGameStatus.active:
                this.infoBoxSprite.texture = this.textures['playerActive'].texture;
                this.avatarBackgroundSprite.texture = this.textures['avatarActive'].texture;

                this.name.style = CONST.nameActiveStyle;
                this.moneyAmountText.style = CONST.balanceActiveStyle;
                this.infoBoxSprite.filters = [];
                this.avatarSprite.filters = [];
                this.moneyAmountText.visible = true;
                this.moneyAmountDecimalText.visible = true;
                this.moneyAmountCurrencyText.visible = true;
                this.name.text = this.checkForNameLength();
                break;
            case PlayerGameStatus.fold:
                this.infoBoxSprite.texture = this.textures['playerFold'].texture;
                this.avatarBackgroundSprite.texture = this.textures['avatarFold'].texture;

                this.name.style = CONST.nameDefaultStyle;
                this.moneyAmountText.style = this.amountStyle;
                break;
            case PlayerGameStatus.sitout:
                this.infoBoxSprite.texture = this.textures['playerSitout'].texture;
                this.avatarBackgroundSprite.texture = this.textures['avatarSitout'].texture;

                this.name.style = CONST.nameDefaultStyle;
                this.moneyAmountText.style = this.amountStyle;
                this.infoBoxSprite.filters = [this.colorMatrix];
                this.avatarSprite.filters = [this.colorMatrix];

                this.moneyAmountText.text = this.checkForStatusLength('Sitout');
                this.isSitOut = true;
                this.shouldRevertToStacks = true;

                this.moneyAmountDecimalText.visible = false;
                this.moneyAmountCurrencyText.visible = false;
                break;
            case PlayerGameStatus.disconnected:
                this.infoBoxSprite.texture = this.textures['playerDisconnected'].texture;
                this.avatarBackgroundSprite.texture = this.textures['avatarDisconnected'].texture;

                this.name.style = CONST.nameDefaultStyle;
                this.moneyAmountText.style = this.amountStyle;
                this.infoBoxSprite.filters = [];
                this.avatarSprite.filters = [];
                this.moneyAmountText.text = this.checkForStatusLength('Disconnected');


                this.moneyAmountDecimalText.visible = false;
                this.moneyAmountCurrencyText.visible = false;
                break;
            default:
                this.infoBoxSprite.texture = this.textures['playerDefault'].texture;
                this.avatarBackgroundSprite.texture = this.textures['avatarDefault'].texture;

                this.name.style = CONST.nameDefaultStyle;
                this.moneyAmountText.style = this.amountStyle;
                this.infoBoxSprite.filters = [];
                this.avatarSprite.filters = [];
                // this.checkForNameDisplay();

                break;
        }
    }


    foldCards() {
        this.hasFold = true;
        this.cardsController.removeAllCards();
        this.moveAllCardsToBack();
        this.numberOfCardsHolding = 0;

        this.stopTimer();
    }

    setHiddenCards(numberOfCards) {

        this.cardsController.addHiddenCards(numberOfCards, this.gameComponent.getVariant() === VariantType2.SevenStud || this.gameComponent.getVariant() === VariantType2.SevenStudHiLo);
    }

    removeCards() {

        this.hasFold = false;
        this.numberOfCardsHolding = 0;
        this.cardsController.removeAllCards();
        this.moveAllCardsToBack();
    }

    updateBigBlind(bigBlind: number) {
        this.bigBlind = bigBlind;
        this.setMoney()
    }

    updateStacksInBB(stacksInBB: boolean) {
        this.stacksInBB = stacksInBB;
        this.setMoney()
    }
    private setMoney() {
        let moneyAmountText = ''
        this.shouldRevertToStacks = false

        if (this.stacksInBB) {
            const amount = this.money / this.bigBlind

            const moneyAmountData = amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });


            this.moneyAmountText.text = moneyAmountData.split('.')[0] + '.';

            this.moneyAmountDecimalText.text = moneyAmountData.split('.')[1]
            this.moneyAmountCurrencyText.text = 'BB'

            this.moneyAmountDecimalText.position.x = 274 + this.moneyAmountText.width / 2
            this.moneyAmountCurrencyText.position.x = 274 - this.moneyAmountText.width / 2 - 5 // 5px is space

        } else {

            let moneyAmountData;

            if (this.currency) {
                moneyAmountData = CurrencyPipe.prototype.transform(CurrencyDeviderPipe.prototype.transform(this.money, this.currency), this.currency, true)
            } else {
                let moneyText = this.money.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

                moneyAmountData = {
                    withoutDecimal: moneyText.split('.')[0],
                    decimal: moneyText.split('.')[1],
                    symbol: ''
                }
            }


            this.moneyAmountText.text = moneyAmountData.withoutDecimal + (this.currency && moneyAmountData.decimal ? '.' : '');

            this.moneyAmountDecimalText.text = this.currency && moneyAmountData.decimal ? moneyAmountData.decimal : '';
            this.moneyAmountCurrencyText.text = moneyAmountData.symbol;


            this.moneyAmountDecimalText.position.x = 274 + this.moneyAmountText.width / 2
            this.moneyAmountCurrencyText.position.x = 274 - this.moneyAmountText.width / 2 - 5 // 5px is space

        }

        this.moneyAmountText.visible = true
        this.moneyAmountDecimalText.visible = true
        this.moneyAmountCurrencyText.visible = true

        if (this.money > 0) {
            this.name.text = this.checkForNameHiding();
        }
    }
    updateBalance(balance: number) {
        if (balance === undefined) {
            this.money = 0;
        } else {
            this.money = balance;
        }
        this.setMoney()

    }

    showCards(cards) {
        this.cardsController.removeAllCards();
        const cardsData: CardData[] = [];
        cards.forEach(card => {
            const cardData: CardData = {
                Suit: card.Suit,
                Number: card.Number,
                Name: cardDecoder(card.Suit, card.Number),
                IsRabbitHunting: card.IsRabbitHunting, IsPublic: card.IsPublic

            };
            cardsData.push(cardData);
        });


        console.log("@@ addCards (1)",)

        this.cardsController.addCards(cardsData, this.gameComponent.getVariant() === VariantType2.SevenStud || this.gameComponent.getVariant() === VariantType2.SevenStudHiLo);
        this.moveCardToFront();
    }

    receiveCards(cards) {

        console.log("receiveCards @1a", cards)
        this.cardsController.removeAllCards();
        const cardsData: CardData[] = [];
        cards.forEach(card => {
            const cardData: CardData = {
                Suit: card.Suit,
                Number: card.Number,
                Name: cardDecoder(card.Suit, card.Number),
                IsHidden: card.IsHidden,
                IsRabbitHunting: card.IsRabbitHunting,
                IsPublic: card.IsPublic
            };
            cardsData.push(cardData);
        });
        console.log("receiveCards @1ab", cardsData)

        this.cardsController.addCards(cardsData, this.gameComponent.getVariant() === VariantType2.SevenStud || this.gameComponent.getVariant() === VariantType2.SevenStudHiLo);
        this.moveCardToFront();
    }


    // ## Control cards z-index position
    updateCardControllIndex() {

        const myPlayer = this.gameComponent.getMemberProfile().Id === this.id
        if (myPlayer || this.cardsController.showCards) {
            let index;
            let n = 0;
            for (const child of this.container.children) {

                const texture = child['texture']
                if (texture) {
                    if (texture.baseTexture.resource?.url?.includes('avatar_active')) {
                        index = n;
                    }
                }
                n++
            }

            this.container.setChildIndex(this.cardsController.container, index + 1); // (6)
        } else {

            this.container.setChildIndex(this.cardsController.container, 0);
        }


    }

    showBigCards() {
        this.showBigCardsActive = true;
        this.container.setChildIndex(this.cardsController.container, this.container.children.length - 1);
        this.cardsController.showBigCards()
    }


    hideBigCards() {

        this.showBigCardsActive = false;
        this.container.setChildIndex(this.cardsController.container, 4);
        this.cardsController.hideBigCards()
    }


    moveAllCardsToBack() {
        this.container.setChildIndex(this.cardsController.container, 0);
    }

    moveCardToFront() {

        this.container.setChildIndex(this.cardsController.container, 4);

    }

    setBankTime(bankTime: number = 0) {

        this.bankTime = bankTime * 1000;
        this.timebankText.text = 'Timebank: ' + bankTime.toString();
    }

    startUsingTimeBank(bankTime: number) {
        console.log("TURN @startUsingTimeBank", this.nameString, this.isMyTurn)

        this.removeDisconnectProtection();

        this.bankTime = bankTime * 1000;
        this.normalTimeExpired = true;
        this.timer = 0;

        this.timerGraphics.changeToTimebankColor();

        this.timebankText.text = 'Timebank: ' + bankTime.toString();
        this.timebankTimerText.text = bankTime.toString();
        this.timebankTimerText.visible = true;

        this.timerGraphics.reset();

        // '24 added
        if (this.isMyTurn) {


        }

    }

    getPlayerCardsInShortNotation(): string {
        return this.cardsController.getCardsInShortNotation();
    }


    loadSound(soundUrl: string): Promise<Howl> {
        return new Promise((resolve, reject) => {
            const sound = new Howl({ src: [soundUrl] });


            if (sound.state() === 'loaded') {
                resolve(sound);
            } else if (sound.state() === 'unloaded') {
                resolve(sound);
            } else {
                sound.once('load', () => {
                    resolve(sound);
                })
            }

        });
    }
    async chatMessage(message: string, emoticonId: number) {

        let bubblePos: Point;
        if (this.isLeftSided) {
            bubblePos = CONST.chatBubblePositionLeft;
        } else {
            bubblePos = CONST.chatBubblePositionRight;
        }

        if (!emoticonId) {
            const chatBubble = new ChatBubble(this.textures, message, bubblePos);
            this.container.addChild(chatBubble.container);
        } else if (!this.reactionPlaying) {
            this.reactionPlaying = true;


            const emoticon = this.emoticons.find(el => el.Id === emoticonId)

            const sound = await this.loadSound(emoticon.SoundUrl)


            if (emoticon.Type === 'text') {

                const chatBubble = new ChatBubble(this.textures, emoticon.Text, bubblePos);
                sound.play();

                this.container.addChild(chatBubble.container);
                setTimeout(() => {
                    this.reactionPlaying = false
                }, 2000);
            } else {


                fetch(emoticon.ImageUrl)
                    .then(res => res.arrayBuffer())
                    .then(AnimatedGIF.fromBuffer)
                    .then(image => {

                        const SIZE = 300
                        image.anchor.set(0.5);
                        image.position.set(275, 120);
                        image.height = (image.height * SIZE) / image.width
                        image.width = SIZE;
                        image.loop = true

                        image.onLoop = () => {
                            image.stop()
                            this.container.removeChild(image);
                            this.reactionPlaying = false
                        }

                        image.currentFrame = 1
                        image.play()
                        sound.play();

                        this.container.addChild(image);
                    })
                    .catch(err => {



                        this.reactionPlaying = false
                    })
            }
        }


    }


    setCallTime(callTimeStatus: CallTimeStatus) {
        this.callTime.status = callTimeStatus
    }

    getNumbersOfCards(): number {
        return this.cardsController.cards.length;
    }

    showNote(noteText: string, noteColor: string) {
        console.log("noteColor", noteColor, `playerNoteIcon${noteColor ? noteColor : this.noteColors[0]}`)
        try {


            this.noteText = noteText;
            this.noteColor = noteColor;

            if (this.noteSprite) { return; }
            this.noteSprite = new PIXI.Sprite(this.textures[`playerNoteIcon${noteColor ? noteColor : this.noteColors[0]}`].texture);
            this.noteSprite.anchor.set(0.5);
            if (this.isLeftSided) {
                this.noteSprite.position.set(95, 355);
            } else {
                this.noteSprite.position.set(459, 355);
            }
            this.container.addChild(this.noteSprite);
        } catch (error) {
            console.log("Error in showNote", error) // Default white
            this.noteText = noteText;
            this.noteColor = noteColor;

            if (this.noteSprite) { return; }
            this.noteSprite = new PIXI.Sprite(this.textures[`playerNoteIcon${this.noteColors[0]}`].texture);
            this.noteSprite.anchor.set(0.5);
            if (this.isLeftSided) {
                this.noteSprite.position.set(95, 355);
            } else {
                this.noteSprite.position.set(459, 355);
            }
            this.container.addChild(this.noteSprite);
        }
    }

    hideNote() {
        if (!this.noteSprite) { return; }
        this.noteText = '';
        this.container.removeChild(this.noteSprite);
        this.noteSprite = undefined;
    }

    showBounty(bountyAmount: number, isCelebrity: boolean) {

        this.bountyAmount = bountyAmount;
        this.bounty = new PlayerBounty(this.textures, isCelebrity);
        this.bounty.setParams(
            CurrencyPipe.prototype.transform(
                CurrencyDeviderPipe.prototype.transform(bountyAmount, this.bountyCurrency),
                this.bountyCurrency), isCelebrity
        );
        if (this.isLeftSided) {
            this.bounty.setPosition({ x: 60, y: 210 }, AnchorPosition.middleCenter);
        } else {
            this.bounty.setPosition({ x: 440, y: 210 }, AnchorPosition.middleCenter);
        }


        this.container.addChild(this.bounty.container);
    }

    setBounty(bounty: string, isCelebrity: boolean) {
        if (this.bounty) {
            this.bounty.setParams(bounty, isCelebrity);

        }
    }


    setBountySpell(spellVariant: BountySpellVariant, status: SpellStatus) {
        console.log("CHECK+++ @setBountySpell", spellVariant, status)
        if (!this.bounty) {
            return
        }

        if (spellVariant !== BountySpellVariant.NoSpell && (status === SpellStatus.Activated || status === SpellStatus.NotActivated)) {
            if (spellVariant === BountySpellVariant.HiddenSpell) {
                this.bounty.setSpell('bountySpellIndicator')
            } else {

                if (status === SpellStatus.Activated) {
                    this.bounty.setSpell(`bountySpellVariant_${spellVariant}_active`)

                }
                if (status === SpellStatus.NotActivated) {
                    this.bounty.setSpell(`bountySpellVariant_${spellVariant}`)
                }
            }
        } else {
            this.bounty.setSpell(`bountyTargetRegular`)

        }
    }

    noSpellZone() {
        this.isNoSpell = true;
        for (const earnedSpell of this.earnedSpells) {
            this.container.removeChild(earnedSpell.sprite)
        }
        if (this.moreSpellSprite) {
            this.moreSpellSprite.visible = false
            this.container.removeChild(this.moreSpellSprite)
            this.moreSpellSprite = undefined
        }
    }

    addEarnedBounty(spells: PlayerSpellsEarnedSpell[]) {
        if (!spells || spells.length === 0) {
            return
        }
        const MAX_SPELLS = 4
        this.allSpells = spells;

        for (const earnedSpell of this.earnedSpells) {
            this.container.removeChild(earnedSpell.sprite)
        }
        if (this.moreSpellSprite) {
            this.moreSpellSprite.visible = false

            this.container.removeChild(this.moreSpellSprite)
            this.moreSpellSprite = undefined
        }

        if (this.isNoSpell) {
            return
        }

        let spellSprite: PIXI.Sprite;
        let index = 0;
        const spellsFiltered = spells
            .filter(spell => spell.SpellVariant !== BountySpellVariant.NoSpell && (spell.Status === SpellStatus.Activated || spell.Status === SpellStatus.NotActivated))
            .slice(0, MAX_SPELLS)
            .map(spell => {
                spell.sortIndex = 0;
                switch (spell.Status) {
                    case SpellStatus.NotActivated:
                        spell.sortIndex = 2;
                        break;
                    case SpellStatus.Activated:
                        spell.sortIndex = 1;
                        break;
                    case SpellStatus.Used:
                        spell.sortIndex = 5;
                        break;
                    case SpellStatus.TakenAway:
                        spell.sortIndex = 7;
                        break;
                    case SpellStatus.Wasted:
                        spell.sortIndex = 6;
                        break;
                    case SpellStatus.UsedBigBlind:
                        spell.sortIndex = 3;
                        break;
                    case SpellStatus.UsedSmallBlind:
                        spell.sortIndex = 4;
                        break;
                    case SpellStatus.Disabled:
                        spell.sortIndex = 8;
                        break;
                    default:
                        break;
                }
                return spell
            }).sort((a, b) => {
                return a.sortIndex > b.sortIndex ? 1 : -1
            })

        this.earnedSpells = []
        for (const spell of spellsFiltered) {
            // spellSprite = new PIXI.Sprite(this.textures[`bountySpellVariant_${spell.SpellVariant}_active`].texture);

            if (spell.Status === SpellStatus.Activated) {
                spellSprite = new PIXI.Sprite(this.textures[`bountySpellVariant_${spell.SpellVariant}_active`].texture);
            }

            if (spell.Status === SpellStatus.NotActivated) {
                spellSprite = new PIXI.Sprite(this.textures[`bountySpellVariant_${spell.SpellVariant}`].texture);
            }
            spellSprite.scale.set(0.8);
            spellSprite.anchor.set(0.5)


            if (this.isLeftSided) {
                spellSprite.position.set(490 - (spellsFiltered.length - index) * 45, 355)
            } else {
                spellSprite.position.set(490 - (spellsFiltered.length - index) * 45, 355)

                // spellSprite.position.set(65 + (spellsFiltered.length - index) * 30, 355)

            }




            // if (this.isLeftSided) {
            //     spellSprite.position.set(454 + index * 25, 192)

            // } else {
            //     spellSprite.position.set(102 - index * 25, 192)
            // }

            this.earnedSpells.push({ ...spell, sprite: spellSprite })
            this.container.addChild(spellSprite);
            index++;
        }


        if (spells.length > MAX_SPELLS) {
            this.moreSpellSprite = new PIXI.Sprite(this.textures[`bountySpellMore`].texture);
            this.moreSpellSprite.scale.set(0.8);
            this.moreSpellSprite.anchor.set(0.5)


            if (this.isLeftSided) {
                this.moreSpellSprite.position.set(490 - (spellsFiltered.length - index) * 30, 355)
            } else {
                this.moreSpellSprite.position.set(490 - (spellsFiltered.length - index) * 30, 355)

            }
            this.container.addChild(this.moreSpellSprite);
        }

        // this.addSpellMenu(spellsFiltered)

    }

    // ######################
    // ## SET ELEMENTS
    // ######################
    private createStars(playerLevel: number) {
        if (playerLevel === undefined || playerLevel === 0) { return; }

        let starPositions;
        if (this.isLeftSided) {
            starPositions = CONST.starsPositionsLeft;
        } else {
            starPositions = CONST.starsPositionsRight;
        }

        for (let i = 0; i < 5; i++) {
            const star = new PIXI.Sprite(this.textures['playerStar'].texture);
            star.anchor.set(0.5);
            star.position.set(starPositions[i].x, starPositions[i].y);
            this.container.addChild(star);
            this.levelStars.push(star);
        }

        this.setPlayerLevel(playerLevel);
    }

    private createTimebankElements() {
        this.timebankBackground = new PIXI.Graphics();
        this.timebankBackground.beginFill(0xFFFFFF, 0.2);

        this.timebankBackground.drawRoundedRect(
            6, // X
            165, // Y
            362, // WIDTH
            10, // HEIGTH
            5 // RADIUS
        );

        this.timebankBackground.endFill();

        this.timebankText = new PIXI.Text('Timebank: 0', new PIXI.TextStyle({
            fontFamily: 'Saira Semi Condensed',
            fontSize: 28,
            fill: '#ffffff',
            fontWeight: '500'
        }));
        this.timebankText.anchor.set(0.5);
        this.timebankTimerText = new PIXI.Text('10', new PIXI.TextStyle({
            fontFamily: 'Saira Semi Condensed',
            fontSize: 90,
            fill: '#ffffff',
            fontWeight: '600'
        }));
        this.timebankTimerText.anchor.set(0.5);



        this.timebankText.position.set(274, 260);
        this.timebankTimerText.position.set(274, 55);

        this.timebankBackground.visible = false;
        this.timebankText.visible = false;
        this.timebankTimerText.visible = false;

        //  this.container.addChild(this.timebankBackground); // on v2 dont use background
        this.container.addChild(this.timebankText);
        this.container.addChild(this.timebankTimerText);


        this.timerGraphics = new PlayerTimerStandard(this.isLeftSided, { x: 275, y: 285 }, this.config);
        this.container.addChild(this.timerGraphics.container);
        this.timerGraphics.container.visible = false;
    }

    private createInfoBox() {

        this.infoBoxSprite = new PIXI.Sprite(this.textures['playerDefault'].texture);
        this.infoBoxBlinkSprite = new PIXI.Sprite(this.textures['playerActive'].texture);

        this.infoBoxSprite.anchor.set(0.5)
        this.infoBoxBlinkSprite.anchor.set(0.5)

        this.infoBoxSprite.position.set(274, 274);
        this.infoBoxBlinkSprite.position.set(274, 274);

        this.container.addChild(this.infoBoxSprite);
        this.container.addChild(this.infoBoxBlinkSprite);
        this.infoBoxBlinkSprite.visible = false;


        this.container.addChild(this.infoBoxSprite);
        this.container.addChild(this.infoBoxBlinkSprite);
        this.infoBoxBlinkSprite.visible = false;

        console.log('@this.isSpellTournament', this.isSpellTournament)
        if (this.gameComponent.getMemberProfile().Id !== this.id || this.isSpellTournament) {
            //     console.log("member profile", this.nameString )
            this.infoBoxSprite.interactive = true;
            this.infoBoxSprite.buttonMode = true;
            this.infoBoxSprite.cursor = 'pointer';
            this.infoBoxSprite.on('mousedown', (event) => {
                //           console.log("@clicked")
                event.stopPropagation();
                this.gameComponent.clickPlayer(this.id, this.noteText, this.noteColor, this.nameString);
            });
            this.infoBoxSprite.on('touchstart', (event) => {
                event.stopPropagation();
                this.gameComponent.clickPlayer(this.id, this.noteText, this.noteColor, this.nameString);
            });
        }
        else {
            //         console.log("@passed == not!! mighty", this.gameComponent.getMemberProfile().Id !== this.id, this.id, this.isSpellTournament)
        }



    }

    private createAvatar() {
        let avatarTexture: PIXI.Texture;
        if (this.avatarUrl) {
            const tempLoader = new PIXI.Loader()
            tempLoader.add('avatar', this.config.httpUrl + '/avatar/' + this.avatarUrl, { crossOrigin: 'anonymous' });
            tempLoader.load((loader, resources) => {
                this.avatarSprite.texture = resources['avatar'].texture;
                this.avatarSprite.scale.set(circleWidth / Math.abs(circleWidth - this.avatarSprite.texture.baseTexture.width) + 0.1)

                loader.reset();
            });
        } else {
            if (false) {
                avatarTexture = this.textures['playerDefaultAvatars'].textures[this.seatNumber];
            } else {
                avatarTexture = this.textures['emptyAvatar'].texture;
            }
        }




        const circleWidth = 218
        this.avatarMask = new PIXI.Graphics();
        this.avatarMask.beginFill(0x00FF00, 1);
        this.avatarMask.lineStyle(0);
        this.avatarMask.drawCircle(274, 122, circleWidth / 2);
        this.avatarMask.endFill();
        this.container.addChild(this.avatarMask);
        this.avatarMask.isMask = true;

        const avatarMask2 = new PIXI.Graphics();
        avatarMask2.beginFill(0xFFFFFF, 1);
        avatarMask2.lineStyle(0);
        avatarMask2.drawCircle(273, 121, circleWidth / 2 + 2);
        avatarMask2.endFill();
        this.container.addChild(avatarMask2);


        this.avatarSprite = new PIXI.Sprite(avatarTexture);
        this.avatarSprite.anchor.set(0.5);
        this.avatarSprite.position.set(274, 122)
        this.avatarSprite.mask = this.avatarMask;
        this.avatarSprite.scale.set(circleWidth / Math.abs(circleWidth - this.avatarSprite.texture.baseTexture.width) + 0.1)
        this.container.addChild(this.avatarSprite);


        this.avatarSeatChangeSprite = new PIXI.Sprite(this.textures['spellSeatChange'].texture);
        this.avatarSeatChangeSprite.anchor.set(0.5);
        this.avatarSeatChangeSprite.position.set(274, 122)
        this.avatarSeatChangeSprite.mask = this.avatarMask;
        this.avatarSeatChangeSprite.scale.set(circleWidth / Math.abs(circleWidth - this.avatarSprite.texture.baseTexture.width) + 0.8)
        this.avatarSeatChangeSprite.cursor = 'pointer';





        this.avatarBackgroundSprite = new PIXI.Sprite(this.textures['avatarDefault'].texture);
        this.avatarBackgroundBlinkSprite = new PIXI.Sprite(this.textures['avatarActive'].texture);

        this.avatarBackgroundSprite.anchor.set(0.5);
        this.avatarBackgroundBlinkSprite.anchor.set(0.5);

        this.avatarBackgroundSprite.position.set(274, 122)
        this.avatarBackgroundBlinkSprite.position.set(274, 122)

        // this.avatarBackgroundSprite.mask = this.avatarMask;
        this.container.addChild(this.avatarBackgroundSprite);
        this.container.addChild(this.avatarBackgroundBlinkSprite);
        this.avatarBackgroundBlinkSprite.visible = false;



        this.disconnectProtectionIconSprite = new PIXI.Sprite(this.textures['disconnectionProtectionIcon'].texture);
        this.disconnectProtectionIconSprite.anchor.set(0.5);
        this.disconnectProtectionIconSprite.position.set(274, 15)
        this.disconnectProtectionIconSprite.visible = false

        this.disconnectProtectionOverlaySprite = new PIXI.Sprite(this.textures['disconnectionProtectionOverlay'].texture);
        this.disconnectProtectionOverlaySprite.anchor.set(0.5);
        this.disconnectProtectionOverlaySprite.position.set(274, 122)
        this.disconnectProtectionOverlaySprite.alpha = 0.9
        this.disconnectProtectionOverlaySprite.scale.set(1.2)
        this.disconnectProtectionOverlaySprite.visible = false

        this.disconnectProtectionOverlay = new PIXI.Graphics();
        this.disconnectProtectionOverlay.beginFill(0x7e0822, 0.8);
        this.disconnectProtectionOverlay.lineStyle(0);
        this.disconnectProtectionOverlay.drawCircle(273, 121, circleWidth / 2 + 2);
        this.disconnectProtectionOverlay.endFill();
        this.disconnectProtectionOverlay.visible = false;

        this.disconnectProtectionText = new PIXI.Text('DISCONNECTION \n PROTECTION', {
            fontFamily: 'Saira Semi Condensed',
            fontSize: 28,
            fontWeight: '600',
            fill: '#ffffff',
            align: 'center'
        });
        this.disconnectProtectionText.position.set(274, 140);
        this.disconnectProtectionText.anchor.set(0.5, 0.5);

        this.disconnectProtectionTimer = new PIXI.Text('', {
            fontFamily: 'Saira Semi Condensed',
            fontSize: 48,
            fontWeight: '500',
            fill: '#ffffff',
            align: 'center'
        });
        this.disconnectProtectionTimer.position.set(274, 80);
        this.disconnectProtectionTimer.anchor.set(0.5, 0.5);

        this.disconnectProtectionText.visible = false;
        this.disconnectProtectionTimer.visible = false;


        // this.container.addChild(this.disconnectProtectionOverlay);
        this.container.addChild(this.disconnectProtectionOverlaySprite);
        this.container.addChild(this.disconnectProtectionIconSprite);

        this.container.addChild(this.disconnectProtectionText);
        this.container.addChild(this.disconnectProtectionTimer);



        // temp testing
        this.avatarSeatChangeSprite.on('mousedown', (event) => {
            this.gameComponent.useSpell(BountySpellVariant.RotateSeats, this.id)
            this.gameComponent.seatChangeHide()
        });
        this.avatarSeatChangeSprite.on('touchend', (event) => {
            this.gameComponent.useSpell(BountySpellVariant.RotateSeats, this.id)
            this.gameComponent.seatChangeHide()
        });

        this.avatarSeatChangeSprite.interactive = true;
        this.avatarSeatChangeSprite.buttonMode = true;
        this.avatarSeatChangeSprite.visible = false;


        this.container.addChild(this.avatarSeatChangeSprite);

    }

    private createInfoText() {

        // create name/status info
        this.name = new PIXI.Text(this.checkForNameHiding(), {
            fontFamily: 'Saira Semi Condensed',
            fontSize: 40,
            fontWeight: '500',
            fill: '#ffffff'
        });
        this.name.position.set(274, 220);
        this.name.anchor.set(0.5, 0.5);
        // if (this.isLeftSided) {
        //     if (environment.skinType === SkinType.Unlimited) {
        //         this.name.position.set(CONST.namePositionLeftUnlimited.x, CONST.namePositionLeftUnlimited.y);
        //         this.name.anchor.set(1, 0.5);
        //     } else {
        //         this.name.position.set(CONST.namePositionLeft.x, CONST.namePositionLeft.y);
        //         this.name.anchor.set(0.5, 0.5);
        //     }
        // } else {
        //     if (environment.skinType === SkinType.Unlimited) {
        //         this.name.position.set(CONST.namePositionRightUnlimited.x, CONST.namePositionRightUnlimited.y);
        //         this.name.anchor.set(0, 0.5);
        //     } else {
        //         this.name.position.set(CONST.namePositionRight.x, CONST.namePositionRight.y);
        //         this.name.anchor.set(0.5, 0.5);
        //     }
        // }
        this.name.resolution = window.devicePixelRatio;
        this.container.addChild(this.name);

        this.amountStyle = new PIXI.TextStyle({
            fontFamily: 'Saira Semi Condensed',
            fontSize: 50,
            fontWeight: '500',
            fill: '#ffffff'
        });

        this.amountDecimalStyle = new PIXI.TextStyle({
            fontFamily: 'Saira Semi Condensed',
            fontSize: 32,
            fontWeight: '500',
            fill: '#ffffff'
        });

        this.amountCurrencyStyle = new PIXI.TextStyle({
            fontFamily: 'Saira Semi Condensed',
            fontSize: 40,
            fontWeight: '500',
            fill: '#999999'
        });

        this.moneyAmountText = new PIXI.Text('', this.amountStyle);
        this.moneyAmountText.position.set(274, 320);
        this.moneyAmountText.anchor.set(0.5, 0.5);

        this.moneyAmountCurrencyText = new PIXI.Text('', this.amountCurrencyStyle);
        this.moneyAmountCurrencyText.position.set(274, 320);
        this.moneyAmountCurrencyText.anchor.set(1, 0.5);

        this.moneyAmountDecimalText = new PIXI.Text('', this.amountDecimalStyle);
        this.moneyAmountDecimalText.position.set(274, 327);
        this.moneyAmountDecimalText.anchor.set(0, 0.5);

        this.container.addChild(this.moneyAmountText);
        this.container.addChild(this.moneyAmountDecimalText);
        this.container.addChild(this.moneyAmountCurrencyText);



    }

    private createDealerButton() {
        this.dealer = new PIXI.Sprite(this.textures['dealer'].texture);
        this.container.addChild(this.dealer);
        this.dealer.visible = false;
        this.dealer.anchor.set(0.5);
        if (this.isLeftSided) {
            this.dealer.position.set(454, 192);
        } else {
            this.dealer.position.set(102, 192);
        }
    }

    private createStraddleDisplay() {
        this.straddle = new PIXI.Sprite(this.textures['straddle'].texture);
        this.container.addChild(this.straddle);
        this.straddle.visible = false;
        this.straddle.anchor.set(0.5);
        if (this.isLeftSided) {
            this.straddle.position.set(102, 192);
        } else {
            this.straddle.position.set(454, 192);
        }
    }

    private createNbRebuyUsedDisplay() {
        this.nbRebuyUsed = new PIXI.Sprite(this.textures['nbRebuyUsed'].texture);
        this.container.addChild(this.nbRebuyUsed);
        this.nbRebuyUsed.visible = false;
        this.nbRebuyUsed.anchor.set(0.5);
        if (this.isLeftSided) {
            this.nbRebuyUsed.position.set(355, 42);
        } else {
            this.nbRebuyUsed.position.set(194, 42);
        }


        this.nbRebuyUsedCount = new PIXI.Text('2', new PIXI.TextStyle({
            fontFamily: 'Saira Semi Condensed',
            fontSize: 36,
            fill: '#ffffff',
            fontWeight: '400'
        }));
        this.nbRebuyUsedCount.anchor.set(0.5);
        if (this.isLeftSided) {
            this.nbRebuyUsedCount.position.set(355, 42);
        } else {
            this.nbRebuyUsedCount.position.set(194, 42);
        }
        this.nbRebuyUsedCount.visible = false;
        this.container.addChild(this.nbRebuyUsedCount);
    }

    setNbRebuyUsed(count: number) {
        this.nbRebuy = count ?? 0;
        if (count && count > 0) {
            this.nbRebuyUsedCount.text = count.toString();
            this.nbRebuyUsed.visible = true;
            this.nbRebuyUsedCount.visible = true;
        } else {
            this.nbRebuyUsed.visible = false;
            this.nbRebuyUsedCount.visible = false;
            this.nbRebuyUsedCount.text = '';
        }
    }

    // ######################
    // ## HELPER FUNCTIONS
    // ######################

    private checkForNameLength(): string {
        return this.checkForStringLength(this.nameString, CONST.playerNameMaxLength);
    }

    private checkForStatusLength(statusText: string): string {
        return this.checkForStringLength(statusText, CONST.playerStatusMaxLength);
    }

    private checkForStringLength(str: string, maxLength: number): string {
        if (str.length > maxLength) {
            return str.substring(0, maxLength) + '..';
        } else {
            return str;
        }
    }

    private checkForNameHiding(): string {
        if (false) {
            return 'Seated';
        } else {
            return this.checkForNameLength();
        }
    }

    setStatusText(statusText: string) {
        if (statusText) {
            this.name.text = statusText;
            this.shouldRevertToDefaultStatus = true;
            this.revertTimer = 0;
        }
    }


    private createCardReplaceInfo() {
        // for (const el of Array.from(Array(3).keys())) {
        //     this.replacedCardsInfo.push(new PlayerCardReplaceInfo());
        //     this.container.addChild(this.replacedCardsInfo[el].container);

        //     let position: Point
        //     if (this.isLeftSided) {
        //         position = Object.assign({}, CONST.replacedCardsInfoPositionLeft)
        //         position.x += el * 55

        //         this.replacedCardsInfo[el].setPosition(position, AnchorPosition.topLeft);
        //     } else {
        //         position = Object.assign({}, CONST.replacedCardsInfoPositionLeft)
        //         position.x += el * 55

        //         this.replacedCardsInfo[el].setPosition(position, AnchorPosition.topLeft);
        //     }

        //     this.replacedCardsInfo[el].container.visible = false;
        // }

        if (true) {
            const addX = 80
            const addY = 110
            // ***** NEW
            this.cardReplaceInfoBackground = new PIXI.Graphics();
            this.cardReplaceInfoBackground.beginFill(0xFF4248, 1);

            this.cardReplaceInfoBackground.drawRoundedRect(
                97 + addX, // X
                235 + addY, // Y
                180, // WIDTH
                50, // HEIGTH
                35 // RADIUS
            );

            this.cardReplaceInfoBackground.endFill();
            this.cardReplaceInfoBackground.visible = false;

            this.container.addChild(this.cardReplaceInfoBackground);


            this.cardReplaceInfoLine1 = new PIXI.Graphics();
            this.cardReplaceInfoLine1.beginFill(0xFFFFFF, 0.4);
            this.cardReplaceInfoLine1.drawRoundedRect(
                161 + addX, // X
                242 + addY, // Y
                3, // WIDTH
                36, // HEIGTH
                0 // RADIUS
            );
            this.cardReplaceInfoLine1.endFill();
            this.cardReplaceInfoLine1.visible = false;
            this.container.addChild(this.cardReplaceInfoLine1);


            this.cardReplaceInfoLine2 = new PIXI.Graphics();
            this.cardReplaceInfoLine2.beginFill(0xFFFFFF, 0.4);
            this.cardReplaceInfoLine2.drawRoundedRect(
                211 + addX, // X
                242 + addY, // Y
                3, // WIDTH
                36, // HEIGTH
                0 // RADIUS
            );
            this.cardReplaceInfoLine2.endFill();
            this.cardReplaceInfoLine2.visible = false;
            this.container.addChild(this.cardReplaceInfoLine2);


            this.cardReplaceInfoNo1 = new PIXI.Text('1', new PIXI.TextStyle({
                fontFamily: 'Saira Semi Condensed',
                fontSize: 36,
                fill: '#ffffff',
                fontWeight: '400'
            }));
            this.cardReplaceInfoNo1.anchor.set(0.5);
            this.cardReplaceInfoNo1.position.set(138 + addX, 260 + addY)
            this.cardReplaceInfoNo1.visible = false;
            this.container.addChild(this.cardReplaceInfoNo1);


            this.cardReplaceInfoNo2 = new PIXI.Text('2', new PIXI.TextStyle({
                fontFamily: 'Saira Semi Condensed',
                fontSize: 36,
                fill: '#ffffff',
                fontWeight: '400'
            }));
            this.cardReplaceInfoNo2.anchor.set(0.5);
            this.cardReplaceInfoNo2.position.set(188 + addX, 260 + addY)
            this.cardReplaceInfoNo2.visible = false;
            this.container.addChild(this.cardReplaceInfoNo2);



            this.cardReplaceInfoNo3 = new PIXI.Text('3', new PIXI.TextStyle({
                fontFamily: 'Saira Semi Condensed',
                fontSize: 36,
                fill: '#ffffff',
                fontWeight: '400'
            }));
            this.cardReplaceInfoNo3.anchor.set(0.5);
            this.cardReplaceInfoNo3.position.set(238 + addX, 260 + addY)
            this.cardReplaceInfoNo3.visible = false;
            this.container.addChild(this.cardReplaceInfoNo3);
            // *************
        }
    }

    setReplacedCardsNumber(numOfReplacedCards: number, gameStatus: number) {

        if (gameStatus === GameStatus.ReplaceCards1) {
            this.cardReplaceInfoBackground.visible = true
            this.cardReplaceInfoLine1.visible = true
            this.cardReplaceInfoLine2.visible = true

            this.cardReplaceInfoNo1.text = numOfReplacedCards.toString();
            this.cardReplaceInfoNo1.visible = true

        } else if (gameStatus === GameStatus.ReplaceCards2) {
            this.cardReplaceInfoNo1.alpha = 0.65
            this.cardReplaceInfoNo2.text = numOfReplacedCards.toString();
            this.cardReplaceInfoNo2.visible = true


        } else if (gameStatus === GameStatus.ReplaceCards3) {
            this.cardReplaceInfoNo1.alpha = 0.65
            this.cardReplaceInfoNo2.alpha = 0.65
            this.cardReplaceInfoNo3.text = numOfReplacedCards.toString();
            this.cardReplaceInfoNo3.visible = true

        }



        // if (this.replacedCardsInfo[index]) {
        //  this.replacedCardsInfo[index].setNumberOfReplacedCards(numOfReplacedCards);
        //     this.replacedCardsInfo[index].container.visible = true;
        // }


        // for (const el of Array.from(Array(index).keys())) {
        //     this.replacedCardsInfo[el].setBackground(true);
        // }
    }

    public setBackgroundsToGray(size: number) {
        switch (size) {
            case 1:
                this.cardReplaceInfoNo1.alpha = 0.65

                break;

            case 2:
                this.cardReplaceInfoNo1.alpha = 0.65
                this.cardReplaceInfoNo2.alpha = 0.65

                break;

            case 3:
                this.cardReplaceInfoNo1.alpha = 0.65
                this.cardReplaceInfoNo2.alpha = 0.65
                this.cardReplaceInfoNo3.alpha = 0.65

                break;

            default:
                break;
        }
        // for (const el of Array.from(Array(size).keys())) {
        //     this.replacedCardsInfo[el].setBackground(true);
        // }
    }

    public hideReplacedCardsInfo() {
        this.cardReplaceInfoBackground.visible = false
        this.cardReplaceInfoLine1.visible = false
        this.cardReplaceInfoLine2.visible = false

        this.cardReplaceInfoNo1.visible = false
        this.cardReplaceInfoNo2.visible = false
        this.cardReplaceInfoNo3.visible = false

        this.cardReplaceInfoNo1.alpha = 1
        this.cardReplaceInfoNo2.alpha = 1
        this.cardReplaceInfoNo3.alpha = 1


        // for (const el of Array.from(Array(3).keys())) {
        //     this.replacedCardsInfo[el].setBackground(false);
        //     this.replacedCardsInfo[el].container.visible = false;

        // }
    }


    private dhmsCountdownTimer(duration: number): number {

        let t = Math.floor(duration / 1000);

        let days, hours, minutes, seconds;

        days = Math.floor(t / 86400)
        t -= days * 86400;
        hours = (Math.floor(t / 3600) % 24)
        t -= hours * 3600;
        minutes = (Math.floor(t / 60) % 60)
        t -= minutes * 60
        seconds = (t % 60)

        if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {
            return 0
        } else if (days < 0 || hours < 0 || minutes < 0 || seconds < 0) {
            return 0
        }

        // min 2 seconds 30 => 3min
        // min 1 seconds 12 => 2 min
        // min 0 seconds 58 => 58

        if (minutes === 0) {
            return seconds
        }

        return minutes + 1; // +1 min

    }







    addSpellMenu(spells: PlayerSpellsEarnedSpell[]) {

        while (this.spellContainer.children[0]) {
            this.spellContainer.removeChild(this.spellContainer.children[0]);
        }
        this.container.removeChild(this.spellContainer)
        if (!spells || spells.length === 0) {
            return
        }


        // ***
        const earnedSpells: PlayerSpellsEarnedSpell[] = spells

        const background = new PIXI.Graphics();
        background.beginFill(0x1f1f21);
        background.drawRoundedRect(
            0, // x
            0, // y
            this.gameComponent.getMemberProfile().Id === this.id ? 550 : 380, // Width
            earnedSpells?.length * 110, // Height
            50 // Radius
        );
        background.endFill()
        const backgroundTexture = this.renderer.generateTexture(background)
        const backgroundSprite = new PIXI.Sprite(backgroundTexture);
        // backgroundSprite.interactive = true;
        //  backgroundSprite.buttonMode = true;

        // backgroundSprite.buttonMode = true;
        backgroundSprite.anchor.set(0, 1)
        this.spellContainer.addChild(backgroundSprite)


        let index = 0;
        for (const earnedSpell of earnedSpells) {
            const earnedSpellContainer = new PIXI.Container();

            let spellName = ''
            switch (earnedSpell.SpellVariant) {
                case BountySpellVariant.SuddenDeath:
                    spellName = 'Sudden Death'
                    break;

                case BountySpellVariant.RobAPlayer:
                    spellName = 'Rob A Player'
                    break;

                case BountySpellVariant.Resurrection:
                    spellName = 'Resurrection'
                    break;

                case BountySpellVariant.SkipBlindOnce:
                    spellName = 'Skip Blind'
                    break;

                case BountySpellVariant.RotateSeats:
                    spellName = 'Seat Change'
                    break;

                case BountySpellVariant.Immunity:
                    spellName = 'Immunity'
                    break;

                case BountySpellVariant.ScanSpells:
                    spellName = 'Scan Spells'
                    break;
                default:
                    break;
            }


            const sprite = new PIXI.Sprite(this.textures[`bountySpellVariant_${earnedSpell.SpellVariant}`].texture);
            const text = new PIXI.Text(spellName, new PIXI.TextStyle({
                fontFamily: 'Saira Semi Condensed',
                fontSize: 32,
                fill: '#ffffff',
                fontWeight: '500'
            }));
            text.position.set(120, 45)
            earnedSpellContainer.addChild(sprite)
            earnedSpellContainer.addChild(text)

            earnedSpellContainer.position.set(0, backgroundSprite.y - backgroundSprite.height + index * 100);


            if (this.gameComponent.getMemberProfile().Id === this.id) {


                let status = ''
                let color;
                switch (earnedSpell.Status) {
                    case SpellStatus.Activated:
                        status = 'ACTIVE'
                        color = 0x000000
                        break;

                    case SpellStatus.NotActivated:
                        status = 'USE'
                        color = 0x5db057

                        break;
                    default:
                        break;
                }


                const button = new PIXI.Graphics();
                button.beginFill(color);
                button.drawRoundedRect(
                    0, // x
                    0, // y
                    150, // Width
                    80, // Height
                    50 // Radius
                );
                button.endFill()
                const buttonTexture = this.renderer.generateTexture(button)
                const buttonSprite = new PIXI.Sprite(buttonTexture);
                buttonSprite.interactive = true;
                buttonSprite.buttonMode = true;
                buttonSprite.position.set(375, 25)
                earnedSpellContainer.addChild(buttonSprite)


                buttonSprite.on('mouseup', (event) => {
                    if (earnedSpell.Status === SpellStatus.NotActivated) {
                        this.gameComponent.useSpell(earnedSpell.SpellVariant)
                    }
                    console.log("earnedSpells", earnedSpell.SpellVariant, spellName)
                });

                const btnText = new PIXI.Text(status, new PIXI.TextStyle({
                    fontFamily: 'Saira Semi Condensed',
                    fontSize: 36,
                    fill: '#ffffff',
                    fontWeight: '500'
                }));
                btnText.anchor.set(0.5, 0)
                btnText.position.set(450, 45)

                earnedSpellContainer.addChild(btnText)
            }
            this.spellContainer.addChild(earnedSpellContainer)

            index++;

        }

        this.spellContainer.position.set(550 * 0.6, 320)
        this.spellContainer.visible = false
        this.container.addChild(this.spellContainer)
    }


}
