import {
  Component,
  ViewChild,
  ElementRef,
  OnDestroy,
  NgZone,
  OnInit,
  Input,
  AfterViewInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { of, Subject, Subscription } from 'rxjs';
import * as PIXI from 'pixi.js';
// import { Store } from '@ngrx/store';
import { EMPTY, merge } from 'rxjs';

import { DealerTipComponent } from './dialogs/dealer-tip/dealer-tip.component';
import { Table, PreBet } from './helpers/table';
import { RequestMessage, PlayerStatus, WaitingListCommand, VariantType2, Limit } from '../../shared/enums/poker-types';
import { ENavItem } from '../nav/nav-item-type';
import { GameTableStart, DealerTipCost, BuyChips, InfoData } from '../../shared/models';
import { NavTableData } from '../../shared/models/nav-table-data';
import { GameService } from './game.service';
import { Currency, GameStatus } from '../../shared/enums';
import { DealerAction } from '../../shared/enums/dealer-action';
import { State } from '../../app.reducers';
import { AppActions } from '../../app.actions';
import { GenericTextDialog } from '../../core/dialogs/generic-text-dialog/generic-text-dialog.component';
import { GamePasswordComponent } from './dialogs/game-password/game-password.component';
import { AuthService } from '../../auth/auth.service';
import { NotificationsService } from '../../core/notifications/notifications.service';
import { RebuyAnswer, GameRebuyComponent } from './game-rebuy/game-rebuy.component';
import { AddOnAnswer, GameAddonComponent } from './game-addon/game-addon.component';
import { Chat } from '../../shared/models/chat';
import { GameHistory } from '../../shared/models/game-history';
import { GameEvents } from './models/game-events';
import { PlayerStats } from '../../shared/models/player-stats';
import { AppWebSocketService } from '../../core/services/app-web-socket.service';
import { CallTimeStatus, ManagerService } from '../../core/services/manager.service';
import { LoggerService } from '../../core/services/logger.service';
import { AssetsLoaderService } from '../../core/services/assets-loader.service';
import { DataManagerService } from '../../core/services/data-manager.service';
import { CurrencyMultiplierPipe } from '../../shared/utils/currency-multiplier.pipe';
import { BetActions, ReplayActions, ControlActions } from './game-controls-container/game-controls-enum';
import { environment } from '../../../environments/environment';
import { PlayerNoteComponent } from './dialogs/player-note/player-note.component';
import { MainService } from '../main.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Card } from './helpers/card';
import { filter, finalize, map, mergeAll, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';


import * as CONST_HORIZONTAL from './helpers/table-horizontal.const';
import * as CONST_VERTICAL from './helpers/table-vertical.const';
import { AnchorPosition } from './helpers/const';
import { DialogLeaveTableComponent } from './dialogs/dialog-leave-table/dialog-leave-table.component';
import { Player } from './helpers/player';
import { CallTimeComponent } from './dialogs/call-time/call-time.component';
import { Pot } from './helpers/pot';
import { ConfigService } from '../../core/services/config.service';
import { Comparators } from '../../shared/helpers/comparators';
import { v3ManagerService } from '../../core/services/v3-manager.service';
import { BountySpellVariant } from '../../shared/models/bounty-spell';
import { DialogBountySpellActiveComponent } from '../bounty-spell/dialog-bounty-spell-active/dialog-bounty-spell-active.component';
import { DialogPlayerSpellsComponent } from './dialogs/dialog-player-spells/dialog-player-spells.component';
import { RobAPlayerImmunityMock, RobAPlayerMock, Resurrection, RotateSeats, RotateSeatsImmunity, ScanSpells, SkipBlindsOnce, SuddenDeath, SuddenDeathImmunity } from './helpers/bounty-spell-mock.const';
import { RobAPlayer, RobAPlayerPlayer } from './helpers/robAPlayer';
import { SortCards } from '../../shared/enums/sort-card';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss'],
})

export class GameComponent extends Table implements OnInit, AfterViewInit, OnDestroy {
  bountySpellVariant = BountySpellVariant
  GameStatus = GameStatus;
  Currency = Currency;
  environment = environment;

  subscription: Subscription = new Subscription;
  tablePassword: string;
  pauseDialogRef;

  lastUpdateTime = 0;

  gameLeftOffset = 0;
  gameTopOffset = 0;

  playerCards: Card[]

  // public runItTwice;
  public loader;
  public chatMessages: Array<Chat> = [];
  public gameHistories: Array<GameHistory> = [];
  public playerStats: PlayerStats = {
    handsPlayed: 0,
    handsWon: 0,
    totalBet: '',
    totalWon: '',
    profit: ''
  };

  private _navTableData: NavTableData;

  animation = false

  @Input() set navTableData(value: NavTableData) {
    this._navTableData = value;
  }
  get navTableData(): NavTableData {
    return this._navTableData;
  }

  @Input() set isActive(isActive: boolean) {
    this._isActive = isActive;
    if (isActive) {
      // this.activateRender();

      this._navTableData.isActive = isActive;
    }
  }
  get isActive(): boolean {
    return this._isActive;
  }

  @ViewChild('pixiGameContainer', { static: true })
  pixiGameContainer: ElementRef;
  @ViewChild(GameRebuyComponent, { static: false })
  rebuyComponent: GameRebuyComponent;
  @ViewChild(GameAddonComponent, { static: false })
  addOnComponent: GameAddonComponent;

  @ViewChild('availableSpace', { static: true })
  availableSpace: ElementRef;

  public gameLimit;

  public selectCard: boolean

  destroy$ = new Subject<boolean>();

  gameLoader = true
  dialogGamePassword
  emoticons: any[] = [];



  tournamentInfo$;

  spellZone?: { activePlayers: number, minActivePlayersRequired: number }

  constructor(
    private service: AppWebSocketService,
    public authService: AuthService,
    public managerService: ManagerService,
    // private store: Store<State>,
    private notificationsService: NotificationsService,
    public zone: NgZone,
    public dialog: MatDialog,
    public logger: LoggerService,
    public gameService: GameService,
    public mainService: MainService,
    public assetsLoader: AssetsLoaderService,
    public dataManager: DataManagerService,
    private _ngZone: NgZone,
    public translateService: TranslateService,

    private ref: ChangeDetectorRef,
    protected breakpointObserver: BreakpointObserver,

    protected configService: ConfigService,




    //___
    private _v3ManagerService: v3ManagerService,


  ) {
    super(
      assetsLoader,
      authService,
      logger,
      dataManager,
      gameService,
      dialog,
      breakpointObserver,
      managerService,
      configService
    );

    this.managerService.userAppConfig$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((userAppConfig) => {
        this.userAppConfig = userAppConfig;

        this.onUserAppConfigStacksChange()
      });


    this.managerService.emoticonsInfo$
      .pipe(take(1))
      .subscribe({
        next: (emoticons) => {
          this.emoticons = emoticons
        }
      })

    // TRANSLATION
    this.translateService.get('LABEL.TAKEASEAT')
      .pipe(take(1))
      .subscribe(trans => {
        this.seatText = trans;
      });

    this.translateService.onLangChange
      .pipe(
        switchMap(() => this.translateService.get('LABEL.TAKEASEAT')),
        takeUntil(this.destroy$)
      )
      .subscribe((translation) => {
        this.onSeatTextChange(translation);
      });
    //


    // USER
    this.managerService.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (user) => {
          this.user = user
        }
      })

    // SETTINGS
    this.managerService.settings$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (settings) => {
          if (settings) {
            this.settings = settings;
          }
        }
      })

    this.managerService.callTimeStatus$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (callTimeStatus) => {
          if (callTimeStatus) {

          }
        }
      })
  }

  rotateTable() {
    const currentUseVerticalMode = this.useVerticalMode
    // ## UPDATE TEXTURES AND ROTATIONS
    this.fpsText.position.set(this.CONST.FpsPosition.x, this.CONST.FpsPosition.y);

    this.table.texture = this.assetsLoader.textures[currentUseVerticalMode ? 'tableVertical' : 'table'].texture;
    this.table.position.set(this.CONST.TablePosition.x, this.CONST.TablePosition.y);

    this.tableNameText.container.position.set(this.CONST.TableNamTextPosition.x, this.CONST.TableNamTextPosition.y)
    this.tableBlindsText.container.position.set(this.CONST.TableBlindsTextPosition.x, this.CONST.TableBlindsTextPosition.y)


    // ## handle previous state and remove shadow if the previous state was horizontal
    if (currentUseVerticalMode && this.tableShadow) {
      this.container.removeChild(this.tableShadow)
    } else {
      if (!this.tableShadow) {
        this.tableShadow = new PIXI.Sprite(this.assetsLoader.textures['tableShadow'].texture);
        this.tableShadow.position.set(this.CONST.TableShadowPosition.x, this.CONST.TableShadowPosition.y);

      }
      this.container.addChild(this.tableShadow);
      this.container.setChildIndex(this.tableShadow, 0);
    }


    this.tableLogo.texture = this.assetsLoader.textures['tableLogo'].texture;
    this.tableLogo.position.set(this.CONST.BrandLogoPosition.x, this.CONST.BrandLogoPosition.y);




    this.potTotalText.container.position.set(this.CONST.potTotalTextTopPosition.x - 40, this.CONST.potTotalTextTopPosition.y)



    this.tableCardsController.updateRotationMode(currentUseVerticalMode)
    this.tripleDrawRaoundInfo.setPosition(this.CONST.TripleDrawInfoPosition, AnchorPosition.middleCenter);


    if (this.hasSexyDealer) {
      this.sexyDealer.container.position.set(this.CONST.SexyDealerPosition.x - 256 / 2, this.CONST.SexyDealerPosition.y - 256 / 2)
    }

    this.createSeatPositions();
    this.createPlayerPositions();
    this.createPotPositions();

    this.foldedCardsController.updateRotationMode(currentUseVerticalMode)

    if (this.idTournament) {
      this.blindsText.container.position.set(this.CONST.BlindsTextPosition.x, this.CONST.BlindsTextPosition.y)
    }

    this.isRearrangedPositionsForPlayersPotsSeatsFoldedCards = false;
    this.rearrangePositionsForPlayersPotsSeatsFoldedCards(true);
    this.animationText.setPortraitMode(currentUseVerticalMode);


    console.log("@gameWidth", this.gameWidth, this.gameHeight, window.innerWidth, window.innerHeight)
    if (currentUseVerticalMode) {
      this.gameApp.view.style.width = '100%'
      this.gameApp.view.style.height = null
    } else {
      this.gameApp.view.style.width = null
      this.gameApp.view.style.height = '100%'
    }
    // ## Recreate new PIXI Application, mainly because of the Game Width and Game Height
    this.gameApp.renderer.resize(this.gameWidth, this.gameHeight)

    // this.renderer = new PIXI.Application(this.gameWidth, this.gameHeight, { transparent: true });
    // this.renderer.stage.addChild(this.container);


    if (currentUseVerticalMode) {
      this.jackpotValueOnTable.container.position.set(540, 1200 + 30)
    } else {
      this.jackpotValueOnTable.container.position.set(960, 612 + 50 - 40 - 10 + 30)

    }



    // Main pots:
    this.mainPot.updatePosition(this.CONST.MainPotPositions[0])
    this.mainPot.setPositions('left');

    this.mainPot2.updatePosition(this.CONST.MainPotPositions[1])
    this.mainPot2.setPositions('left');

    // this.pixiGameContainer.nativeElement.innerHTML = ''
    //  this.pixiGameContainer.nativeElement.appendChild(this.renderer.view);

  }



  ngOnInit(): void {
    this.managerService.onLeaveTable
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        idTable => {
          if (idTable === this.id) {
            if (!this.isTournament && !this.currentPlayer) {




              this.clickLeaveTable()



            } else if (!this.isTournament && this.currentPlayer) {





              this.dialog.open(DialogLeaveTableComponent, {
                width: '300px',
                position: { bottom: '75px' }
              }).afterClosed().subscribe(data => {
                if (data) {
                  if (this.callTimeConfiguration?.IsCallTime && (!this.callTimeStatus || this.callTimeStatus.CanLeaveTable === false)) {
                    this.dialog.open(CallTimeComponent, { data: { playerId: this.memberProfile.Id, tableId: this.id, } })
                  } else {
                    if (data.table) {
                      this.clickLeaveTable()
                    } else if (data.seat) {
                      this.clickLeaveSeat()
                    }
                  }

                }
              });
            } else {
              this.clickLeaveTable()

            }
          }
        }
      )

    this.breakpointObserver
      .observe([
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {

        this.handsetPortrait = result.breakpoints[Breakpoints.HandsetPortrait]
        this.handsetLandscape = result.breakpoints[Breakpoints.HandsetLandscape]

        this.useVerticalMode = this.handsetPortrait;

        if (this.useVerticalMode) {
          this.gameWidth = this.gameService.gameWidthVertical;
          this.gameHeight = this.gameService.gameHeightVertical;
          this.CONST = CONST_VERTICAL;
        } else {
          this.gameWidth = this.gameService.gameWidthHorizontal;
          this.gameHeight = this.gameService.gameHeightHorizontal;
          this.CONST = CONST_HORIZONTAL;
        }

        if (this.table) {
          this.rotateTable()
        } else {

          console.log("@gameWidth", this.gameWidth, this.gameHeight, window.innerWidth, window.innerHeight)
          this.gameApp = new PIXI.Application({
            width: this.gameWidth,
            height: this.gameHeight,
            backgroundAlpha: 0
          });
          if (this.useVerticalMode) {
            this.gameApp.view.style.width = '100%'
            this.gameApp.view.style.height = null
          } else {
            this.gameApp.view.style.width = null
            this.gameApp.view.style.height = '100%'
          }
          this.initTable(this.navTableData);



          this.gameLimit = this.getLimit();



          this.gameApp.stage.addChild(this.container);
          this.pixiGameContainer.nativeElement.appendChild(this.gameApp.view);



          this.update();

          if (!this.isReplay) {
            if (this.isTournament) {
              this.actionControls.showLeaveTableButton = false;
              this.joinTable();


              // JUNE new
              //this.rearrangePositionsForPlayersPotsSeatsFoldedCards()
            } else {

              console.log("this.navTableData", this.navTableData)

              if (this.tableSum.HasPassword && this.tableSum.IsLocked) {
                this.showPasswordDialog();
              } else {
                this.joinTable();
              }
              this.actionControls.showLeaveTableButton = true;
              this.dataManager.sendSubscribeLiveLobbySelected(this.id);
            }
          }
        }
      })

































    // V3  🐋

    this.tournamentInfo$ = this._v3ManagerService.getTournament(this.idTournament)




    // this.tournamentInfo$ = this.managerService.v2Tournaments$
    //   .pipe(
    //     tap((tournaments) => {
    //       console.log("_______MY TOURNAMENT this.id", this.id, this.idTournament, tournaments.has(this.idTournament))

    //     }),
    //     filter(tournaments => tournaments.has(this.idTournament)),
    //     map(tournaments => tournaments.get(this.idTournament)),
    //     map(tournament => {
    //       console.log("_______MY TOURNAMENT data :", JSON.parse(JSON.stringify(tournament)))

    //       let prize;
    //       if (tournament?.tourInfo?.Prizes) {
    //         prize = tournament.tourInfo.Prizes.sort((a, b) => Comparators.compare(+a.Position, +b.Position, true))[0];
    //       }
    //       if (tournament?.tourInfo?.Prizes2) {
    //         prize = tournament.tourInfo.Prizes2.sort((a, b) => Comparators.compare(+a.Position, +b.Position, true))[0];
    //       }

    //       return {
    //         myPosition: tournament.myPosition,

    //         Name: tournament.tourSum.Name,
    //         NbPlayerRegistered: tournament.tourSum.NbPlayerRegistered,
    //         NbPlayersActive: tournament.tourSum.NbPlayersActive,
    //         NbRebuyUsed: tournament.tourSum.NbRebuyUsed,
    //         ReBuyNbLimit: tournament.tourSum.ReBuyNbLimit,
    //         BreakIn: tournament.tourSum.BreakIn,
    //         BlindIncreaseIn: tournament.tourSum.BlindIncreaseIn,
    //         nextBlindLevel: tournament.blindLevels ? tournament.blindLevels[tournament.tourSum.BlindLevel + 1] : undefined,

    //         prize: prize
    //         // 💡 BreakIn, BlindIncreaseIn 
    //         // u service setovati da bude BlindIncreaseInDate koji bi bio u buducnost
    //         // tako da onda u celoj app bude isti counter ovako je negde razlika za par sekundi
    //       }
    //     }),
    //     tap((tournament) => {
    //       console.log("_______MY TOURNAMENT this.id FFF", tournament)

    //     }),
    //     shareReplay(1)
    //   )


    // // v3 -----------------------
    // if (this.isTournament) {



    //   // Player & Prizes -> Player Rank and First Prize
    //   //}
    // }
    // // v3 -----------------------


    // #####



























    this.id$.pipe(
      filter(tableId => !!tableId),
      switchMap((tableId)=> merge(of(tableId), this.addPlayer$)),
      switchMap(tableId => this.managerService.sortCards$.pipe(
        map(sortCards => {
          if (sortCards[tableId]) {
            return sortCards[tableId]
          } else {
            return SortCards.DEFAULT
          }
        }))
      ),
      takeUntil(this.destroy$)
    ).subscribe(sortCards => {
      console.log("sortCards", sortCards)
      for (let i = 0; i < this.seats.length; i++) {
        if (this.seats[i] instanceof Player) {
            (<Player>this.seats[i]).setSortCards(sortCards);
        }
    }
    })

  }

  ngAfterViewInit(): void { }

  ngOnDestroy() {
    this.gameApp.destroy(true);
    this.subscription.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getDeltaTime() {
    const now = Date.now();
    const dt = now - this.lastUpdateTime;
    this.lastUpdateTime = now;

    return dt;
  }



  announceMixTableGame(data: any) {
    console.log("announceMixTableGame", data)
    this.tableSum.Limit = data.Limit;
    this.tableSum.Variant = data.Variant;


    this.animationText.setConfig(VariantType2[data.CurrentVariant], null, 2000, this.handsetPortrait);
    this.container.setChildIndex(this.animationText.container, this.container.children.length - 1);


    this.tableNameText.setText([`${data.RotateIndex + 1}. ${VariantType2[data.CurrentVariant]} (${data.HandNumber}/${data.RotateEvery}) ${Limit[data.Limit]}`], ', ');
    this.tableBlindsText.setText([], ', ')

    // UPDATE
    this.navTableData.table.tableSum = this.tableSum;
    this.initTable(this.navTableData);
  }

  updateSelectedCards(data: Card) {
    const player = this.getPlayerById(this.currentPlayer.id);
    this.playerCards = player.cardsController.cards

    let cardIndex = this.playerCards.findIndex(el => el.cardData.Name === data.cardData.Name)

    this.playerCards = player.cardsController.cards

    if (!data.selected) {
      // Selected Cards
      let index = this.playerSelectedCards.findIndex(el => el.cardData.Name === data.cardData.Name)
      if (index > -1) { this.playerSelectedCards.splice(index, 1); }

      // Selected Cards index
      index = this.playerSelectedCardsIndexes.findIndex(el => el === cardIndex)
      if (index > -1) { this.playerSelectedCardsIndexes.splice(index, 1); }

    } else {
      this.playerSelectedCards.push(data)
      this.playerSelectedCardsIndexes.push(cardIndex)
    }

  }


  refreshUI() {
    try {
      this.ref.detectChanges();
    } catch (error) {

    }
  }

  update() {
    if (this.table) {
      const now = Date.now();
      const dt = now - this.lastUpdateTime;
      this.lastUpdateTime = now;

      this.updateTable(dt);
      requestAnimationFrame(() => this.update());
    }
  }

  joinTable() {
    this.service.sendData({ type: RequestMessage.JoinTable, IdTable: this.id, Str1: this.tablePassword });
  }

  showPasswordDialog() {
    if (this.dialogGamePassword) { return }
    this.dialogGamePassword = this.dialog.open(GamePasswordComponent, { width: '300px', data: { idTable: this.id } })

    this.dialogGamePassword.afterClosed()
      .pipe(
        finalize(() => this.dialogGamePassword = undefined)
      )
      .subscribe(data => {
        if (data) {
          this.tablePassword = data;
          this.joinTable()
        } else {
          this.managerService.setActiveTab(ENavItem.Lobby)
          this.managerService.removeGame(this.id)
        }
      });
  }

  changePreBet(event: { preBet: PreBet, checked }) {
    if (event.checked) {
      event.preBet.checked = true;
      this.preBetControls.preBetSelected = event.preBet.type;
      Object.keys(this.preBetControls.preBetValues).map(key => {
        this.preBetControls.preBetValues[key].checked = (this.preBetControls.preBetValues[key].type === event.preBet.type);
      });
    } else {
      event.preBet.checked = false;
      this.preBetControls.preBetSelected = undefined;
    }
  }

  clickShowTourInfo() {
    this.managerService.setActiveTab(ENavItem.Tournament)
    this.managerService.tournamentView(this.managerService.getTournament(this.idTournament).tourSum);

    // this.store.dispatch(new AppActions.TournamentsActions.SetViewTournament(this.idTournament));
    // this.store.dispatch(new AppActions.NavAction.ChangeLayout(ENavItem.Tournament));
  }

  updateTableData(tableData: NavTableData) {
    this.managerService.updateGame(tableData)
    //this.store.dispatch(new AppActions.GameAction.UpdateGame(tableData));
  }

  // clickDisconnect() {
  //   this.service.disconnect();
  //   for (const tableId in this) {
  //     this.navService.layoutRemove(this[tableId].id);
  //   }
  //   this = {};
  // }

  clickRunItTwice() {
    // this.actionControls.checkRunItTwice = !this.actionControls.checkRunItTwice;
    // const item = {
    //   type: RequestMessage.SetRunItTwice,
    //   IdTable: this.id,
    //   Value: 2 //this.actionControls.checkRunItTwice ? 1 : 0,
    // };
    // this.service.sendData(item);
  }

  sendPlayerNote(playerId: number, note: string) {
    const item = {
      type: RequestMessage.SetPlayerNotes,
      Value: playerId,
      Str1: note
    };
    this.service.sendData(item);
  }
  sendPlayerColor(playerId: number, color: string) {
    const data = {
      type: RequestMessage.SetPlayerColor,
      Value: playerId,
      Str1: color
    };

    this.service.sendData(data);
  }

  onMyEarnedSpells() {
    const player = this.getPlayerById(this.memberProfile.Id);
    const spells = [...player.allSpells]

    this.dialog.open(DialogPlayerSpellsComponent, {
      width: '300px',
      position: { bottom: '75px' },
      data: { player, spells, playerName: this.memberProfile.Username, isMyPlayer: true }
    }).afterClosed().subscribe(data => {
      console.log("________DATA 1 spell", data)

      if (data) {
        if (data.spell.SpellVariant === BountySpellVariant.RotateSeats) {
          this.seatChangeShow()
        } else {
          this.useSpell(data.spell.SpellVariant)
        }
      }
    })
  }

  clickPlayer(playerId: number, playerNote: string, playerNoteColor: string, nameString: string) {
    const player = this.getPlayerById(playerId);
    console.log("@mighty bounty player", player.initBountySpell, player.initEarnedSpells, player.earnedSpells, '@@@', player, '++++')
    const spells = [...player.allSpells]

    if (this.memberProfile.Id === playerId) {
      this.dialog.open(DialogPlayerSpellsComponent, {
        width: '300px',
        position: { bottom: '75px' },
        data: { player, spells, playerName: nameString, isMyPlayer: true }
      }).afterClosed().subscribe(data => {
        console.log("DATA spell 2", data)

        if (data) {
          if (data.spell.SpellVariant === BountySpellVariant.RotateSeats) {
            this.seatChangeShow()
          } else {
            this.useSpell(data.spell.SpellVariant)
          }
        }
      })
    } else {




      const data = { playerId: playerId, note: playerNote, color: playerNoteColor, playerName: nameString, player, spells };

      this.dialog.open(PlayerNoteComponent, { width: '330px', data })
        .afterClosed()
        .subscribe(data => {
          if (data) {

            this.sendPlayerNote(data.playerId, data.note);


            this.sendPlayerColor(data.playerId, data.color);

          }
        });
    }
  }

  playerTurnCardsReturnChange(idPlayer: number, timeToPlay: number = 0, handNumber: number) {

    const player = this.getPlayerById(idPlayer);
    if (!player || idPlayer !== this.authService.user.memberProfile.Id) { return; }

    this._ngZone.run(() => {
      this.playerCards = player.cardsController.cards
      this.betControls.showReplaceCardsButtons = true
    })

  }

  clickDealerTip() {
    const dealerTipCosts: DealerTipCost = {
      upgrade: this.sexyDealer.nextLevelCost,
      switch: this.sexyDealer.chageCost,
      currency: this.currency
    };

    this.dialog.open(DealerTipComponent, { data: dealerTipCosts })
      .afterClosed().subscribe((dealerAction: DealerAction) => {
        try {
          if (dealerAction === DealerAction.tip) {

            const item = {
              type: RequestMessage.TipUpgrade,
              IdTable: this.id,
              Value: dealerTipCosts.upgrade
            };
            this.service.sendData(item);
          }
          if (dealerAction === DealerAction.switch) {

            const item = {
              type: RequestMessage.TipChange,
              IdTable: this.id,
              Value: dealerTipCosts.switch
            };
            this.service.sendData(item);
          }
        } catch (e) {
          this.logger.error(e);
        }
      });
  }

  clickPostBigBlind() {
    this.actionAcceptBigBlind();
  }

  clickFold() {
    if (this.betControls.showCheckButton) {
      const dialogRef = this.dialog.open(GenericTextDialog, {
        disableClose: true
      });

      this.translateService.get(['LABEL.WANTTOFOLD', 'BUTTON.FOLD', 'areYouSureYouWantToFold']).subscribe(trans => {
        dialogRef.componentInstance.textHeader = trans['LABEL.WANTTOFOLD'];
        dialogRef.componentInstance.textAcceptBtn = trans['BUTTON.FOLD'];
        dialogRef.componentInstance.textDismissBtn = 'Back';
        dialogRef.componentInstance.textBody = trans['areYouSureYouWantToFold'];
      });

      dialogRef.afterClosed().subscribe(clicked => {
        if (clicked === 'ok') {
          super.actionFold();
        }
      });

    } else {
      super.actionFold();
    }

  }

  clickCall() {

    super.actionCall();
  }

  clickCheck() {
    super.actionCheck();
  }

  clickRaise(raiseValue: number) {

    if (raiseValue === 0) {
      super.actionRaise(this.betControls.betSlider.max);
    } else {
      super.actionRaise(raiseValue);
    }
  }


  clickAllIn() {
    super.actionAllIn();
  }

  clickPot() {
    this.betControls.betSlider.value = this.currentPlayer.currentPot;
    this.betControls.betSlider.bigBlind.value = this.betControls.betSlider.value / (this.getBlind() * 2);

    this.updateRaiseButton(this.betControls.minimumBet, this.betControls.isBringIn);
  }

  clickHalfPot() {
    this.betControls.betSlider.value = Math.ceil(this.currentPlayer.currentPot / 2);
    this.betControls.betSlider.bigBlind.value = this.betControls.betSlider.value / (this.getBlind() * 2);

    this.updateRaiseButton(this.betControls.minimumBet, this.betControls.isBringIn);
  }

  clickQuarterPot() {
    this.betControls.betSlider.value = Math.ceil(this.currentPlayer.currentPot / 4);
    this.betControls.betSlider.bigBlind.value = this.betControls.betSlider.value / (this.getBlind() * 2);

    this.updateRaiseButton(this.betControls.minimumBet, this.betControls.isBringIn);
  }

  clickThreeQuartersPot() {
    this.betControls.betSlider.value = Math.ceil(this.currentPlayer.currentPot * 0.75);
    this.betControls.betSlider.bigBlind.value = this.betControls.betSlider.value / (this.getBlind() * 2);

    this.updateRaiseButton(this.betControls.minimumBet, this.betControls.isBringIn);
  }

  click2BB() {
    this.betControls.betSlider.value = 4 * this.getBlind();
    this.betControls.betSlider.bigBlind.value = this.betControls.betSlider.value / (this.getBlind() * 2);

  }
  click3BB() {
    this.betControls.betSlider.value = this.getBlind() * 2 * 3;
    this.betControls.betSlider.bigBlind.value = this.betControls.betSlider.value / (this.getBlind() * 2);

    this.updateRaiseButton(this.betControls.minimumBet, this.betControls.isBringIn);
  }
  click4BB() {
    this.betControls.betSlider.value = this.getBlind() * 2 * 4;
    this.betControls.betSlider.bigBlind.value = this.betControls.betSlider.value / (this.getBlind() * 2);

    this.updateRaiseButton(this.betControls.minimumBet, this.betControls.isBringIn);
  }

  clickImBack() {
    this.actionImBack();
  }

  clickSliderDecrease() {
    const smallBlind = this.getBlind();
    const mult = Math.floor(this.betControls.betSlider.value / smallBlind);
    let step;
    if (this.betControls.betSlider.value !== smallBlind * mult) {
      step = this.betControls.betSlider.value - smallBlind * mult;
    } else {
      step = smallBlind;
    }
    this.betControls.betSlider.value -= step;

    if (this.betControls.betSlider.value < this.betControls.betSlider.min) {
      this.betControls.betSlider.value = this.betControls.betSlider.min;
    }
    this.betControls.betSlider.bigBlind.value = this.betControls.betSlider.value / (this.getBlind() * 2);

    this.updateRaiseButton(this.betControls.minimumBet, this.betControls.isBringIn);
  }

  clickSliderIncrease() {
    const smallBlind = this.getBlind();
    const mult = Math.ceil(this.betControls.betSlider.value / smallBlind);
    let step;
    if (this.betControls.betSlider.value !== smallBlind * mult) {
      step = smallBlind * mult - this.betControls.betSlider.value;
    } else {
      step = smallBlind;
    }
    this.betControls.betSlider.value += step;

    if (this.betControls.betSlider.value > this.betControls.betSlider.max) {
      this.betControls.betSlider.value = this.betControls.betSlider.max;
    }
    this.betControls.betSlider.bigBlind.value = this.betControls.betSlider.value / (this.getBlind() * 2);

    this.updateRaiseButton(this.betControls.minimumBet, this.betControls.isBringIn);
  }

  clickSliderMin() {
    this.betControls.betSlider.value = this.betControls.betSlider.min;
    this.betControls.betSlider.bigBlind.value = this.betControls.betSlider.value / (this.getBlind() * 2);

    this.updateRaiseButton(this.betControls.minimumBet, this.betControls.isBringIn);
  }

  clickSliderMax() {
    this.betControls.betSlider.value = this.betControls.betSlider.max;
    this.betControls.betSlider.bigBlind.value = this.betControls.betSlider.value / (this.getBlind() * 2);
    this.updateRaiseButton(this.betControls.minimumBet, this.betControls.isBringIn);
  }

  onSliderChange(value: number) {
    if (this.betControls.betSlider.max - value < this.betControls.betSlider.step) {
      this.betControls.betSlider.value = this.betControls.betSlider.max;
    } else {
      this.betControls.betSlider.value = value;
    }
    this.betControls.betSlider.bigBlind.value = this.betControls.betSlider.value / (this.getBlind() * 2);

    this.updateRaiseButton(this.betControls.minimumBet, this.betControls.isBringIn);
  }

  onSliderInputChange(value) {


    value = CurrencyMultiplierPipe.prototype.transform(value, this.currency);



    if (value <= this.betControls.betSlider.max && value >= this.betControls.betSlider.min) {

      this.betControls.betSlider.value = value;
      this.betControls.betSlider.bigBlind.value = this.betControls.betSlider.value / (this.getBlind() * 2);

      this.updateRaiseButton(this.betControls.minimumBet, this.betControls.isBringIn);
    }
  }

  clickLeaveSeat() {
    this.gameService.actionLeaveSeat(this.id);
  }

  clickLeaveTable() {
    if (this.currentPlayer === undefined || this.currentPlayer === null || this.isReplay) {
      console.log("idTournament", this.idTournament)
      if (this.idTournament && this.idTournament > 0) {
        this.managerService.setActiveTab(ENavItem.Tournament)
        this.managerService.tournamentView(this.managerService.getTournament(this.idTournament).tourSum);
        this.managerService.removeGame(this.id)

        return
      } else {
        this.gameService.closeTable(this.id);

      }
    } else {
      this.gameService.actionLeaveTable(this.id);
      this.shouldCloseTable = true;
    }

    this.managerService.setActiveTab(ENavItem.Lobby)
    this.managerService.setActiveGame(null)
    this.mainService.toggleSidenav(false, true);
    setTimeout(() => {
      this.mainService.toggleSidenav(false, false);
    }, 600);

  }

  actionAcceptBigBlind() {
    const item = {
      type: RequestMessage.PayBBOnNextHand,
      IdTable: this.id,
      Value: 0
    };
    this.service.sendData(item);

    this.betControls.showPostBigBlind = false;
  }

  initTableParams(info: InfoData) {
    if (!this.seatsInitialized) {
      console.log("@info", info)
      this.addSeatsAndPlayers(
        info.Players,
        info.PublicCards,
        info.HandNumber,
        info.CurrentPlayerTurn,
        info.Pots,
        info.IdTournament);

      if (info.Players) {
        info.Players.forEach(player => {
          if (player !== null) {
            this.playerStatus(player.Id, player.Status, player.Money, player.PostedStraddle);
          }
        });
      }
    }
    this.setJackpotValue(info.JackPotPrize) // ## JackPot
  }

  actionRebuyAddOnChips(answer: RebuyAnswer | AddOnAnswer) {
    this.service.sendData({
      type: RequestMessage.BuyChips,
      IdTable: this.id,
      IdTournament: this.idTournament,
      Value2: answer,
      Values: this.authService.user.memberProfile.Id
    });


  }

  clickSitoutNextHand() {
    this.actionControls.checkSeatOutNextHand ? this.actionImBack() : this.actionSitout();
  }

  actionSitout() {
    const item = {
      type: RequestMessage.ChangeStatus,
      IdTable: this.id,
      Value2: PlayerStatus.SitoutNextHand,
    };
    this.service.sendData(item);


    if (this.callTimeConfiguration?.IsCallTime && (!this.callTimeStatus || this.callTimeStatus.CanLeaveTable === false)) {
      this.dataManager.callTimeAccepted(this.id)
    }
  }

  clickBuyRebuyChips() {

    if (this.isTournament) {
      this.showRebuyOverlay(30);
    } else {
      // this.gameService.buyChips(this.id, this.buyShipsDialogTimeout);

      let time = this.buyChipsDialogTimestamp ? Math.round((this.buyChipsDialogTimestamp - new Date().getTime()) / 1000) : 240
      this.gameService.buyChips(this.id, time > 0 ? time : 240);

    }
  }

  skipNextHand() {

    if (this.currentPlayer) {
      this.betControls.showPostBigBlind = true;
    }
  }

  actionImBack() {
    const item = {
      type: RequestMessage.ChangeStatus,
      IdTable: this.id,
      Value2: PlayerStatus.Ready,
    };
    this.service.sendData(item);

  }

  gamePause(item) {
    if (this.isTournament) {
      this.gamePaused(item.Value3);

    }
  }



  askPlayerRebuy(playerIDs: number[], pauseTime: number) {

    // && this.currentPlayer.money > this.tourSum.ReBuyThreshold
    for (let i = 0; i < playerIDs.length; i++) {
      // if it's me
      if (playerIDs[i] === this.authService.user.memberProfile.Id) {
        // if table is tournament
        if (this.idTournament !== undefined) {
          this.showRebuyOverlay(pauseTime);
        } else {

          this.gameService.buyChips(this.id, pauseTime);

        }
      }
    }
  }

  showRebuyOverlay(pauseTime: number) {

    /*
    📚 Rebuy
   
    #1 Case: ako je iskljucen double rebuy
    single rebuy sako je player money ispod treshold
    dobule rebuy disable
   
    #2 Case: ako je ukljucen double rebuy
    single rebuy ako je player money ispod gornjeg treshold odnosno Threshold + TournamentStartupChips
    dobule rebuy  ako je player money ispod donjeg treshold odnosno Threshold
   
    */

    const userAvailableMoney = this.authService.user.playerBalance[this.currency.Id].availableMoney
    const reBuyThreshold = this.tourSum.ReBuyThreshold
    const reBuyThreshold2x = this.tourSum.ReBuyThreshold + this.tourSum.TournamentStartupChips;
    const entryFeeWithHouseFee = this.tourSum.EntryFee + (this.tourSum.EntryFee / 10);

    let showRebuyButton = false;
    let showRebuyDoubleButton = false;

    if (this.tourSum?.DoubleRebuy) {
      showRebuyButton = this.currentPlayer.money <= reBuyThreshold2x;
      showRebuyDoubleButton = this.currentPlayer.money <= reBuyThreshold;
    } else {
      showRebuyButton = this.currentPlayer.money <= reBuyThreshold;
    }

    if (userAvailableMoney < entryFeeWithHouseFee) {
      this.notificationsService.showError('InsuficientFunds!');
    } else {

      this.rebuyComponent.show({
        reBuyNbLimit: this.tourSum.ReBuyNbLimit,
        reBuyThreshold: this.tourSum.ReBuyThreshold,
        rebuyFee: this.tourSum.RebuyFee,
        currency: this.currency,
        countdown: pauseTime,
        showRebuyButton: showRebuyButton,
        showRebuyDoubleButton: showRebuyDoubleButton,
      });
    }
  }
  /*
   
      showRebuyOverlay(pauseTime: number) {
   
      const userAvailableMoney = this.authService.user.playerBalance[this.currency.Id].availableMoney
      let treshold = this.tourSum.ReBuyThreshold
   
      const treshold2X = this.tourSum.ReBuyThreshold + this.tourSum.TournamentStartupChips;
      const conditionPersonalMoney = this.currentPlayer.money < this.tourSum.ReBuyThreshold;
      const conditionPersonalMoney2X = this.currentPlayer.money < treshold2X;
      const entryFeeWithHouseFee = this.tourSum.EntryFee + (this.tourSum.EntryFee / 10);
   
      // const conditionPersonalLimit = this.currentPlayer.NbRebuy < this.tourSum.ReBuyNbLimit;
      let showRebuyButton = userAvailableMoney >= treshold;
      let showRebuyDoubleButton = false;
   
   
      if (this.tourSum.DoubleRebuy) {
        treshold += this.tourSum.TournamentStartupChips;
      }
   
   
   
      if (this.authService.user.playerBalance[this.currency.Id].availableMoney < entryFeeWithHouseFee) {
        this.notificationsService.showError('InsuficientFunds!');
      } else {
        if (this.tourSum.DoubleRebuy) {
          showRebuyDoubleButton = userAvailableMoney >= treshold + this.tourSum.TournamentStartupChips
        } 
      }
   
   
      this.rebuyComponent.show({
        reBuyNbLimit: this.tourSum.ReBuyNbLimit,
        reBuyThreshold: this.tourSum.ReBuyThreshold,
        rebuyFee: this.tourSum.RebuyFee,
        currency: this.currency,
        countdown: pauseTime,
        showRebuyButton,
        showRebuyDoubleButton,
      });
   
   
  }
      */






  askPlayerAddOn(playerId: number, pauseTime: number) {

    if (playerId === this.authService.user.memberProfile.Id) {
      this.addOnComponent.show({
        countdown: pauseTime,
      });

    }
  }

  waitingListAction(waitingListCommand: WaitingListCommand) {
    if (waitingListCommand === WaitingListCommand.Join) {
      this.dataManager.joinWaitingList(this.id);
    } else if (waitingListCommand === WaitingListCommand.Leave) {
      this.dataManager.leaveWaitingList(this.id);
    }

  }

  chatMessage(chatItem: Chat) {
    if (this.currentPlayer) {
      chatItem.isMe = chatItem.IdPlayer === this.currentPlayer.id;
    } else {
      chatItem.isMe = false;
    }

    if (!chatItem.PlayerData) { return; }
    if (chatItem.PlayerData.Avatar) {
      chatItem.avatarUrl = this.configService.config.httpUrl + '/avatar/' + chatItem.PlayerData.Avatar;
    } else {
      chatItem.avatarUrl = `${environment.dataSource}/assets/${this.config.code}/player/avatar.png`

    }
    let index = this.chatMessages.findIndex(el => (el.IdPlayer === chatItem.IdPlayer && el.IdTable === chatItem.IdTable))
    if (index > -1) {
      chatItem.color = this.chatMessages[index].color
    } else {
      let color = ``
      index = this.chatMessages.length
      if (index == 0) {
        color = `#29B6F6`
      } else if (index == 1) {
        color = `#EF5350`
      } else if (index == 2) {
        color = `#FFA726`
      } else if (index == 3) {
        color = `#66BB6A`
      } else if (index == 4) {
        color = `#AB47BC`
      } else if (index == 5) {
        color = `#FFEE58`
      } else if (index == 6) {
        color = `#EC407A`
      } else if (index == 7) {
        color = `#5C6BC0`
      } else if (index == 8) {
        color = `#26C6DA`
      } else {
        color = `#D4E157`
      }
      chatItem.color = color
    }


    const emoticon = this.emoticons.find(el => el.Id === chatItem.Value)
    if (emoticon) {
      chatItem.Text = emoticon.Title
    }

    this.chatMessages = [...this.chatMessages, chatItem];
    this.playerChat(chatItem.IdPlayer, chatItem.Text, chatItem.Value);
  }

  gameHistoryEvent(gameHistory: GameHistory) {
    this.gameHistories = [...this.gameHistories, gameHistory];
  }

  setPlayerStatistics(
    IdPlayer: number,
    NbHandsPlayed: number,
    NbHandsWon: number,
    TotalBet: number,
    TotalWon: number) {
    if (this.currentPlayer) {
      if (this.currentPlayer.id === IdPlayer) {
        this.playerStats.handsPlayed = NbHandsPlayed;
        this.playerStats.handsWon = NbHandsWon;
        this.playerStats.totalBet = this.getConvertedAmountText(TotalBet);
        this.playerStats.totalWon = this.getConvertedAmountText(TotalWon);
        this.playerStats.profit = this.getConvertedAmountText(Math.max(0, TotalWon - TotalBet));
      }
    }
  }

  betAction(event: { actionType: BetActions, data: any }) {
    switch (event.actionType) {
      case BetActions.PostBigBlind:
        this.clickPostBigBlind();
        break;
      case BetActions.Fold:
        this.clickFold();
        break;
      case BetActions.Call:

        this.clickCall();
        break;
      case BetActions.Check:
        this.clickCheck();
        break;
      case BetActions.Raise:

        this.clickRaise(event.data); // betSlider.value
        break;
      case BetActions.SliderDecrease:
        this.clickSliderDecrease();
        break;
      case BetActions.SliderMin:
        this.clickSliderMin();
        break;
      case BetActions.SliderChange:
        this.onSliderChange(event.data); // $event
        break;
      case BetActions.SliderInputChange:
        this.onSliderInputChange(event.data); // $event
        break;
      case BetActions.SliderIncrease:
        this.clickSliderIncrease();
        break;
      case BetActions.SliderMax:
        this.clickSliderMax();
        break;
      case BetActions.BB2:
        this.click2BB();
        break;
      case BetActions.BB3:
        this.click3BB();
        break;
      case BetActions.BB4:
        this.click4BB();
        break;
      case BetActions.QuarterPot:
        this.clickQuarterPot();
        break;
      case BetActions.ThreeQuartersPot:
        this.clickThreeQuartersPot();
        break;
      case BetActions.HalfPot:
        this.clickHalfPot();
        break;
      case BetActions.Pot:
        this.clickPot();
        break;
    }
  }

  replayAction(event: { actionType: ReplayActions, data: any }) {
    switch (event.actionType) {
      case ReplayActions.Play:
        this.replayPlay();
        break;
      case ReplayActions.Next:
        this.replayNext();
        break;
      case ReplayActions.Stop:
        this.replayStop();
        break;
      case ReplayActions.Restart:
        this.replayRestart();
        break;
    }
  }

  controlAction(event: { actionType: ControlActions, data: any }) {
    switch (event.actionType) {
      case ControlActions.BuyRebuyChips:
        this.clickBuyRebuyChips();
        break;
      case ControlActions.DealerTip:
        this.clickDealerTip();
        break;
      case ControlActions.ImBack:
        this.clickImBack();
        break;
      case ControlActions.LeaveTable:

        let leaveTablePlayer = this.seats.find(seat => (seat instanceof Player && (<Player>seat).id === this.memberProfile.Id))

        if (leaveTablePlayer && this.callTimeConfiguration?.IsCallTime && (!this.callTimeStatus || this.callTimeStatus.CanLeaveTable === false)) {
          this.dialog.open(CallTimeComponent, { data: { playerId: this.memberProfile.Id, tableId: this.id, } })
        } else {
          this.clickLeaveTable();
        }
        break;
      case ControlActions.ShowTourInfo:
        this.clickShowTourInfo();
        break;
      case ControlActions.LeaveSeat:
        const leaveSeatPlayer = this.seats.find(seat => (seat instanceof Player && (<Player>seat).id === this.memberProfile.Id))

        if (leaveSeatPlayer && this.callTimeConfiguration?.IsCallTime && (!this.callTimeStatus || this.callTimeStatus.CanLeaveTable === false)) {
          this.dialog.open(CallTimeComponent, { data: { playerId: this.memberProfile.Id, tableId: this.id, } })
        } else {
          this.clickLeaveSeat();
        }
        break;
      case ControlActions.RunTwoTimes:
        this.clickRunItTwice();
        break;
      case ControlActions.SitoutNextHand:
        this.clickSitoutNextHand();
        break;
      case ControlActions.StandPat:
        this.cardsChange(false);
        break;
      case ControlActions.Discard:
        this.cardsChange(true);
        break;
      case ControlActions.ReplayPreviousHand:
        this.replayPreviousHand(event.data);
        break;
      case ControlActions.OfferRabbitHunting:
        this.OfferRabbitHunting();
        break;
      case ControlActions.Straddle:
        this.setStraddle();
        break;
    }
  }

  setStraddle() {
    this.betControls.straddle = !this.betControls.straddle;

    const item = {
      Type: RequestMessage.SetStraddle,
      IdTable: this.id,
      value: this.betControls.straddle ? 1 : 0
    };

    this.service.sendData(item);
  }

  OfferRabbitHunting() {
    const item = {
      Type: RequestMessage.AskRabbitHunting,
      IdTable: this.id,
      HandNumber: this.currentHandNumber,
    };
    this.service.sendData(item);
    this.betControls.showOfferRabbitHunting = false
  }

  replayPreviousHand(handId) {
    const item = {
      type: RequestMessage.HandReplay2,
      Value: handId // 253946//253063, //target hand number
    };
    this.service.sendData(item);
  }


  cardsChange(change: boolean) {
    let strCardsIndexes = '';

    if (change) {
      this.playerSelectedCardsIndexes.sort()
      strCardsIndexes = this.playerSelectedCardsIndexes.toString();
      this.playerSelectedCards = []
      this.playerSelectedCardsIndexes = []
    }

    const item = {
      Type: RequestMessage.ReturnCards,
      IdTable: this.id,
      Str1: strCardsIndexes,
      HandNumber: this.currentHandNumber,
    };

    this.service.sendData(item);

    this.betControls.showReplaceCardsButtons = false
  }



  setNbRebuy(players: { IdPlayer: number; IdTable: number; Name: string; NbChips: number; NbRebuy?: number }[]) {

    for (const player of players.filter(el => el.NbRebuy && el.NbRebuy > 0)) {
      for (let i = 0; i < this.seats.length; i++) {
        if (this.seats[i] instanceof Player) {
          if ((<Player>this.seats[i]).id === player.IdPlayer) {
            (<Player>this.seats[i]).setNbRebuyUsed(player.NbRebuy)
          }
        }
      }
    }


  }


  setPlayerGameStatus(callTimeStatus: CallTimeStatus) {

    for (let i = 0; i < this.seats.length; i++) {
      if (this.seats[i] instanceof Player) {
        if ((<Player>this.seats[i]).id === callTimeStatus.IdPlayer) {



          if (this.memberProfile.Id === callTimeStatus.IdPlayer) {
            this.callTimeStatus = callTimeStatus;


            (<Player>this.seats[i]).setCallTime(callTimeStatus)

          } else {
            (<Player>this.seats[i]).setCallTime(callTimeStatus)
          }
        }
      }
    }
  }


  /*
  {
        "Date":"2023-06-08T18:46:14Z",
        "MsgTypeLog":"DisconnectionProtectionTime",
        "Id":25,"IdTable":20016,
        "MsgType":82,
        "IdPlayer":1042,
        "Value":30}
  */

  setPlayerDisconnectProtection(data: any) {
    for (let i = 0; i < this.seats.length; i++) {
      if (this.seats[i] instanceof Player) {
        if ((<Player>this.seats[i]).id === data.IdPlayer) {
          (<Player>this.seats[i]).startDisconnectProtection(data.Value)
        }
      }
    }
  }


  // # 📒 Refactor April 23

  setRebuyEndIn(reBuyEndIn: number) {
    if (this.tourSum !== undefined) {
      this.tourSum.ReBuyEndIn = reBuyEndIn
    }
    this.checkBuyChipsVisibility();
  }

  setBlind(blind: number) {
    this.tourSum.Blind = blind;
    for (let i = 0; i < this.seats.length; i++) {
      if (this.seats[i] instanceof Player) {
        (<Player>this.seats[i]).updateBigBlind(this.getBlind() * 2);
      }
    }

    for (let i = 0; i < this.splitPots.length; i++) {
      if (this.splitPots[i]) {
        this.splitPots[i].updateBigBlind(this.getBlind() * 2)
      }
    }
    for (let i = 0; i < this.pots.length; i++) {
      if (this.pots[i]) {
        this.pots[i].updateBigBlind(this.getBlind() * 2)
      }
    }

    if (this.mainPot) {
      this.mainPot.updateBigBlind(this.getBlind() * 2)
    }

    if (this.mainPot2) {
      this.mainPot2.updateBigBlind(this.getBlind() * 2)
    }
  }

  onUserAppConfigStacksChange() {
    if (this.id) {
      const stacksInBB = this.isTournament ? this.userAppConfig.stacksInBBTourney : this.userAppConfig.stacksInBBRing
      for (let i = 0; i < this.seats.length; i++) {
        if (this.seats[i] instanceof Player) {
          (<Player>this.seats[i]).updateStacksInBB(stacksInBB);
        }
      }

      for (let i = 0; i < this.splitPots.length; i++) {
        if (this.splitPots[i]) {
          this.splitPots[i].updateStacksInBB(stacksInBB)
        }
      }
      for (let i = 0; i < this.pots.length; i++) {
        if (this.pots[i]) {
          this.pots[i].updateStacksInBB(stacksInBB)
        }
      }

      if (this.mainPot) {
        this.mainPot.updateStacksInBB(stacksInBB)
      }

      if (this.mainPot2) {
        this.mainPot2.updateStacksInBB(stacksInBB)
      }

      this.potsCalculateTotalValueOnTable()
    }
  }


  useSpell(value: BountySpellVariant, rotateSeatPlayerId?: number) {
    this.dataManager.spellActivation(this.id, value, rotateSeatPlayerId)
  }

  noSpellZoneStart() {
    console.log("@noSpellZoneStart")
    for (let i = 0; i < this.seats.length; i++) {
      if (this.seats[i] instanceof Player) {
        (<Player>this.seats[i]).noSpellZone();
      }
    }
  }


  onActivateSpellRequest(data) {



    this.dialog.open(DialogBountySpellActiveComponent, {
      width: '360px',
      data
    })

    // this.managerService.settings$.pipe(take(1))
    //   .subscribe(settings => {
    //     const bountySpellDescriptionModel = settings.BountySpellDescriptionModel

    //     for (const item of Object.keys(data)) {
    //       data[item].Info = this.findCurentLang(data[item].Descriptions)
    //     }


    //   })


  }



  findCurentLang(data: any[]): any {
    console.log("@@land", data)

    if (!data || data.length === 0) { return }

    let lang = data.find(el => el.Language === this.translateService.currentLang) // find by current language

    if (!lang) { // if current language doesnt exist first try to find english otherwise set first available language
      lang = data.find(el => el.langId === 'en')
      if (!lang) {
        lang = data[0]
      }
    }

    console.log("@@land", lang)
    return lang
  }


  suddenDeath() {
    this.gameService.parseWsMsg({ ...SuddenDeath, IdTable: this.id })
  }
  suddenDeathImmunity() {
    this.gameService.parseWsMsg({ ...SuddenDeathImmunity, IdTable: this.id })
  }
  resurrection() {
    this.gameService.parseWsMsg({ ...Resurrection, IdTable: this.id })
  }
  scanSpells() {
    this.gameService.parseWsMsg({ ...ScanSpells, IdTable: this.id })
  }
  skipBlindsOnce() {
    this.gameService.parseWsMsg({ ...SkipBlindsOnce, IdTable: this.id })
  }
  rotateSeats() {
    this.gameService.parseWsMsg({ ...RotateSeats, IdTable: this.id })
  }

  rotateSeatsImmunity() {
    this.gameService.parseWsMsg({ ...RotateSeatsImmunity, IdTable: this.id })
  }

  robAPlayerMock() {
    this.gameService.parseWsMsg({ ...JSON.parse(JSON.stringify(RobAPlayerMock)), IdTable: this.id })
  }

  robAPlayerImmunityMock() {
    this.gameService.parseWsMsg({ ...JSON.parse(JSON.stringify(RobAPlayerImmunityMock)), IdTable: this.id })
  }


  startRobAPlayerAnimation(affectedPlayers: RobAPlayerPlayer[], initiatorPlayer: RobAPlayerPlayer) {
    console.log("startRobAPlayerAnimation", affectedPlayers, initiatorPlayer)
    this.robAPlayerAnimation = new RobAPlayer(this.assetsLoader.textures);
    this.container.addChild(this.robAPlayerAnimation.container);

    if (this.useVerticalMode) {
      this.robAPlayerAnimation.setPosition({ x: 540, y: 600 }, AnchorPosition.middleCenter);
    } else {
      this.robAPlayerAnimation.setPosition({ x: 960, y: 445 }, AnchorPosition.middleCenter);
    }

    this.robAPlayerAnimation.setPlayers(affectedPlayers, initiatorPlayer);
    this.robAPlayerAnimation.onShowDoneObservable$.subscribe(() => {
      this.container.removeChild(this.robAPlayerAnimation.container);
    });
  }

  seatChangeShow() {
    for (let i = 0; i < this.seats.length; i++) {
      if (this.seats[i] instanceof Player) {
        if ((<Player>this.seats[i]).id !== this.memberProfile.Id) {
          (<Player>this.seats[i]).showSeatChange()
        }

      }
    }
  }

  seatChangeHide() {
    for (let i = 0; i < this.seats.length; i++) {
      if (this.seats[i] instanceof Player) {
        if ((<Player>this.seats[i]).id !== this.memberProfile.Id) {
          (<Player>this.seats[i]).hideSeatChange()
        }

      }
    }
  }
}
