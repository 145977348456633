import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, of } from 'rxjs';

import { NavItemType, ENavItem } from '../../nav-item-type';
import { AuthService } from '../../../../auth/auth.service';
import { State } from '../../../../app.reducers';
import { MainService } from '../../../main.service';
import { environment } from '../../../../../environments/environment';
import { DialogCasinosInfo } from '../../dialog-casinos-info/dialog-casinos-info.component';
import { CardData, NavTableData, TournamentInformation } from '../../../../shared/models';
import { ManagerService } from '../../../../core/services/manager.service';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { User } from '../../../../shared/models/user';
import { ConfigService } from '../../../../core/services/config.service';
import { v3ManagerService } from '../../../../core/services/v3-manager.service';
import { FallGuysComponent } from '../../../game/dialogs/fall-guys/fall-guys.component';
import { Limit, VariantType2 } from '../../../../shared/enums/poker-types';
import { Currency } from '../../../../shared/enums';
import { SortCards } from '../../../../shared/enums/sort-card';
import { cardSuit } from '../../../../shared/helpers/card-decoder';

@Component({
  selector: 'app-nav-standard',
  templateUrl: './nav-standard.component.html',
  styleUrls: ['./nav-standard.component.scss']
})
export class NavStandardComponent implements OnInit, OnDestroy {


  destroy$: Subject<boolean> = new Subject<boolean>();

  environment = environment;
  navTables: Array<NavTableData> = [];
  activeTab: ENavItem;
  navItems = ENavItem;
  handsetPortrait: boolean;
  handsetLandscape: boolean;
  user: User;
  logoUrl: string;
  public Currency = Currency;

  private subscription: Subscription = new Subscription();

  casinosInfo$: BehaviorSubject<any[]>;
  config;



  tournamentInfo$: Observable<TournamentInformation>;

  variantType2 = VariantType2
  limitType = Limit;


  activeCasinoGame$ = this.managerService.activeCasinoGame$;
  activeCasinoGames$ = this.managerService.activeCasinoGames$;
  showBalance = false
  constructor(

    private dialog: MatDialog,
    private authService: AuthService,
    private mainService: MainService,
    // private store: Store<State>,
    private managerService: ManagerService,
    private breakpointObserver: BreakpointObserver,
    private configService: ConfigService,
    private _v3ManagerService: v3ManagerService,
  ) {

    this.config = this.configService.config;

    // store.select(getActiveGames).subscribe(navTables => {
    //   this.navTables = navTables;
    // }); DEC

    this.tournamentInfo$ = this.managerService.activeGames$.pipe(
      map(activeGames => Object.values(activeGames).find(el => el.isActive)),
      switchMap(activeGame => {
        if (activeGame?.table?.IdTournament) {
          return this._v3ManagerService.getTournament(activeGame?.table?.IdTournament)
        } {
          return of(undefined)
        }
      })
    )

    this.tournamentInfo$.subscribe(d => console.log('TT', d))
  }

  ngOnInit() {
    this.casinosInfo$ = this.managerService.casinosInfo$;

    this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait]).subscribe(result => {
        this.handsetPortrait = result.matches;
      })

    this.breakpointObserver
      .observe([Breakpoints.HandsetLandscape]).subscribe(result => {
        this.handsetLandscape = result.matches;
      })

    this.managerService.activeTab$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (activeTab) => {
          this.activeTab = activeTab
        }
      })


    combineLatest([
      this.managerService.sortCards$,
      this.managerService.activeGames$
    ])

      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([sortCards, activeGames]) => {
          this.navTables = Object.values(activeGames).sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
            .map((activeGame) => {
              const game = { ...activeGame }
              const sortDirection = sortCards[game.table.IdTable] || SortCards.DEFAULT;
              if (game.cardsInHand) {
                game.cardsInHand = this.toSortCards([...game.cardsInHand], sortDirection);
              }
              console.log('SORT activeGame', sortCards, game)
              return game;
            })


        }
      })

    this.managerService.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (user) => {
          this.user = user;

          if (user?.settings?.LobbyClubLogo) {
            this.logoUrl = this.config.httpUrl + '/' + user.settings.LobbyClubLogo;
          } else {
            this.logoUrl = `${environment.dataSource}/assets/${this.config.code}/logo.png`;
          }
        }
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();

    // this.navTables = null; DEC
    // this.subscription.unsubscribe(); DEC
  }

  onSettingsNavToggle() {
    this.mainService.toggleSidenav(true, true);
  }

  onLeftSideNavToggle() {
    this.mainService.toggleSidenav(true, false);
  }



  openFilters() {
    this.mainService.toggleRightSidenav();
  }

  onLobbyClick() {
    this.managerService.setActiveTab(ENavItem.Lobby)
    this.managerService.setActiveGame(null)

    this.mainService.toggleSidenav(false, false);

    // this.store.dispatch(new AppActions.NavAction.ChangeLayout(ENavItem.Lobby)); DEC
    // this.store.dispatch(new AppActions.GameAction.SetActiveGame({ tableId: undefined })); DEC
  }


  onTableClick(navTableData: NavTableData) {
    this.mainService.hideSidenav();
    this.managerService.setActiveTab(ENavItem.Game);
    this.managerService.setActiveGame(navTableData.table.IdTable);


    // this.mainService.hideSidenav();
    // this.store.dispatch(new AppActions.NavAction.ChangeLayout(ENavItem.Game));
    // navTableData.isActive = true;
    // this.store.dispatch(new AppActions.GameAction.SetActiveGame({ tableId: navTableData.table.IdTable }));
  }

  onLeaveTable() {


    this.managerService.onLeaveTable.next(this.navTables.find(el => el.isActive).table.IdTable)
    // if(this.activeTab this.authService.user.memberProfile.Id)
  }

  // logout() { its not used anymore
  //   this.mainService.logout();
  // }

  showCasinosInfo() {
    this.mainService.showCasino()
  }

  clickShowTourInfo(idTournament) {
    this.managerService.setActiveTab(ENavItem.Tournament)
    this.managerService.tournamentView(this.managerService.getTournament(idTournament).tourSum);

    // this.store.dispatch(new AppActions.TournamentsActions.SetViewTournament(this.idTournament));
    // this.store.dispatch(new AppActions.NavAction.ChangeLayout(ENavItem.Tournament));
  }


  clickOpenFallGuysLevelsForApps(data: any) {
    this.dialog.open(FallGuysComponent, {
      data,
      width: '360px',
      //position: { bottom: '100px' }
    });
  }

  openCasinoGame(game) {
    this.managerService.setActiveCasinoGame(game)
  }

  toSortCards(cards: CardData[], cardSorting: SortCards) {
    const suitOrder: { [key: string]: number } = {
      "Hearts": 0,
      "Diamonds": 1,
      "Clubs": 2,
      "Spades": 3
    };

    switch (cardSorting) {
      case SortCards.DEFAULT:
        return cards;
      case SortCards.SUIT:
        return cards.sort((a, b) => {
          // First, compare suit order (descending)
          const suitComparison = suitOrder[cardSuit[b.Suit]] - suitOrder[cardSuit[a.Suit]];
          if (suitComparison !== 0) {
            return suitComparison;
          }

          // If suits are the same, sort by number (descending)
          return b.Number - a.Number;
        });
      case SortCards.UP:
        return cards.sort((a, b) => a.Number - b.Number);
      case SortCards.DOWN:
        return cards.sort((a, b) => b.Number - a.Number);
      default:
        return cards;
    }
  }
}
