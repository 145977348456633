import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';

import { CoreModule } from '../core/core.module';
import { LobbyModule } from './lobby/lobby.module';
import { TournamentModule } from './tournament/tournament.module';
import { CashierModule } from './cashier/cashier.module';
import { GameModule } from './game/game.module';
import { MainComponent } from './main.component';
import { MainService } from './main.service';

import { DealerTipComponent } from './game/dialogs/dealer-tip/dealer-tip.component';
import { BuyChipsComponent } from './game/dialogs/buy-chips/buy-chips.component';
import { SoundControlComponent } from './game/dialogs/sound-control/sound-control.component';
import { SidenavRightComponent } from './sidenav/sidenav-right/sidenav-right.component';
import { NavModule } from './nav/nav.module';
import { SkinSettingsModule } from './skin-settings/skin-settings.module';
import { SharedModule } from '../shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth-guard.service';
import { SidenavLeftComponent } from './sidenav/sidenav-left/sidenav-left.component';
import { QuickInfoComponent } from './sidenav/quick-info/quick-info.component';
import { HandReplayComponent } from './sidenav/hand-replay/hand-replay.component';
import { ShowCardsComponent } from './game/dialogs/show-cards/show-cards.component';
import { SwiperModule } from 'swiper/angular';
import { MenuGameSettingsComponent } from './sidenav/menu-game-settings/menu-game-settings.component';
import { DialogHandReplayComponent } from './sidenav/dialog-hand-replay/dialog-hand-replay.component';
import { ShopComponent } from './shop/shop.component';
import { DialogShopComponent } from './shop/dialog-shop/dialog-shop.component';
import { DialogEmoticonInfoComponent } from './shop/dialog-emoticon-info/dialog-emoticon-info.component';
import { EditProfileComponent } from './dialogs/edit-profile/edit-profile.component';
import { CallTimeComponent } from './game/dialogs/call-time/call-time.component';
import { ForceChangePasswordComponent } from './dialogs/force-change-password/force-change-password.component';
import { PopUpComponent } from './dialogs/pop-up/pop-up.component';
import { DialogBountySpellInfoComponent } from './bounty-spell/dialog-bounty-spell-info/dialog-bounty-spell-info.component';
import { DialogBountySpellActiveComponent } from './bounty-spell/dialog-bounty-spell-active/dialog-bounty-spell-active.component';
import { PromtPwaComponent } from './promt-pwa/promt-pwa.component';
import { CasinoGameComponent } from './casino-game/casino-game.component';
import { FeedbackFromPlayerComponent } from './dialogs/feedback-from-player/feedback-from-player.component';

const routes: Routes = [
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard]
  }
];
@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    NavModule,
    GameModule,
    LobbyModule,
    TournamentModule,
    CashierModule,
    SkinSettingsModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule
  ],
  declarations: [
    MainComponent,
    DealerTipComponent,
    BuyChipsComponent,
    SoundControlComponent,
    SidenavRightComponent,
    SidenavLeftComponent,
    QuickInfoComponent,
    HandReplayComponent,
    ShowCardsComponent,
    MenuGameSettingsComponent,
    DialogHandReplayComponent,
    ShopComponent,
    DialogShopComponent,
    DialogEmoticonInfoComponent,
    EditProfileComponent,
    CallTimeComponent,
    ForceChangePasswordComponent,
    PopUpComponent,
    DialogBountySpellInfoComponent,
    DialogBountySpellActiveComponent,
    PromtPwaComponent,
    CasinoGameComponent,
    FeedbackFromPlayerComponent,
  ],
  exports: [
    RouterModule,
  ],
  providers: [
    MainService,
    DatePipe,
  ],
  entryComponents: [
    DealerTipComponent,
    BuyChipsComponent,
    SoundControlComponent,
    ShowCardsComponent,
    CallTimeComponent,
    DialogHandReplayComponent,
    DialogShopComponent,
    DialogEmoticonInfoComponent,
    PopUpComponent,

    EditProfileComponent,
    ForceChangePasswordComponent
  ],
})
export class MainModule { }
