import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subject, combineLatest, of, timer } from 'rxjs';
import { concatMap, exhaustMap, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DataManagerService } from '../../../core/services/data-manager.service';
import { ManagerService } from '../../../core/services/manager.service';
import { User } from '../../../shared/models/user';


export enum TransactionType {
  SellChips = 'Cash-out',
  SellChipsRecovery = 'Cash-out',
  BuyChips = 'Buy-in',
  BuyChipsReload = 'Buy-in',
  Deposit = 'Deposit',
  Withdraw = 'Withdraw',
  WithdrawCanceled = 'Withdrawal canceled',
  GiftDepositBonus = 'Deposit bonus',
  GiftRakeBackBonus = 'Rakeback',
  GiftReferAFriendReferrer = 'RAF bonus',
  GiftReferAFriendRegister = 'RAF bonus',
  GiftSignupBonus = 'Sign-up bonus',
  Jackpot = 'Jackpot',
  RabbitHunting = 'Rabbit Hunting',
  TournamentAddon = 'Tournament Add-on',
  TournamentEntryFee = 'Tournament Buy-in',
  TournamentEntryFeeRefund = 'Tournament unregister',
  TournamentEntryFeeRefundCancel = 'Tournament canceled',
  TournamentRebuy = 'Tournament Win',
  TournamentWinner = 'Tournament Win'
}




@Component({
  selector: 'app-transfer-history',
  templateUrl: './transfer-history.component.html',
  styleUrls: ['./transfer-history.component.scss']
})
export class TransferHistoryComponent implements OnInit, OnDestroy {

  @ViewChild('transactionsBox', { static: false }) private transactionsBoxContainer: ElementRef;
  readonly PAGINATION_PER_PAGE = 25;

  @Input() newLayout: boolean = false;

  user: User;
  destroy$ = new Subject<boolean>();

  getTransactionHistory = false;
  reload$ = new BehaviorSubject<void>(undefined)
  transactions$: Observable<any>

  transactionType = TransactionType

  offset = 0;
  transactions = [];

  navigation = [
    "Balance",
    "Deposits",
    "Withdrawals",
    "CashGames",
    "Tournaments",
    "Casino"
  ]

  currentNav = "Balance"

  scrollHeight = 0
  constructor(
    private managerService: ManagerService,
    private dataManagerService: DataManagerService

  ) { }

  ngOnInit(): void {



    this.transactions$ = this.reload$.pipe(
      tap(() => {
        try {
          if (this.transactionsBoxContainer) {
            this.scrollHeight = this.transactionsBoxContainer.nativeElement.scrollTop
          }
        } catch (err) { console.log(err) }
      }),
      switchMap(() => combineLatest([this.managerService.user$, this.dataManagerService.getGameToken()])),
      exhaustMap(([user, response]) => this.dataManagerService.getPlayersTransactionHistory(this.offset, this.PAGINATION_PER_PAGE, this.currentNav, user.selectedCurrency.Id, response.token)
        .pipe(
          tap(response => {
            this.offset += response.transactions.length
          }),
          map(response => this.transactions.concat(response.transactions).map(transaction => {
            return {
              ...transaction,
              currencyInfo: user.selectedCurrency
            }
          })),

          tap(transactions => {
            if (transactions.length > 0) {
              this.transactions = transactions;

            }


            setTimeout(() => {
              try {
                if (this.transactionsBoxContainer) {
                  this.transactionsBoxContainer.nativeElement.scrollTop = this.scrollHeight / 2
                }
              } catch (err) { console.log(err) }
            }, 100);
          })
        )),
      shareReplay(1)
    )


    this.managerService.user$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: user => {
          this.user = user
          if (!this.getTransactionHistory) {
            this.getTransactionHistory = true
            this.dataManagerService.getTransactionsHistory(this.user.selectedCurrency.Id);

          }
        }
      })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  cancelTransaction() {
    this.dataManagerService.sendCancelWithdrawal(this.user.selectedCurrency.Id);
  }

  openFromNav(nav: string) {
    this.currentNav = nav
    this.offset = 0
    this.transactions = []
    this.reload$.next()
  }


  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      // **** scrolled to the bottom
      console.log("scrolled to the bottom")
      this.offset = this.offset + 1;
      this.reload$.next()
    } else if (event.target.scrollTop < 1) {
      // **** load more messages in lobby chat
      console.log("load more")
    }
  }
}
