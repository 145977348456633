import * as PIXI from 'pixi.js';

import { Vector } from './vector';
import { Point } from '../models';

import { environment } from '../../../../environments/environment';
import { Currency } from '../../../shared/enums';
import { Common } from './common';
import { CurrencyPipe } from '../../../shared/utils/currency.pipe';
import { CurrencyDeviderPipe } from '../../../shared/utils/currency-devider.pipe';
import { Table } from './table';
import { CurrencyInfo } from '../../../shared/models/currency-info';

export type ElementsPositions = 'right' | 'left';

const ElementsPositions = {
    right: {
        money: { x: 42, y: 11 } as Point, // left-center
        chip: { x: 0, y: -9 } as Point, // top-left
        convertedMoney: { x: 42, y: 30 } as Point, // left-center
    },
    left: {
        money: { x: 55, y: 11 } as Point, // right-center
        chip: { x: 57, y: -9 } as Point, // top-left
        convertedMoney: { x: 55, y: 30 } as Point, // left-center
    }
};

export class Pot {
    container;
    private containerSize: Point = { x: 98, y: 41 };
    private moneyTextStyle = new PIXI.TextStyle({ fontFamily: 'Saira Semi Condensed', fontSize: 26, fontWeight: '500', fill: '#ffffff' });
    private moneyTextStyleAdvanced = new PIXI.TextStyle({ fontFamily: 'Saira Semi Condensed', fontSize: 26, fontWeight: '500', fill: '#00ff24' });
    private convertedMoneyTextStyle = new PIXI.TextStyle({ fontFamily: 'Saira Semi Condensed', fontSize: 26, fontWeight: '500', fill: '#ffffff' });
    private chipsSprite: PIXI.Sprite;
    potId: number; // this will be playerId if pot belongs to player
    private potAmount: PIXI.Text;
    private convertedPotAmount: PIXI.Text;
    amount: number;
    shouldMove = false;
    targetPosition: Point;
    private movingSpeed = 0;
    private movingDirection: Point;
    defaultPosition: Point;
    private currentPosition: Point;
    private timeToTraget = 0.5; // traveling time
    private oldDistance = 9999;
    isSplit;
    rakeAmount = 0;
    currency: CurrencyInfo;
    seatNumber: number;


    private bigBlind: number;
    private stacksInBB: boolean;

    showPotWithRakeAmount: boolean;

    constructor(
        textures,
        potId: number,
        position: Point,
        amount: number,
        currency: CurrencyInfo,
        seatNumber: number,

        bigBlind: number,
        stacksInBB: boolean,
        showPotWithRakeAmount: boolean,
        isSplit?: boolean,

        public table?: Table) {
        console.log("@showPotWithRakeAmount", showPotWithRakeAmount)

        this.bigBlind = bigBlind;
        this.stacksInBB = stacksInBB;



        this.seatNumber = seatNumber;
        this.container = new PIXI.Container();
        this.defaultPosition = {
            x: position.x - this.containerSize.x / 2,
            y: position.y - this.containerSize.y / 2
        };
        this.currentPosition = { x: this.defaultPosition.x, y: this.defaultPosition.y };
        this.container.position.set(this.defaultPosition.x, this.defaultPosition.y);

        this.chipsSprite = new PIXI.Sprite(textures['chip'].texture);
        this.container.addChild(this.chipsSprite);

        if (amount === undefined) {
            amount = 0;
        }
        this.amount = amount;

        let moneyStyle;

        moneyStyle = this.moneyTextStyle;


        this.potAmount = new PIXI.Text(currency ? CurrencyPipe.prototype.transform(
            CurrencyDeviderPipe.prototype.transform(this.amount, currency), currency) : `${this.amount}`,
            moneyStyle);
        this.container.addChild(this.potAmount);

        this.convertedPotAmount = new PIXI.Text(this.currency ? CurrencyPipe.prototype.transform(
            CurrencyDeviderPipe.prototype.transform(this.amount, currency), currency) : `${this.amount}`,
            this.convertedMoneyTextStyle);
        this.container.addChild(this.convertedPotAmount);

        this.convertedPotAmount.visible = false; // TODO: show/hide if skin standard/advanced

        if (Common.isPotLeftSided(seatNumber, this.table.getMaxPlayers())) {
            this.setPositions('left');
        } else {
            this.setPositions('right');
        }

        if (this.amount === 0) {
            this.hidePot();
        }

        this.isSplit = isSplit;
        this.currency = currency;
        console.log('Pot -> constructor -> currency', showPotWithRakeAmount);
        this.showPotWithRakeAmount = showPotWithRakeAmount;
    }

    update(dt) {
        if (this.shouldMove) {
            this.move(dt);
        }
    }

    updatePosition(position: Point) {
        this.defaultPosition = {
            x: position.x - this.containerSize.x / 2,
            y: position.y - this.containerSize.y / 2
        };
        this.currentPosition = { x: this.defaultPosition.x, y: this.defaultPosition.y };
        this.container.position.set(this.defaultPosition.x, this.defaultPosition.y);
    }

    setPositions(position: ElementsPositions) {
        this.chipsSprite.position.set(ElementsPositions[position].chip.x, ElementsPositions[position].chip.y);
        this.potAmount.position.set(ElementsPositions[position].money.x, ElementsPositions[position].money.y);
        this.convertedPotAmount.position.set(ElementsPositions[position].convertedMoney.x, ElementsPositions[position].convertedMoney.y);

        if (position === 'right') {
            this.potAmount.anchor.set(0, 0.5);
            this.convertedPotAmount.anchor.set(0, 0.5);
        } else if (position === 'left') {
            this.potAmount.anchor.set(1, 0.5);
            this.convertedPotAmount.anchor.set(1, 0.5);
        }
    }

    hidePot() {
        this.container.visible = false;
    }

    showPot() {
        this.container.visible = true;
    }

    updateBigBlind(bigBlind: number) {
        this.bigBlind = bigBlind;
        this.updateAmount(this.amount)
    }

    updateStacksInBB(stacksInBB: boolean) {
        this.stacksInBB = stacksInBB;
        this.updateAmount(this.amount)
    }

    updateAmount(newAmount: number, rakeAmount?: number) {
        this.amount = newAmount;

        if (newAmount === undefined) {
            newAmount = 0;
        }

        if (this.showPotWithRakeAmount) {
            if (rakeAmount) {
                if (newAmount > 0) {
                    this.rakeAmount = rakeAmount;
                    newAmount += rakeAmount;
                }
            }
            //  else if (newAmount > 0) {
            //     newAmount += this.rakeAmount;
            // }
        }



        let newAmountConverted = 0
        if (this.stacksInBB) {
            const amount = newAmount / this.bigBlind
            newAmountConverted = Math.round((amount + Number.EPSILON) * 100) / 100

        } else {
            newAmountConverted = this.currency ? CurrencyDeviderPipe.prototype.transform(newAmount, this.currency) : newAmount;
        }

        if (this.stacksInBB) {
            this.potAmount.text = `${newAmountConverted} BB`
        } else {
            this.potAmount.text = this.currency ? CurrencyPipe.prototype.transform(newAmountConverted, this.currency) : `${newAmountConverted}`;
        }

        if(newAmount === 0) {
            this.rakeAmount = 0;
        }

        this.resetPot();
    }

    move(dt) {
        this.currentPosition.x += dt / 1000 * this.movingSpeed * this.movingDirection.x;
        this.currentPosition.y += dt / 1000 * this.movingSpeed * this.movingDirection.y;
        const newDistance = Vector.prototype.distace(this.targetPosition, this.currentPosition);
        if (this.oldDistance <= newDistance) {
            // this.shouldMove = false;
            this.amount = 0; // TODO: check if this is working
            this.resetPot();
            // this.hidePot();
            // this.currentPosition = { x: this.defaultPosition.x, y: this.defaultPosition.y };

            if (this.isSplit) {
                this.selfDistruct();
            }

            return;
        }
        this.container.position.set(this.currentPosition.x, this.currentPosition.y);
        this.oldDistance = newDistance;
    }

    resetPot() {
        this.shouldMove = false;
        this.currentPosition = { x: this.defaultPosition.x, y: this.defaultPosition.y };
        this.container.position.set(this.currentPosition.x, this.currentPosition.y);
        this.oldDistance = 9999;
        if (this.amount === 0) {
            this.hidePot();
        } else {
            this.showPot();
        }
    }

    moveToTarget(targetPosition: Point) {

        if (!this.showPotWithRakeAmount) {
            if (this.rakeAmount > 0) {
                this.amount -= this.rakeAmount;
                this.rakeAmount = 0;
                const newAmountConverted = this.currency ? CurrencyDeviderPipe.prototype.transform(this.amount, this.currency) : this.amount;
                this.potAmount.text = this.currency ? CurrencyPipe.prototype.transform(newAmountConverted, this.currency) : `${newAmountConverted}`;
            }
        }
        this.resetPot();
        this.targetPosition = targetPosition;
        const distanceToTarget = Vector.prototype.distace(targetPosition, this.currentPosition);
        this.movingSpeed = distanceToTarget / this.timeToTraget;
        this.movingDirection = Vector.prototype.direction(targetPosition, this.currentPosition);
        this.shouldMove = true;
    }

    isUpSided(seatPosition: number) {
        if ((seatPosition >= 0 && seatPosition < 4) || seatPosition === 9) {
            return false;
        } else {
            return true;
        }
    }

    selfDistruct() {
        this.container.parent.removeChild(this.container);
    }

    rearngeElements(position: Point, fakeSeatNumber: number) {
        this.defaultPosition = {
            x: position.x - this.containerSize.x / 2,
            y: position.y - this.containerSize.y / 2
        };
        this.currentPosition = { x: this.defaultPosition.x, y: this.defaultPosition.y };
        this.container.position.set(this.defaultPosition.x, this.defaultPosition.y);

        if (Common.isPotLeftSided(fakeSeatNumber, this.table.getMaxPlayers())) {
            this.setPositions('left');
        } else {
            this.setPositions('right');
        }
    }
}
