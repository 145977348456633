import { KamikazeCard } from './kamikaze.card';
import { Seat } from './seat';
import { Player, PlayerGameStatus } from './player';
import { handTypesText } from './player.const';
import { InfoText } from './info.text';
import { AskShowCard } from './ask.show.card';
import { SexyDealer } from './sexy.dealer';
import { Pause } from './pause';
import { cardNameDecoder, cardDecoder, cardSuitDecoder } from '../../../shared/helpers/card-decoder';
import { Pot } from './pot';

import { Point, BetSlider } from '../models';
import { ServerMessage, PlayerStatus, Limit, HandType, VariantType2 } from '../../../shared/enums/poker-types';
import { NavTableData } from '../../../shared/models/nav-table-data';
import { environment } from '../../../../environments/environment';
import { Currency, GameStatus } from '../../../shared/enums';
import { GameService } from '../game.service';

import * as PIXI from 'pixi.js';
import {
    TableSummary,
    TournamentSummary,
    MemberProfile,
    PotData,
    PlayerData,
    Settings,
    SitNGoSummary,
    CardData,
    Winner,
    PlayerSpells
} from '../../../shared/models';
import { Common } from './common';
import { PotTotalText } from './pot-total.text';
import * as CONST_HORIZONTAL from './table-horizontal.const';
import * as CONST_VERTICAL from './table-vertical.const';
import { AuthService } from '../../../auth/auth.service';
import { AssetsLoaderService } from '../../../core/services/assets-loader.service';
import { LoggerService } from '../../../core/services/logger.service';
import { DataManagerService } from '../../../core/services/data-manager.service';
import { CurrencyDeviderPipe } from '../../../shared/utils/currency-devider.pipe';
import { CurrencyCryptoConverterPipe } from '../../../shared/utils/currency-crypto-converter.pipe';
import { CurrencyPipe } from '../../../shared/utils/currency.pipe';
import { FloatingText } from './floating.text';
import { FoldedCardsController } from './cards-cotrollers/folded-cards-controller';
import { GameComponent } from '../game.component';
import { TableCardsController } from './cards-cotrollers/table-cards-controller';
import { AnimatedSexyDealer } from './animated-sexy-dealer';
import { AnchorPosition } from './const';
import { BlindsText } from './blinds-text';
import { TournamentTimers } from './tournament-timers';
import { GenericTextArray } from './generic-text-array';
import { TranslateService } from '@ngx-translate/core';
import { Card } from './card';
import { TripleDrawRoundInfo } from './triple-draw-round-info';
import { SpinAndGo } from './spinAndGo';
import { MatDialog } from '@angular/material/dialog';
import { ShowCardsComponent } from '../dialogs/show-cards/show-cards.component';
import { User } from '../../../shared/models/user';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { iif, interval, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { AnimationText } from './animation-text';
import { CallTimeStatus, ManagerService } from '../../../core/services/manager.service';
import { UserAppConfig } from '../models/user-app-config';
import { env } from 'process';
import { ConfigService } from '../../../core/services/config.service';
import { MemberPreferencesRunItTwice } from '../../../shared/models/member-preferences';
import { pl } from 'date-fns/locale';
import { RobAPlayer } from './robAPlayer';

enum replayStates { init, playing, stoped }
export enum PreBetType { checkFold = 0, check = 1, call = 2, callAny = 3 }
type seatsType = Seat | Player | Pot;

export interface PreBet {
    type: PreBetType;
    text: string;
    visible: boolean;
    checked: boolean;
    value?: string;
}

export interface SeatVisibility {
    seatIndex: number;
    seatNumber: number;
}

export interface TableEvents {
    actionShowCards(item): void;
    actionRebuyChips(item): void;
    actionTakeSeat(item): void;
    clickAddChips(): void;
    parseWsMsg(fakeServerMsg): void;
}

export abstract class Table {
    gameApp: PIXI.Application;


    userAppConfig: UserAppConfig;

    user: User;
    settings: Settings;

    id: number;
    tableSum: TableSummary;
    public tourSum: TournamentSummary;
    sngSum: SitNGoSummary;
    gameStatus: GameStatus;
    container: PIXI.Container;
    table: PIXI.Sprite;
    tableShadow: PIXI.Sprite;
    seats: seatsType[] = [];
    pots: Pot[] = [];
    // cards: Card[] = [];
    // communityCardsT1: Card[] = [];
    // communityCardsT2: Card[] = [];
    tableCardsController: TableCardsController;
    tableNameText: GenericTextArray;
    tableBlindsText: GenericTextArray;

    public seatsInitialized: boolean;
    mainPot: Pot;
    mainPot2: Pot;
    splitPots: Pot[] = [];
    potsOnTableTotalValue = 0;

    public currentHandNumber = 0;
    public previousHandNumber = 0;
    public currentPlayer: Player;
    public currentPot: Pot;

    kamikazeCards: KamikazeCard[] = [];

    potTotalText: PotTotalText;
    blindsText: BlindsText;
    tournamentTimers: TournamentTimers;

    public idTournament: number;
    public isTournament: boolean;

    public waitingList: string[] = [
        'aaaaa',
        'bbbbb',
        'ccccc',
        'ddddd'
    ];

    public showWaitingList = false;
    public canJoinWaitingList = false;

    public actionControls = {
        checkSeatOutNextHand: false,
        showImBackButton: false,
        showBuyRebuyChipsButton: false,
        showTipButton: false,
        showLeaveTableButton: false,
        showReplayButtons: false,
        checkRunItTwice: MemberPreferencesRunItTwice.OFF,
        isStraddle: false

    };

    public betControls = {
        showOfferRabbitHunting: false,

        showReplaceCardsButtons: false,

        showPostBigBlind: false,
        showBetButtons: false,
        showFoldButton: false,
        showCallButton: false,
        showCheckButton: false,
        showRaiseButton: false,
        justForShowCallValue: 0,
        justForShowCallValueBB: '0 BB',

        raiseButtonText: 'page.game.action.raise',
        raiseButtonValue: '',
        raiseButtonValueBigBlind: '',

        betSlider: { min: 0, max: 10, step: 1, value: 0 } as BetSlider,
        showSlider: false,
        showBB2Button: false,
        showBB3Button: false,
        showBB4Button: false,
        showPotButton: false,
        showHalfPotButton: false,
        showQuarterPotButton: false,
        showThreeQuartersPot: false,
        showMaxButton: true,
        callValue: 0,
        potValue: 0,
        minimumBet: 0,
        isBetPreload: false,
        callButtonText: 'page.game.action.call',
        isBringIn: 0,
        checkButtonText: 'page.game.action.check',
        straddle: false,
        amount7StudBet: 0,

        rabbitHuntingPrize: null
    };

    public preBetControls = {
        showPreBet: false,
        preBetSelected: undefined as PreBetType,
        preBetValues: [] as { [preBetType: number]: PreBet },
    };

    fps = 0;
    fpsText;
    fpsRefreshCounter = 0;
    fpsRefreshNumber = 10;

    pauseOverlay: Pause;
    askShowCardOverlay: AskShowCard;

    public isReplay: boolean;
    public _isActive: boolean;
    replayEventData: any[]; // list of messages for hand replay
    tableTimer = 0;
    replayState: replayStates = replayStates.init;
    replayEventIndex = 0;
    replaySpeed = 0; // ms //default is 2000 ms

    sexyDealer: SexyDealer;
    hasSexyDealer = false;

    playerTurnId: number;
    prePlayHandInfo = {
        previousHighestBet: 0,
    };
    public currency;
    memberProfile: MemberProfile;
    public gameWidth = 1920;
    public gameHeight = 930;
    public handTypeText = '';
    public handTypeDoubleBoardText = '';

    seatPositions: Point[] = [];
    playerPositions: Point[] = [];
    potPositions: Point[] = [];
    dealerChipPositions: Point[] = [];
    private chanceToWin = 0;

    dealerChip: PIXI.Sprite;
    dealerChipId: number;
    foldedCardsController: FoldedCardsController;

    public showChat = false;
    public disableChat = false;
    public shouldCloseTable = false;

    tableLogo: PIXI.Sprite;
    tableLogoTop: PIXI.Sprite;

    animDealer: AnimatedSexyDealer;

    CONST;

    buyChipsDialogTimestamp: number;
    // buyShipsDialogTimeout: number;

    public seatText: string;

    tripleDrawRaoundInfo: TripleDrawRoundInfo;
    spinAndGo: SpinAndGo;
    robAPlayerAnimation: RobAPlayer;


    public playerSelectedCards: Card[] = []
    public playerSelectedCardsIndexes: number[] = []


    public startedRabbitHuntingPlayerId: number = null
    rabbitHuntingContainer: PIXI.Container;

    useVerticalMode: boolean



    tableLoader: boolean = true






    handsetPortrait = false;
    handsetLandscape = false;

    jackpotValueOnTable: GenericTextArray; // PIXI JackPot value thats appear on the table

    animationText: AnimationText;
    announceMixTableGameText: AnimationText;



    emoticons: any[] = []

    isStraddle: boolean;


    // ### 2023

    callTimeConfiguration: { CallTimeDuration: number, callTimePercent: number, IsCallTime: boolean }
    callTimeStatus: CallTimeStatus


    isRearrangedPositionsForPlayersPotsSeatsFoldedCards = false
    config;

    antePots: Pot[] = []
    amIOnTable = false;

    constructor(
        public assetsLoader: AssetsLoaderService,
        public authService: AuthService,
        public logger: LoggerService,
        public dataManager: DataManagerService,
        public gameService: GameService,
        public dialog: MatDialog,
        protected breakpointObserver: BreakpointObserver,
        protected managerService: ManagerService,
        protected configService: ConfigService

    ) {
        this.config = this.configService.config;
        this.managerService.emoticonsInfo$.pipe(take(1)).subscribe({
            next: (data) => {
                this.emoticons = data
            }
        })
        // if (environment.settings.useVerticalMode) {
        //     this.CONST = CONST_VERTICAL;
        // } else {
        //     this.CONST = CONST_HORIZONTAL;
        // }
    }


    setJackpotValue(value: number) {
        const valueCurrency = this.isTournament ? value : CurrencyPipe.prototype.transform(CurrencyDeviderPipe.prototype.transform(value, this.currency), this.currency);

        if (value > 0) {
            this.jackpotValueOnTable.setText([`JackPot: ${valueCurrency}`], ' ');
        } else {
            this.jackpotValueOnTable.setText([``], ' ');
        }

    }




    public showRabbitHunting() {


        this.rabbitHuntingContainer = new PIXI.Container();
        this.rabbitHuntingContainer.position.set(this.CONST.TableTextRabbitHunting.x, this.CONST.TableTextRabbitHunting.y);

        const playerName = this.getPlayerNameById(this.startedRabbitHuntingPlayerId)
        let text = new PIXI.Text(`${playerName} requested rabbit hunting`,
            {
                fontFamily: 'Arial',
                fontSize: 26,
                fill: '#EEEEEE',
                fontWeight: 'bold'
            });
        text.anchor.set(0.5);
        this.rabbitHuntingContainer.addChild(text);
        this.container.addChild(this.rabbitHuntingContainer);
    }

    public removeRabbitHunting() {
        this.container.removeChild(this.rabbitHuntingContainer);
        this.rabbitHuntingContainer = null
    }

    public setCardSelection(status: boolean) {

        let seat: Player
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === this.memberProfile.Id) {
                    seat = <Player>this.seats[i]
                }
            }
        }

        if (seat) {
            seat.setCardSelection(status)
        }
    }



    initTable(navTableData: NavTableData) {

        // this.showSpinAndGo([2, 3, 6, 10, 25, 120, 240, 480, 2400], 2400)
        try {



            // type Error Cannot read properties of undefined (reading 'texture')
            this.memberProfile = this.authService.user.memberProfile;

            this.settings = this.authService.settings;





            this.actionControls.checkRunItTwice = this.memberProfile.Preferences.RunItTwice;

            this.container = new PIXI.Container();

            this.fpsText = new PIXI.Text('', { fill: '#FFFFFF' });
            this.container.addChild(this.fpsText);
            this.fpsText.position.set(this.CONST.FpsPosition.x, this.CONST.FpsPosition.y);

            if (!this.useVerticalMode) {
                this.tableShadow = new PIXI.Sprite(this.assetsLoader.textures['tableShadow'].texture);
                this.container.addChild(this.tableShadow);
                this.tableShadow.position.set(this.CONST.TableShadowPosition.x, this.CONST.TableShadowPosition.y);
            }

            this.table = new PIXI.Sprite(this.assetsLoader.textures[this.useVerticalMode ? 'tableVertical' : 'table'].texture);
            this.container.addChild(this.table);

            this.table.position.set(this.CONST.TablePosition.x, this.CONST.TablePosition.y);


            this.tableNameText = new GenericTextArray(this.CONST.TableNamTextPosition, this.config.code);
            this.container.addChild(this.tableNameText.container);
            this.tableBlindsText = new GenericTextArray(this.CONST.TableBlindsTextPosition, this.config.code);
            this.container.addChild(this.tableBlindsText.container);


            this.tableLogo = new PIXI.Sprite(this.assetsLoader.textures['tableLogo'].texture);
            this.tableLogo.position.set(this.CONST.BrandLogoPosition.x, this.CONST.BrandLogoPosition.y);
            this.tableLogo.anchor.set(0.5);
            this.tableLogo.scale.set(0.7)


            if (this.useVerticalMode && this.config.brand === '111') {
                this.tableLogo.scale.set(0.5)
            }
            if (!this.useVerticalMode && this.config.brand === 'sc') {
                this.tableLogo.position.set(this.CONST.BrandLogoPosition.x, this.CONST.BrandLogoPosition.y + 50);
            }
            if (!this.useVerticalMode && this.config.brand === 'solthereum') {
                this.tableLogo.position.set(this.CONST.BrandLogoPosition.x, this.CONST.BrandLogoPosition.y + 50);
            }


            if (!this.useVerticalMode && this.config.code === 'pugsymalone-fbxs') {
                this.tableLogo.scale.set(0.5)

                this.tableLogo.position.set(this.CONST.BrandLogoPosition.x, this.CONST.BrandLogoPosition.y);
            }

            this.container.addChild(this.tableLogo);



            let potPosition: PIXI.Point;

            potPosition = this.CONST.potTotalTextTopPosition;

            this.potTotalText = new PotTotalText('Pot:', potPosition);
            this.container.addChild(this.potTotalText.container);
            this.potTotalText.container.visible = false;

            this.tableCardsController = new TableCardsController(this.assetsLoader.textures, this as unknown as GameComponent, this.useVerticalMode);
            this.container.addChild(this.tableCardsController.container);

            this.preBetControls.preBetValues[PreBetType.checkFold] = {
                type: PreBetType.checkFold,
                text: 'page.game.action.checkFold',
                visible: true,
                checked: false
            };
            this.preBetControls.preBetValues[PreBetType.call] = {
                type: PreBetType.call,
                text: 'page.game.action.call',
                visible: true,
                checked: false
            };
            this.preBetControls.preBetValues[PreBetType.check] = {
                type: PreBetType.check,
                text: 'page.game.action.check',
                visible: true,
                checked: false
            };
            this.preBetControls.preBetValues[PreBetType.callAny] = {
                type: PreBetType.callAny,
                text: 'page.game.action.callAny',
                visible: true,
                checked: false
            };

            if (navTableData.table.tableSum) {
                this.isStraddle = navTableData.table.tableSum.IsStraddle;
                this.callTimeConfiguration = navTableData.table.tableSum.CallTimeConfiguration;

                if (this.callTimeConfiguration?.IsCallTime) {
                    this.dataManager.callTimeStatus(this.id)
                }

            } else if (navTableData.table.tourSum) {
                this.isStraddle = navTableData.table.tourSum.IsStraddle;

            }


            // if (false && environment.settings.useAnimatedDealer) { // not using anymore
            //     this.addAnimDealer();
            // }

            this.tripleDrawRaoundInfo = new TripleDrawRoundInfo(); // fix naming rAound => round
            this.container.addChild(this.tripleDrawRaoundInfo.container);
            this.tripleDrawRaoundInfo.setPosition(this.CONST.TripleDrawInfoPosition, AnchorPosition.middleCenter);



            this.id = navTableData.table.IdTable;
            this.idTournament = navTableData.table.IdTournament;
            this.isReplay = navTableData.table.isReplay;
            this.replayEventData = navTableData.table.replayEventData;
            this.tableSum = navTableData.table.tableSum;
            this.tourSum = navTableData.table.tourSum;
            this.sngSum = navTableData.table.sitNGoSummary;
            this.currency = navTableData.table.currency;




            if (this.idTournament === undefined) {
                this.isTournament = false;
            } else {
                this.isTournament = true;
            }

            if (this.isReplay) {
                // TO DO: for rotation
                const replayTestText = new PIXI.Text('Replay', { fill: '#FFFFFF' });
                this.container.addChild(replayTestText);
                replayTestText.position.set(10, 160);
            }

            this.addMainPots();


            // TO DO: Check where it was used, seems like its not using at all its HTML now
            this.pauseOverlay = new Pause(this.gameWidth, this.gameHeight, this);
            this.container.addChild(this.pauseOverlay.container);
            this.pauseOverlay.stopPause();

            // TO DO: Check where it was used, seems like its not using at all its HTML now
            this.askShowCardOverlay = new AskShowCard(this.gameWidth, this.gameHeight, this);
            this.container.addChild(this.askShowCardOverlay.container);
            this.askShowCardOverlay.stopPause();

            this.createSeatPositions();
            this.createPlayerPositions();
            this.createPotPositions();
            // this.createDealerChipPositions(); // on v2 its on player

            this.initSeatsAndPots();

            if (!this.isReplay && this.tourSum) {
                // wait for all messages from server.
                setTimeout(() => this.checkForTourBreakAndSetBlinds(), 500);
            }

            if (false) { // its on player now environment.settings.useTableDealerChip
                this.dealerChip = new PIXI.Sprite(this.assetsLoader.textures['dealer'].texture);
                this.dealerChip.anchor.set(0.5);
                this.dealerChip.visible = false;
                this.container.addChild(this.dealerChip);
            }

            this.foldedCardsController = new FoldedCardsController(this.assetsLoader.textures, this.getMaxPlayers(), this.useVerticalMode);
            this.container.addChild(this.foldedCardsController.container);

            if (this.idTournament && !this.isReplay) {
                this.blindsText = new BlindsText(this.CONST.BlindsTextPosition);

                this.blindsText.setBlinds(
                    this.tourSum.Blind ? this.tourSum.Blind : this.tourSum.BlindStart,
                    this.tourSum.Ante ? this.tourSum.Ante : 0
                );

                this.container.addChild(this.blindsText.container);


                this.tournamentTimers = new TournamentTimers(this.CONST.TournamentTimersPosition);
                this.container.addChild(this.tournamentTimers.container);



            }


            const currency = this.isTournament ? Currency.Tournament : this.currency;

            if (this.tableSum) {
                const blindValue = this.isTournament ? this.tableSum.Blind : CurrencyPipe.prototype.transform(CurrencyDeviderPipe.prototype.transform(this.tableSum.Blind, currency), currency);
                const blindValue2 = this.isTournament ? this.tableSum.Blind * 2 : CurrencyPipe.prototype.transform(CurrencyDeviderPipe.prototype.transform(this.tableSum.Blind * 2, currency), currency);

                const tableText = [this.tableSum.tableVariant ?? '', this.tableSum.tableLimit ?? '']
                const tableBlinds = [`Blinds: ${blindValue}/${blindValue2}`]
                if (this.tableSum.Ante > 0) {
                    tableText.push(...[`Ante: ${CurrencyPipe.prototype.transform(CurrencyDeviderPipe.prototype.transform(this.tableSum.Ante, currency), currency)}`])
                }

                if (this.tableSum.BringIn > 0) {
                    tableText.push(...[`Bring In: ${CurrencyPipe.prototype.transform(CurrencyDeviderPipe.prototype.transform(this.tableSum.BringIn, currency), currency)}`])
                }

                this.tableNameText.setText(tableText, ', ');
                this.tableBlindsText.setText(tableBlinds, ', ');
            }



            this.checkStraddle()



            // PIXI JackPot value thats appear on the table
            this.jackpotValueOnTable = new GenericTextArray({ x: this.useVerticalMode ? 540 : 960, y: this.useVerticalMode ? 1200 + 30 : (612 + 50 - 40 - 10 + 30) } as PIXI.Point, this.config.code);
            this.container.addChild(this.jackpotValueOnTable.container);
            this.jackpotValueOnTable.setText([``], ' ');


            // # Table animation text

            this.animationText = new AnimationText(this.assetsLoader, this.configService);
            this.container.addChild(this.animationText.container)

            this.announceMixTableGameText = new AnimationText(this.assetsLoader, this.configService);
            this.container.addChild(this.announceMixTableGameText.container)



            // if (!this.isReplay) {
            const themes = this.config.themes
            if (themes && themes.length > 0) {
                const theme = themes.find(el => el.name === localStorage.getItem('theme'))
                if (theme && theme.sound.tableOpen) {
                    this.playSound(this.assetsLoader.sound[theme.name + '-table-open']);
                }
            }
            //}




        } catch (error) {
            console.log('e', error)
        }
    }

    abstract refreshUI()

    checkStraddle() {

        if (this.isStraddle && this.seats.filter(el => {
            const data = el as Player

            return data && data.status === PlayerStatus.Ready
        }).length >= 3) {
            this.actionControls.isStraddle = true
        } else {
            this.actionControls.isStraddle = false

        }
        this.refreshUI()
    }

    public onSeatTextChange(newText: string) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Seat) {
                (<Seat>this.seats[i]).setSeatText(newText);
            }
        }
    }

    private initSeatsAndPots() {
        const maxPlayers = this.getMaxPlayers();
        for (let i = 0; i < maxPlayers; i++) {
            this.pots.push(null);
            this.seats.push(null);
        }
    }

    private checkForTourBreakAndSetBlinds() {
        if (this.tourSum.BreakEndIn > 0) {
            this.gamePaused(this.tourSum.BreakEndIn);
        } else {
            if (this.tourSum.StartIn > -25) {
                this.container.setChildIndex(this.pauseOverlay.container, this.container.children.length - 1);
                this.pauseOverlay.title.text = 'Game starts in';
                this.pauseOverlay.countdownText.text = 'Tournament will start within 1 minute';
                this.pauseOverlay.container.visible = true;
            }
        }

        this.blindsText.setBlinds(
            this.tourSum.Blind ? this.tourSum.Blind : this.tourSum.BlindStart,
            this.tourSum.Ante ? this.tourSum.Ante : 0
        );



        this.tournamentTimers.setBlindTimer(this.tourSum.BlindIncreaseIn);
        this.tournamentTimers.setBreakTimer(this.tourSum.BreakIn);
    }

    private addAnimDealer() {
        this.animDealer = new AnimatedSexyDealer(this.assetsLoader.textures);
        this.animDealer.setPosition({ x: 960, y: 128 }, AnchorPosition.middleCenter);
        this.container.addChild(this.animDealer.container);
    }







    private updateCardTextures() {
        this.updateTableCardsTexture();
        this.updatePlayerCardsTexture();
    }

    private updateTableCardsTexture() {
        this.tableCardsController.refreshCardsTexture();
    }

    private updatePlayerCardsTexture() {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                (<Player>this.seats[i]).cardsController.refreshCardsTexture();
            }
        }
    }

    updateWaitingList(waitingListNamePlayerNames: any[], playersAtTheTable: any[]) {
        /**
         * SHOW WAITING LIST
         * Check if there is an empty seat
         * Check if user already seating at the table
         */
        this.showWaitingList = this.checkForWaitingListVisibility(playersAtTheTable);

        /**
         * CAN JOIN WAITING LIST
         * Check if user is already join to waiting list
         */
        this.canJoinWaitingList = this.checkForCanJoinWaitingList(waitingListNamePlayerNames);

        /**
         * SET WAITING LIST
         * Check if user is already join to waiting list
         */
        if (waitingListNamePlayerNames) {
            this.waitingList = waitingListNamePlayerNames;
        } else {
            this.waitingList = [];
        }
        console.log("@updateWaitingList", this.id, waitingListNamePlayerNames, playersAtTheTable, '--', this.showWaitingList, this.canJoinWaitingList, '++', this.waitingList)

        this.refreshUI()
    }

    checkForWaitingListVisibility(playersAtTheTable): boolean {
        let shouldShowWaitingList = true;
        playersAtTheTable.forEach(player => {
            if (!player.Name) { // if there is an empty seat
                shouldShowWaitingList = false;
            } else if (this.currentPlayer) {
                // if user already seating
                if (this.currentPlayer.nameString === player.Name) {
                    shouldShowWaitingList = false;
                }
            } else {
                // if user already seating without money, after hi click on take a seat
                if (this.memberProfile.Username === player.Name) {
                    shouldShowWaitingList = false;
                }

            }
        });
        console.log('shouldShowWaitingList', shouldShowWaitingList)

        return shouldShowWaitingList;
    }

    checkForCanJoinWaitingList(waitingListNames: string[]) {
        let canJoinWaitingList = true;
        if (waitingListNames) {
            waitingListNames.forEach(player => {
                if (player === this.memberProfile.Username) {
                    canJoinWaitingList = false;
                }
            });
        }
        return canJoinWaitingList;
    }

    showSpinAndGo(multiplierValues: number[], multiplierValue: number) {
        setTimeout(() => {
            this.spinAndGo = new SpinAndGo(this.assetsLoader.textures);
            this.container.addChild(this.spinAndGo.container);

            if (this.useVerticalMode) {
                this.spinAndGo.setPosition({ x: 540, y: 600 }, AnchorPosition.middleCenter);
            } else {
                this.spinAndGo.setPosition({ x: 960, y: 445 }, AnchorPosition.middleCenter);
            }

            this.spinAndGo.setNumbers(multiplierValues, multiplierValue);
            this.spinAndGo.onShowDoneObservable$.subscribe(() => {
                this.container.removeChild(this.spinAndGo.container);
                this.spinAndGo = null;
            });
        }, 1000);
    }

    updateTable(dt) {

        // check for first frame
        // if (dt > 99999) { this.logger.log('large dt>', dt); dt = 30; }
        if (!environment.production) {
            this.fpsRefreshCounter++;
            this.fps += Math.round(1000 / dt);
            if (this.fpsRefreshCounter >= this.fpsRefreshNumber) {
                this.fpsText.text = 'FPS:' + Math.round(this.fps / this.fpsRefreshNumber);
                this.fpsRefreshCounter = 0;
                this.fps = 0;
            }
        }

        // update all active players
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                (<Player>this.seats[i]).update(dt);
            }
        }

        // update all active pots
        for (let i = 0; i < this.pots.length; i++) {
            if (this.pots[i] != null) {
                this.pots[i].update(dt);
            }
        }

        // update main pot
        this.mainPot.update(dt);
        this.mainPot2.update(dt);

        // update split pots
        for (const splitPot of this.splitPots) {
            if (splitPot != null) {
                splitPot.update(dt);
            } else {
                delete this.splitPots[this.splitPots.indexOf(splitPot)];
            }
        }

        // update kamikaze kards
        for (const kamikaze of this.kamikazeCards) {
            if (kamikaze != null) {
                kamikaze.update(dt);
            } else {
                delete this.kamikazeCards[this.kamikazeCards.indexOf(kamikaze)];
            }
        }

        // update table cards
        this.tableCardsController.update(dt);

        // update pause overlay
        this.pauseOverlay.update(dt);

        // update ask show cards overlay
        this.askShowCardOverlay.update(dt);

        // update folded cards
        this.foldedCardsController.update(dt);

        if (this.isReplay) {
            this.playReplay(dt);
        }

        if (this.tournamentTimers) {
            this.tournamentTimers.update(dt);
        }

        if (this.spinAndGo) {
            this.spinAndGo.update(dt);
        }
        if (this.robAPlayerAnimation) {
            this.robAPlayerAnimation.update(dt);
        }
        this.animationText.update(dt)
        this.announceMixTableGameText.update(dt)



        // igor update zindex active players
        // for (let i = 0; i < this.seats.length; i++) {
        //     if (this.seats[i] instanceof Player) {
        //         const player = this.seats[i] as Player
        //         if (player.id === this.authService.user.memberProfile.Id) {
        //             this.container.setChildIndex(this.seats[i].container, this.container.children.length - 1); // igor update zindex active players
        //         }
        //     }
        // }
    }

    markNotedPlayers(playerNotes, playerColors) {

        if (!playerNotes && !playerColors) { return; }

        const notedPlayersIds = playerNotes ? this.getNotedPlayersIds(playerNotes) : [];
        const notedColorsPlayersIds = playerColors ? this.getNotedPlayersIds(playerColors) : [];


        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                const player = (<Player>this.seats[i]);
                player.hideNote();

                let found = false
                if (notedPlayersIds.length > 0) {


                    notedPlayersIds.forEach(notedPlayerId => {
                        if (player.id === notedPlayerId) {

                            found = true
                            player.showNote(playerNotes[notedPlayerId.toString()], playerColors ? playerColors[notedPlayerId.toString()] : null);
                        }
                    });
                }

                if (!found && notedColorsPlayersIds.length > 0) {
                    notedColorsPlayersIds.forEach(notedPlayerId => {
                        if (player.id === notedPlayerId) {
                            player.showNote('', playerColors ? playerColors[notedPlayerId.toString()] : null);
                        }
                    });
                }

            }
        }
    }

    getNotedPlayersIds(playerNotes) {
        const idsStr = Object.keys(playerNotes);
        const playerIds = idsStr.map(playerId => parseInt(playerId, 10));
        return playerIds;
    }



    gamePaused(pauseTime: number) {
        this.container.setChildIndex(this.pauseOverlay.container, this.container.children.length - 1);

        this.pauseOverlay.title.text = 'Game Starts In:';
        this.pauseOverlay.startPause(pauseTime);
    }

    // ask player to show it's cards if everyone has folded
    askShowCard(IdPlayer: number, timeToRespond: number) {
        // if it's me
        if (IdPlayer === this.memberProfile.Id) {
            // if enabled automaticly send show cards to server


            if (this.memberProfile.Preferences.AutoMuck) {
                this.actionShowCards(0);
            } else { // ask player to show cards
                if (!this.handsetPortrait) {
                    this.container.setChildIndex(this.askShowCardOverlay.container, this.container.children.length - 1);
                    this.askShowCardOverlay.startPause(timeToRespond);

                } else {
                    this.dialog.open(ShowCardsComponent, {
                        data: { time: timeToRespond },
                        width: '300px',
                        position: { bottom: '75px', }
                    }).afterClosed().subscribe(data => {
                        if (data) {
                            this.actionShowCards(1);
                        }
                    });
                }
            }
        }
    }

    playSound(sound) {
        if (this._isActive && sound) {
            sound.play();
        }
    }

    createKamikazeCard(startPosition: Point, endPosition: Point) {
        const kamikazeCard = new KamikazeCard(this.assetsLoader.textures, startPosition, endPosition);
        this.container.addChild(kamikazeCard.container);
        this.kamikazeCards.push(kamikazeCard);
    }

    createSplitPot(potAmount: number, targetPositionIndex: number) {
        const splitPot = new Pot(
            this.assetsLoader.textures,
            0,
            this.CONST.MainPotPositions[0],
            potAmount,
            (this.isTournament ? null : this.currency),
            99,


            (this.getBlind() * 2),
            (this.isTournament ? this.userAppConfig.stacksInBBTourney : this.userAppConfig.stacksInBBRing),


            false,
            this
        ); // TODO: zasto nije isSplit = true u kostruktoru
        this.container.addChild(splitPot.container);
        this.splitPots.push(splitPot);
        splitPot.moveToTarget(this.potPositions[targetPositionIndex]);
    }

    createAntePot(potAmount: number, potPosition: Point) {

        const antePot = new Pot(
            this.assetsLoader.textures,
            0,
            potPosition,
            potAmount,
            (this.isTournament ? null : this.currency),
            99,


            (this.getBlind() * 2),
            (this.isTournament ? this.userAppConfig.stacksInBBTourney : this.userAppConfig.stacksInBBRing),


            false,
            this
        ); // TODO: zasto nije isSplit = true u kostruktoru
        this.container.addChild(antePot.container);
        this.splitPots.push(antePot); // split post is used for ante pots as well
        this.antePots.push(antePot)
        antePot.moveToTarget(this.CONST.MainPotPositions[0]);
    }

    getPlayerPositions(): Point[] {

        if (this.getMaxPlayers() === 6) {
            return this.CONST.PlayerPositionsFor6Players;
        } else {
            return this.CONST.PlayerPositions;
        }

    }

    getPotPositions(): Point[] {

        if (this.getMaxPlayers() === 6) {
            return this.CONST.PotPositionsFor6Players;
        } else {
            return this.CONST.PotPositions;
        }

    }

    getSeatPositions(): Point[] {

        if (this.getMaxPlayers() === 6) {
            if (false) {
                return this.CONST.SeatPositionsAdvancedFor6Players;
            } else {

                return this.CONST.SeatPositionsStandardFor6Players;

            }
        } else {
            if (false) {
                return this.CONST.SeatPositionsAdvanced;
            } else {

                return this.CONST.SeatPositionsStandard;

            }
        }
    }

    getDealerChipPositions(): Point[] {
        if (this.getMaxPlayers() === 6) {

            return this.CONST.DealerChipPositionsFor6Players;

        } else {

            return this.CONST.DealerChipPositions;

        }
    }

    getMaxPlayers(): number {
        if (this.tourSum !== undefined) {
            return this.tourSum.TournamentNbSeatsPerTable;
        } else if (this.sngSum !== undefined) {
            return this.sngSum.TournamentNbSeatsPerTable;
        } else if (this.tableSum !== undefined) {
            // TODO: if replay table
            // if (this.id < 0) {
            //     return 10; // to do | start 5 april
            // }

            return this.tableSum.MaxPlayers;
        } else {

            return 10; // DEFAULT
        }
    }

    getIsFast(): boolean {
        if (this.tourSum !== undefined) {
            return this.tourSum.IsFast;
        } else if (this.sngSum !== undefined) {
            return this.sngSum.IsFast;
        } else if (this.tableSum !== undefined) {
            // TODO: if replay table
            if (this.id < 0) {
                return false;
            }
            return this.tableSum.IsFast;
        } else {

            return false;
        }
    }

    getBlind(): number {
        if (this.tourSum !== undefined) {
            if (this.tourSum.Blind === 0) {
                this.tourSum.Blind = this.tourSum.BlindStart;
            }
            return this.tourSum.Blind;
        } else if (this.sngSum !== undefined) {
            return this.sngSum.Blind;
        } else if (this.tableSum !== undefined) {
            // TODO: if replay table
            if (this.id < 0) {
                return 0;
            }
            return this.tableSum.Blind;
        } else {

            return 0;
        }
    }

    getLimit(): number {
        if (this.tourSum !== undefined) {
            return this.tourSum.Limit;
        } else if (this.sngSum !== undefined) {
            return this.sngSum.Limit;
        } else if (this.tableSum !== undefined) {
            // TODO: if replay table
            if (this.id < 0) {
                return 0;
            }
            return this.tableSum.Limit;
        } else {

            return 0;
        }
    }

    getVariant(): number {

        if (this.tourSum !== undefined) {
            return this.tourSum.Variant;
        } else if (this.sngSum !== undefined) {
            return this.sngSum.Variant;
        } else if (this.tableSum !== undefined) {
            // TODO: if replay table
            if (this.id < 0) {
                return 0;
            }
            return this.tableSum.Variant2;
        } else {

            return 0;
        }
    }

    changeBlindLevel(blindLevel: number = 0, smallBlindAmount: number = 0, anteAmount: number = 0) {

        if (this.isTournament) {
            this.tourSum.Blind = smallBlindAmount;
            this.tourSum.Ante = anteAmount;
            this.tourSum.BlindLevel = blindLevel;
            this.blindsText.setBlinds(smallBlindAmount, anteAmount);
        }

        if (this.sngSum) {
            this.sngSum.Blind = smallBlindAmount;
            this.sngSum.Ante = anteAmount;
            this.sngSum.BlindLevel = blindLevel;
        }
    }

    setBlindIncreaseTimer(time: number) {
        this.tournamentTimers.setBlindTimer(time);
    }

    setGameBreakTimer(time: number) {
        this.tournamentTimers.setBreakTimer(time);
    }

    checkBuyChipsVisibility() {
        let shouldShow = false;

        if (this.tourSum !== undefined) {
            this.actionControls.showTipButton = false;


            if (this.tourSum?.ReBuyEndIn > 0 && this.tourSum?.ReBuyNbLimit > this.currentPlayer?.nbRebuy) {

                const reBuyThreshold = this.tourSum.ReBuyThreshold
                const reBuyThreshold2x = this.tourSum.ReBuyThreshold + this.tourSum.TournamentStartupChips;

                if (this.tourSum?.DoubleRebuy) {
                    shouldShow = this.currentPlayer.money <= reBuyThreshold2x;
                } else if (this.tourSum?.ReBuyThreshold > 0) {
                    shouldShow = this.currentPlayer.money <= reBuyThreshold;
                }
            }


        } else {
            shouldShow = true;
        }




        if (this.isReplay) {
            this.actionControls.showBuyRebuyChipsButton = false;
            return;
        }

        if (this.isTournament) {
            this.actionControls.showBuyRebuyChipsButton = shouldShow;
            return;
        }

        if (this.currentPlayer) {
            if (this.currentPlayer.money >= this.getBlind() * this.tableSum.TakeSeatMax * 2) {
                this.actionControls.showBuyRebuyChipsButton = false;
                return;
            }
        } else {
            this.actionControls.showBuyRebuyChipsButton = false;
            return;
        }

        this.actionControls.showBuyRebuyChipsButton = shouldShow;
    }

    actionTakeSeat(seatPosition: number) {
        if (this.gameService.buyChips(this.id, 0, true)) {
            this.dataManager.sendTakeSeat(this.id, seatPosition);
        }
    }

    actionShowCards(showCardsConfirmed: number) {
        this.dataManager.sendShowCards(this.id, this.currentHandNumber, showCardsConfirmed);

    }

    createPlayerPositions() {
        this.playerPositions = [];
        const positions = this.getPlayerPositions();
        const visibility = this.getSeatVisibility();
        visibility[this.getMaxPlayers()].forEach((element, index) => {
            if (element) {

                this.playerPositions.push(positions[index]);


            }
        });
    }

    createPotPositions() {
        this.potPositions = [];
        const positions = this.getPotPositions();
        const visibility = this.getSeatVisibility();
        visibility[this.getMaxPlayers()].forEach((element, index) => {
            if (element) {
                this.potPositions.push(positions[index]);
            }
        });
    }

    createSeatPositions() {
        this.seatPositions = [];
        const positions = this.getSeatPositions();

        const visibility = this.getSeatVisibility();

        visibility[this.getMaxPlayers()].forEach((element, index) => {
            if (element) {
                this.seatPositions.push(positions[index]);
            }
        });

    }

    createDealerChipPositions() {
        this.dealerChipPositions = [];
        const positions = this.getDealerChipPositions();
        const visibility = this.getSeatVisibility();
        visibility[this.getMaxPlayers()].forEach((element, index) => {
            if (element) {
                this.dealerChipPositions.push(positions[index]);
            }
        });
    }

    getSeatVisibility() {
        if (true) { //usePlayerPositionsWithDealer
            return this.CONST.SeatsVisibilityWithDealer;
        } else {
            return this.CONST.SeatsVisibility;
        }
    }

    addSeatsAndPlayers(
        playersList: PlayerData[],
        cards: any[],
        handNumber: number,
        currentPlayerIdTurn: number,
        pots: any[],
        IdTournamentTable?: number) {



        this.currentHandNumber = handNumber;
        if (this.currentHandNumber === undefined) {
            this.currentHandNumber = 0;
        }
        let isAlreadyAtTheTable = false;
        const maxNumOfPlayers = this.getMaxPlayers();

        for (let i = 0; i < maxNumOfPlayers; i++) {
            const player = playersList[i];




            if (player !== undefined && player !== null) {

                for (let i = 0; i < this.seats.length; i++) {
                    if (this.seats[i] instanceof Player) {
                        if ((<Player>this.seats[i]).id === player.Id) {

                            this.container.removeChild(this.seats[i].container);
                            this.container.removeChild(this.pots[i].container);
                            this.pots[i] = null;
                            this.seats[i] = null;


                            break;
                        }
                    }
                }


                const newPlayer = this.addPlayer(i, player);


                const pot = this.addPlayerPot(i, player.Id, player.MoneyBid);

                if (player.HasFold) {
                    this.foldedCardsController.addCard(i, player.Cards.length);
                }

                if (player.IsDealer !== undefined && player.IsDealer === true) {
                    this.setDealer(player.Id);
                }


                if (player.Id === this.memberProfile.Id) {
                    this.amIOnTable = true;


                    this.currentPlayer = newPlayer;
                    this.currentPot = pot;
                    isAlreadyAtTheTable = true;


                    // add cards to mini table
                    if (newPlayer.cardsController.cards.length > 0) {
                        const cardsInHand = [];
                        for (const card of newPlayer.cardsController.cards) {
                            cardsInHand.push(card.cardData);
                        }
                        this.gameService.setCardsInHand(this.id, cardsInHand);
                    }
                }



                //##

            } else {

                this.seats[i] =
                    new Seat(this.assetsLoader.textures, i, this.seatPositions[i], this, i);
                this.container.addChild(this.seats[i].container);
            }
        }

        this.seatsInitialized = true;

        if (isAlreadyAtTheTable) {
            // hide all seats (player has set on table)
            this.hideSeats();

            this.checkBuyChipsVisibility();





            this.setReplayButtonsVisibility();
            this.potsCalculateTotalValueOnTable();


            this.rearrangePositionsForPlayersPotsSeatsFoldedCards();
        }


        // init table cards
        const tableCardsData: CardData[] = [];
        cards.forEach(card => {
            const cardData: CardData = {
                Suit: card.Suit,
                Number: card.Number,
                Name: cardDecoder(card.Suit, card.Number),
                IsRabbitHunting: card.IsRabbitHunting,
                IsPublic: card.IsPublic

            };
            tableCardsData.push(cardData);
        });
        this.tableCardsController.addCommunityCards(tableCardsData);

        // init main pots
        for (let i = 0; i < pots.length; i++) {
            if (i === 0) {
                this.mainPot.updateAmount(pots[i]);
            } else if (i === 1) {
                this.mainPot2.updateAmount(pots[i]);
            }
        }

        if (this.tourSum !== undefined) {
            // if tournament hide take a seat buttons
            this.hideSeats();
        }

        this.markNotedPlayers(this.memberProfile.Preferences.PlayerNotes, this.memberProfile.Preferences.PlayerColor);


    }

    addPlayer(seatNumber: number, playerData: any, totalNumberOfCards: number = 0): Player {
        const player: Player = new Player(
            this.gameApp.renderer,
            this.assetsLoader.textures,
            playerData.Id,
            seatNumber,
            this.playerPositions[seatNumber],
            playerData.Name,
            playerData.Avatar,
            playerData.Money,
            (this.getIsFast() ?
                this.settings.TimeToPlayFast :
                this.settings.TimeToPlayNormal),
            (this.isTournament ? null : this.currency),
            this as unknown as GameComponent,
            this.useVerticalMode,

            (this.getBlind() * 2),
            (this.isTournament ? this.userAppConfig.stacksInBBTourney : this.userAppConfig.stacksInBBRing),
            this.config,
            !!playerData.CelebrityPlayer,
            playerData.Country,

            playerData.Cards,
            playerData.Level,
            playerData.HasFold,
            playerData.Bounty,
            playerData.RealName,
            playerData.Mark,
            this.assetsLoader,
            this.emoticons,
            this.currency,
            playerData?.PlayerSpells?.BountySpell,
            playerData?.PlayerSpells?.EarnedSpells,
            this.tourSum?.BountySpellConfiguration?.IdBountySpellTemplate > 0
        );

        this.seats[seatNumber] = player;

        if (player.id === this.authService.user.memberProfile.Id) { // TO DO on mobile enlarge player

            player.container.scale.set(0.65);

        } else {
            player.container.scale.set(0.65);

        }
        this.container.addChild(player.container);
        if (player.id === this.authService.user.memberProfile.Id) {
            this.container.setChildIndex(player.container, this.container.children.length - 1); // igor update zindex active players
        }


        if (playerData.Id === this.memberProfile.Id) {
            player.amITheOne = true;
            this.currentPlayer = player;

            this.checkBuyChipsVisibility();

        }

        if (playerData.Bounty) {
            player.setBounty(
                CurrencyPipe.prototype.transform(
                    CurrencyDeviderPipe.prototype.transform(
                        playerData.Bounty,
                        this.currency,
                    ),
                    this.currency
                ),
                playerData.CelebrityPlayer
            );
        }

        return player;
    }

    addPlayerPot(seatNumber: number, playerId: number, moneyBid: number = 0): Pot {


        const pot: Pot = new Pot(
            this.assetsLoader.textures,
            playerId,
            this.potPositions[seatNumber],
            moneyBid,
            (this.isTournament ? null : this.currency),
            seatNumber,


            (this.getBlind() * 2),
            (this.isTournament ? this.userAppConfig.stacksInBBTourney : this.userAppConfig.stacksInBBRing),


            false,
            this
        );




        this.pots[seatNumber] = pot;
        this.container.addChild(this.pots[seatNumber].container);

        return pot;
    }





    getPlayerTargetIndexPosition() {
        /*
            # maxPlayers return max possible players for that table, usually by table.MaxPlayers
            # based on SeatVisibility it should return the player index where he is on 6'clock - center
        */
        const maxPlayers = this.getMaxPlayers();
        console.log("@ maxPlayers", maxPlayers)
        if (maxPlayers === 10) {
            return 6; // set to center
        } else if (maxPlayers === 9) {
            if (this.useVerticalMode) {
                return 4; // set to center
            } else {
                return 5; // set to center
            }
        } else if (maxPlayers === 8) {
            return 5;
        } else if (maxPlayers === 7) {
            return 4;
        } else if (maxPlayers === 6) {
            if (this.useVerticalMode) {
                return 3; // set to center
            } else {
                return 4; // set to center
            }
        } else if (maxPlayers === 5) {
            return 3;
        } else if (maxPlayers === 4) {
            return 2;
        } else if (maxPlayers === 3) {
            if (this.useVerticalMode) {
                console.log("@ here maxPlayers === 3")
                return 8
                    ; // set to center
            } else {
                return 8; // set to center
            }

        } else if (maxPlayers === 2) {
            if (this.useVerticalMode) {
                return 3;
            } else {
                return 1;
            }
        } else {
            return 6;
        }
    }

    addMainPots() {
        this.mainPot = new Pot(
            this.assetsLoader.textures,
            0,
            this.CONST.MainPotPositions[0],
            0,
            (this.isTournament ? null : this.currency),
            99,


            (this.getBlind() * 2),
            (this.isTournament ? this.userAppConfig.stacksInBBTourney : this.userAppConfig.stacksInBBRing),


            false,
            this
        ); // TODO: what to do with main pot side type up or down?
        this.mainPot.setPositions('left');
        this.container.addChild(this.mainPot.container);
        this.mainPot2 = new Pot(
            this.assetsLoader.textures,
            0,
            this.CONST.MainPotPositions[1],
            0,
            (this.isTournament ? null : this.currency),
            99,


            (this.getBlind() * 2),
            (this.isTournament ? this.userAppConfig.stacksInBBTourney : this.userAppConfig.stacksInBBRing),


            false,
            this
        ); // TODO: what to do with main pot side type up or down?
        this.mainPot2.setPositions('left');
        this.container.addChild(this.mainPot2.container);
    }

    removeMainPots() {
        this.container.removeChild(this.mainPot.container);
        this.mainPot = null;
        this.container.removeChild(this.mainPot2.container);
        this.mainPot2 = null;
    }




    potsCalculateTotalValueOnTable() {

        // Pot: value on the table


        this.potsOnTableTotalValue = 0;

        this.pots.forEach(pot => {
            if (pot) {
                this.potsOnTableTotalValue += pot.amount;
            }
        });

        if (this.mainPot) {
            this.potsOnTableTotalValue += this.mainPot.amount;
        }

        let potTotalText = ''
        if ((this.isTournament ? this.userAppConfig.stacksInBBTourney : this.userAppConfig.stacksInBBRing)) {
            const amount = this.potsOnTableTotalValue / (this.getBlind() * 2)
            potTotalText = `${Math.round((amount + Number.EPSILON) * 100) / 100} BB`
        } else {
            potTotalText = this.isTournament ? `${this.potsOnTableTotalValue}` :
                CurrencyPipe.prototype.transform(
                    CurrencyDeviderPipe.prototype.transform(this.potsOnTableTotalValue, this.currency),
                    this.currency
                )
        }

        this.potTotalText.setAmount(potTotalText);

        this.potTotalText.container.visible = !!this.potsOnTableTotalValue;

        return this.potsOnTableTotalValue;
    }

    hideSeats() {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Seat) {
                (<Seat>this.seats[i]).hideSeat();
            }
        }
    }

    showSeats() {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Seat) {
                (<Seat>this.seats[i]).showSeat();
            }
        }
    }

    clearPlayerList() {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] != null) {
                this.container.removeChild(this.seats[i].container);
            }
            this.seats[i] = null;
        }

        for (let i = 0; i < this.pots.length; i++) {
            if (this.pots[i] != null) {
                this.container.removeChild(this.pots[i].container);
            }
            this.pots[i] = null;
        }

        this.potsCalculateTotalValueOnTable();
    }

    public getNumberOfPlayersAtTheTable() {
        let numberOfPlayers = 0;
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                numberOfPlayers++;
            }
        }
        return numberOfPlayers;
    }

    private checkForNavTablePlayerMoney(playerId: number, playerBalance: number) {
        if (playerId === this.memberProfile.Id) {
            this.gameService.setPlayerMoney(this.id, playerBalance);
        }
    }

    getMemberProfile() {
        return this.memberProfile
    }

    playerTurnChange(
        playerId: number,
        minimumBet: number = 0,
        minimumRaise: number = 0,
        maximumRaise: number = 0,
        playerBalance: number = 0,
        alreadyBetInThisRound: number = 0,
        previousRoundBet: number = 0,
        potValue: number = 0,
        highestBet: number = 0,
        timeToPlay: number = 0,
        isBringIn: number = 0) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === playerId) {
                    this.playerTurnId = (<Player>this.seats[i]).id;
                    (<Player>this.seats[i]).takeTurn(this.isReplay);
                    (<Player>this.seats[i]).updateBalance(playerBalance);
                    this.checkForNavTablePlayerMoney(playerId, playerBalance);


                    if (timeToPlay) {
                        (<Player>this.seats[i]).setTimer(timeToPlay);
                    }

                    // If it's my turn
                    if (playerId === this.memberProfile.Id) {

                        console.log("@------debug", minimumBet, alreadyBetInThisRound);

                        (<Player>this.seats[i]).currentCheckValue = alreadyBetInThisRound;
                        this.betControls.callValue = minimumBet;

                        if ((<Player>this.seats[i]).status === PlayerStatus.Sitout) {
                            this.betControls.showBetButtons = false;
                        } else {
                            this.betControls.showBetButtons = true;
                        }

                        const canRaise = minimumRaise > 0;

                        this.betControls.minimumBet = minimumBet;
                        this.betControls.showPostBigBlind = false;
                        this.betControls.showFoldButton = true;
                        this.betControls.showCheckButton = false;
                        this.betControls.showCallButton = false;
                        this.betControls.showRaiseButton = false;
                        this.betControls.showSlider = false;
                        this.betControls.showPotButton = false;
                        this.betControls.showHalfPotButton = false;
                        this.betControls.showQuarterPotButton = false;
                        this.betControls.showThreeQuartersPot = false;
                        this.betControls.isBetPreload = false;
                        this.betControls.isBringIn = isBringIn;

                        const canUse345BbQuickBet = (
                            canRaise && this.gameStatus === GameStatus.PreFlop && minimumBet < this.getBlind() * 4
                        );
                        const bb2 = this.getBlind() * 2 * 2; // *2-Big Blind *2-number of BB
                        const bb3 = this.getBlind() * 2 * 3; // *2-Big Blind *3-number of BB
                        const bb4 = this.getBlind() * 2 * 4; // *2-Big Blind *4-number of BB
                        const halfPot = Math.ceil(potValue / 2);
                        const quarterPot = Math.ceil(potValue / 4);
                        const threeQuartersPot = Math.ceil(potValue * 0.75);

                        this.preBetControls.showPreBet = false;

                        this.betControls.betSlider = {
                            min: Math.min(minimumRaise, maximumRaise),
                            max: maximumRaise, // minimumBet === -1 ? playerBalance : maximumRaise,
                            step: this.getBlind(),
                            value: Math.min(minimumRaise, maximumRaise),
                            playerBalance: playerBalance,

                            bigBlind: {
                                min: Math.round(Math.min(minimumRaise, maximumRaise) / (this.getBlind() * 2)),
                                max: Math.round(maximumRaise / (this.getBlind() * 2)),
                                step: Math.round(this.getBlind() / (this.getBlind() * 2)),
                                value: Math.round(Math.min(minimumRaise, maximumRaise) / (this.getBlind() * 2)),
                                playerBalance: Math.round(playerBalance / (this.getBlind() * 2)),
                            }
                        };
                        this.updateRaiseButton(minimumBet, isBringIn);

                        if (maximumRaise > potValue) {
                            this.betControls.showPotButton = true;
                        } else {
                            this.betControls.showPotButton = false;
                        }

                        if (maximumRaise !== 0) {
                            this.betControls.showRaiseButton = true;
                            (<Player>this.seats[i]).currentPot = potValue;
                        } else {
                            (<Player>this.seats[i]).currentPot = minimumBet;
                        }
                        this.betControls.potValue = (<Player>this.seats[i]).currentPot;

                        // -1 cannot check or call
                        if (minimumBet < 0) {
                            this.betControls.showCallButton = false; // play sound to alert the user he must fold or go all-in
                            this.preBetControls.preBetValues[PreBetType.callAny].checked = false;
                            this.preBetControls.preBetValues[PreBetType.call].checked = false;
                            this.preBetControls.preBetValues[PreBetType.check].checked = false;
                        } else {
                            // nobody raise - check
                            if (minimumBet === alreadyBetInThisRound) {
                                this.betControls.showCheckButton = true;
                            } else {
                                this.betControls.showCallButton = true; // someone raise - call
                                this.betControls.justForShowCallValue = minimumBet - alreadyBetInThisRound;
                                this.preBetControls.preBetValues[PreBetType.check].checked = false;

                                const amount = this.betControls.justForShowCallValue / (this.getBlind() * 2)
                                this.betControls.justForShowCallValueBB = `${Math.round((amount + Number.EPSILON) * 100) / 100} BB`


                            }
                        }

                        if (maximumRaise === ((<Player>this.seats[i]).money + alreadyBetInThisRound)) {
                            (<Player>this.seats[i]).currentAllIn = maximumRaise - alreadyBetInThisRound;
                        } else {
                            if (this.getLimit() !== Limit.NL) {
                                (<Player>this.seats[i]).currentAllIn = maximumRaise;
                            }
                        }

                        if (this.getLimit() === Limit.FL) {
                            if (!this.betControls.showCallButton) {
                                this.betControls.showRaiseButton = true;


                            } else {
                                this.betControls.showRaiseButton = false;
                            }

                            // this.betControls.raiseButtonText += `\r\n${this.getConvertedAmountText(this.betControls.betSlider.max)}`;


                            this.betControls.showSlider = false;
                            this.betControls.showPotButton = false;



                            if (playerBalance === (<Player>this.seats[i]).currentAllIn) {


                                this.betControls.raiseButtonText = 'BUTTON.ALLIN';
                                this.betControls.showRaiseButton = true;
                            }
                        }

                        if (this.getLimit() === Limit.PL) {

                            this.betControls.showMaxButton = false;

                            if (potValue < playerBalance) {
                                this.betControls.showPotButton = true;
                            } else {
                                this.betControls.showMaxButton = true;
                            }


                            if (this.betControls.betSlider.min === this.betControls.betSlider.max) {
                                this.betControls.showSlider = false;
                            }
                        }

                        if (canRaise) {
                            this.betControls.showRaiseButton = true;
                            if (this.getLimit() !== Limit.FL) {
                                this.betControls.showSlider = true;
                            }
                        }

                        if (canUse345BbQuickBet && minimumRaise <= bb2 && maximumRaise >= bb2) {
                            this.betControls.showBB2Button = true;
                        } else {
                            this.betControls.showBB2Button = false;
                        }
                        if (canUse345BbQuickBet && minimumRaise <= bb3 && maximumRaise >= bb3) {
                            this.betControls.showBB3Button = true;
                        } else {
                            this.betControls.showBB3Button = false;
                        }
                        if (canUse345BbQuickBet && minimumRaise <= bb4 && maximumRaise >= bb4) {
                            this.betControls.showBB4Button = true;
                        } else {
                            this.betControls.showBB4Button = false;
                        }

                        const anyBbButtonVisible =
                            this.betControls.showBB3Button || this.betControls.showBB4Button || this.betControls.showBB2Button;
                        if (!anyBbButtonVisible && canRaise && minimumRaise <= quarterPot && maximumRaise >= quarterPot) {
                            this.betControls.showQuarterPotButton = true;
                        } else {
                            this.betControls.showQuarterPotButton = false;
                        }
                        if (!anyBbButtonVisible && canRaise && minimumRaise <= halfPot && maximumRaise >= halfPot) {
                            this.betControls.showHalfPotButton = true;
                        } else {
                            this.betControls.showHalfPotButton = false;
                        }

                        if (!anyBbButtonVisible && canRaise && minimumRaise <= threeQuartersPot && maximumRaise >= threeQuartersPot) {
                            this.betControls.showThreeQuartersPot = true;
                        } else {
                            this.betControls.showThreeQuartersPot = false;
                        }

                        // betting buttons override for pot limit games
                        if (this.getLimit() === Limit.PL) {
                            if (this.betControls.betSlider.value === this.betControls.potValue / 4) {
                                this.betControls.showQuarterPotButton = false;
                            }

                            if (this.betControls.betSlider.value === this.betControls.potValue / 2) {
                                this.betControls.showHalfPotButton = false;
                            }

                            if (this.betControls.betSlider.value === this.betControls.potValue * 0.75) {
                                this.betControls.showThreeQuartersPot = false;
                            }

                            if (this.betControls.potValue === this.getBlind() * 2 * 3) {
                                this.betControls.showBB3Button = false;
                            }

                            if (this.betControls.potValue === this.getBlind() * 2 * 4) {
                                this.betControls.showBB4Button = false;
                            }
                        }

                        if (this.getLimit() === Limit.FL) {
                            this.betControls.showHalfPotButton = false;
                            this.betControls.showQuarterPotButton = false;
                        }

                        if (!this.isReplay) {
                            // this.playSound(this.assetsLoader.sound.WaitingOver);

                            setTimeout(() => {
                                this.playSound(this.assetsLoader.sound.YourTurn);
                            }, 400);
                        }
                        this.gameService.setOnMove(this.id, true);
                        // pre-play
                        // tslint:disable-next-line:max-line-length
                        // https://github.com/ElninhoConsulting/Poker-Windows-Client/blob/1038da4232853f512865bc9a44579d70891f2fc2/SharedTheme/uc/ucBidding.xaml.cs

                        if (highestBet > this.prePlayHandInfo.previousHighestBet) {
                            this.preBetControls.preBetValues[PreBetType.call].checked = false; // not enought money for the call
                        }

                        if (minimumBet < 0) {
                            if (this.preBetControls.preBetValues[PreBetType.checkFold].checked) {
                                this.uncheckPrePlayButtons();
                                this.actionFold();
                            }
                        } else {
                            if (this.preBetControls.preBetValues[PreBetType.checkFold].checked) {
                                this.uncheckPrePlayButtons();
                                if (highestBet === 0 || highestBet === alreadyBetInThisRound) {
                                    this.actionCheck();
                                } else {
                                    this.actionFold();
                                }
                            } else if (this.preBetControls.preBetValues[PreBetType.check].checked) {
                                this.uncheckPrePlayButtons();
                                this.actionCheck();
                            } else if (this.preBetControls.preBetValues[PreBetType.call].checked) {
                                this.uncheckPrePlayButtons();
                                this.actionCall();
                            } else if (this.preBetControls.preBetValues[PreBetType.callAny].checked) {
                                this.uncheckPrePlayButtons();
                                this.actionCall();
                            } else {
                                this.uncheckPrePlayButtons();
                            }
                        }
                    } else {
                        this.otherPlayerTurn(minimumBet, highestBet, alreadyBetInThisRound);
                        this.gameService.setOnMove(this.id, false);
                    }
                    this.bringInButtonText();
                } else { // for all other players
                    (<Player>this.seats[i]).endTurn();
                }
            }
        }
        this.prePlayHandInfo.previousHighestBet = highestBet;
        this.setReplayButtonsVisibility();
        this.potsCalculateTotalValueOnTable();

        // check for break overlay
        if (this.tourSum) {
            this.pauseOverlay.stopPause();
        }
    }

    bringInButtonText() {
        if (this.tableSum) {
            if (this.tableSum.Variant2 === VariantType2.SevenStud ||
                this.tableSum.Variant2 === VariantType2.SevenStudHiLo) {
                console.log("BRING IN *** ***", this.betControls)
                if (this.betControls.isBringIn) {
                    this.betControls.showFoldButton = false;
                    this.betControls.raiseButtonText = 'BUTTON.BET';
                    this.betControls.raiseButtonValue = `${this.getConvertedAmountText(this.betControls.betSlider.max)}`;


                    const amount = this.betControls.betSlider.max / (this.getBlind() * 2)
                    this.betControls.raiseButtonValueBigBlind = `${Math.round((amount + Number.EPSILON) * 100) / 100} BB`

                    this.betControls.checkButtonText = 'Bring In ';
                    this.betControls.callButtonText = 'Bring In ';
                } else {
                    this.betControls.showFoldButton = true;
                    this.betControls.raiseButtonText = 'BUTTON.RAISE';
                    this.betControls.raiseButtonValue = `${this.getConvertedAmountText(this.betControls.betSlider.max)}`;

                    const amount = this.betControls.betSlider.max / (this.getBlind() * 2)
                    this.betControls.raiseButtonValueBigBlind = `${Math.round((amount + Number.EPSILON) * 100) / 100} BB`

                    this.betControls.checkButtonText = 'BUTTON.CHECK';
                    this.betControls.callButtonText = 'BUTTON.CALL';
                }
            }
        }
    }

    replaceCardPeriodOver(idPlayer: number, numberOfCardsReplaced: number = 0) {
        const player = this.getPlayerById(idPlayer);
        if (!player) { return; }

        player.setReplacedCardsNumber(numberOfCardsReplaced, this.gameStatus);



        if (idPlayer === this.memberProfile.Id
            &&
            (
                this.gameStatus === GameStatus.TripleDrawBettingRound1 ||
                this.gameStatus === GameStatus.ReplaceCards1 ||
                this.gameStatus === GameStatus.TripleDrawBettingRound2 ||
                this.gameStatus === GameStatus.ReplaceCards2 ||
                this.gameStatus === GameStatus.TripleDrawBettingRound3
            )
        ) {
            this.setCardSelection(true)
        }
    }

    removePlayerCards(idPlayer: number, cards: Card[]) {
        const player = this.getPlayerById(idPlayer);
        if (!player) { return; }
    }

    actionFold() {
        this.dataManager.sendFold(this.id, this.currentHandNumber);
        this.currentPlayer.endTurn();

        this.betControls.showBetButtons = false;
        this.setReplayButtonsVisibility();
        // this.playSound(this.assetsLoader.soundFold);

        this.handTypeText = '';
        this.handTypeDoubleBoardText = '';
    }

    actionCheck() {
        this.dataManager.sendCheck(this.id, this.betControls.callValue, this.currentHandNumber);
        this.betControls.showBetButtons = false;
        this.setReplayButtonsVisibility();
        this.currentPlayer.endTurn();

        // this.playSound(this.soundCheck);
    }

    actionCall() {
        // if (this.assetsLoader.sound['Call']) {
        //     this.playSound(this.assetsLoader.sound['Call']);
        // }
        this.dataManager.sendCall(this.id, this.betControls.callValue, this.currentHandNumber);
        this.currentPlayer.endTurn();
        this.betControls.showBetButtons = false;
        this.setReplayButtonsVisibility();
        // this.playSound(this.assetsLoader.soundFold);
    }

    actionRaise(raiseValue: number) {
        // if (this.betControls.raiseButtonText === 'BUTTON.BET' && this.assetsLoader.sound['Bet']) {
        //     this.playSound(this.assetsLoader.sound['Bet']);
        // } else {
        //     //  this.playSound(this.assetsLoader.sound['Rise']);
        // }

        this.dataManager.sendCheck(this.id, raiseValue, this.currentHandNumber);
        this.currentPlayer.endTurn();
        this.betControls.showBetButtons = false;
        this.setReplayButtonsVisibility();
        // this.playSound(this.soundRise);
    }

    /*
    actionBet(betValue: number) {
        this.dataManager.sendBet(this.id, betValue, this.currentHandNumber);
        this.currentPlayer.endTurn();
        this.betControls.showBetButtons = false;
        this.setReplayButtonsVisibility();
    }
    */

    actionAllIn() {
        this.dataManager.sendAllIn(this.id, this.betControls.betSlider.max, this.currentHandNumber);
        this.currentPlayer.endTurn();
        this.betControls.showBetButtons = false;
        this.setReplayButtonsVisibility();
        // this.playSound(this.soundAllIn);
    }

    updateRaiseButton(minimumBet: number, isBringIn: number) {

        switch (minimumBet) {
            case 0:
                this.betControls.raiseButtonText = 'BUTTON.BET';
                this.addValueToBetButton();
                break;

            case -1:

                this.betControls.raiseButtonText = 'BUTTON.ALLIN';
                this.addValueToBetButton();

                break;

            default:

                if (this.betControls.betSlider.min) {

                    this.betControls.raiseButtonText = 'BUTTON.RAISE';
                    this.betControls.callButtonText = 'BUTTON.CALL';
                    this.betControls.checkButtonText = 'BUTTON.CHECK';
                    this.bringInButtonText();
                    this.addValueToRaiseButton();
                } else {

                    this.addValueToBetButton();

                    this.betControls.raiseButtonText = 'BUTTON.ALLIN';
                }
                break;
        }
    }

    addValueToRaiseButton() {


        if (this.isTournament) {
            this.betControls.raiseButtonValue = `${this.betControls.betSlider.value}`;
        } else {
            this.betControls.raiseButtonValue = `${this.getConvertedAmountText(this.betControls.betSlider.value)}`;
        }

        const amount = this.betControls.betSlider.value / (this.getBlind() * 2)
        this.betControls.raiseButtonValueBigBlind = `${Math.round((amount + Number.EPSILON) * 100) / 100} BB`



    }

    addValueToBetButton() {
        const value = (this.betControls.betSlider.value === 0 ? this.betControls.betSlider.max : this.betControls.betSlider.value);

        //   const value = (this.betControls.betSlider.value === 0 ? this.betControls.betSlider.playerBalance : this.betControls.betSlider.value);


        if (this.isTournament) {
            this.betControls.raiseButtonValue = `${value}`;
        } else {
            this.betControls.raiseButtonValue = `${this.getConvertedAmountText(value)}`;
        }

        const amount = value / (this.getBlind() * 2)
        this.betControls.raiseButtonValueBigBlind = `${Math.round((amount + Number.EPSILON) * 100) / 100} BB`


    }

    getConvertedAmountText(value: number): string {
        let retText = this.isTournament ? `${value}` : CurrencyPipe.prototype.transform(
            CurrencyDeviderPipe.prototype.transform(value, this.currency), this.currency);
        return retText;
    }

    private otherPlayerTurn(minimumBet: number = 0, highestBet: number, alreadyBetInThisRound: number) {
        this.betControls.showBetButtons = false;
        if (this.currentPlayer) {
            /*
            this.logger.error(
                'highestBet: ', highestBet,
                'alreadyBetInThisRound :', this.currentPlayer.alreadyBetInThisRound,
                'isPlaying:', Common.isPlaying(this.currentPlayer.status),
                'minimumBet', minimumBet,
                'this.currentPlayer.currentCheckValue', this.currentPlayer.currentCheckValue
                );
            */
            if (Common.isPlaying(this.currentPlayer.status)
                && this.currentPlayer.cardsController.cards.length > 0) {
                this.preBetControls.showPreBet = true;
                this.preBetControls.preBetValues[PreBetType.callAny].visible = true;
                this.preBetControls.preBetValues[PreBetType.checkFold].visible = true;

                // [ ]fold, [ ]call
                // [ ]check/fold, [ ]check
                if (minimumBet === this.currentPlayer.currentCheckValue) {
                    // nobody raise - check
                    this.preBetControls.preBetValues[PreBetType.checkFold].text = 'page.game.action.checkFold';
                    this.preBetControls.preBetValues[PreBetType.check].visible = true;

                    this.preBetControls.preBetValues[PreBetType.call].visible = false;
                    this.preBetControls.preBetValues[PreBetType.call].checked = false;
                    // this.controls.preBetValues[PreBetType.check].visible = this.prePlayHandInfo.previousHighestBet < highestBet;
                } else {
                    if (this.currentPlayer.alreadyBetInThisRound === highestBet) {
                        this.preBetControls.preBetValues[PreBetType.checkFold].text = 'page.game.action.checkFold';
                        this.preBetControls.preBetValues[PreBetType.check].visible = true;

                        this.preBetControls.preBetValues[PreBetType.call].visible = false;
                        this.preBetControls.preBetValues[PreBetType.call].checked = false;
                    } else {
                        // someone else raise - call
                        this.preBetControls.preBetValues[PreBetType.call].visible = true;
                        if (minimumBet > 0) {
                            this.preBetControls.preBetValues[PreBetType.call].value = `(${this.isTournament ? minimumBet - this.currentPlayer.alreadyBetInThisRound : CurrencyPipe.prototype.transform(
                                CurrencyDeviderPipe.prototype.transform(
                                    minimumBet - this.currentPlayer.alreadyBetInThisRound,
                                    this.currency
                                ),
                                this.currency)
                                })`;
                        }

                        if (highestBet !== this.prePlayHandInfo.previousHighestBet) {
                            this.preBetControls.preBetValues[PreBetType.call].checked = false;
                        }

                        if (highestBet > (this.currentPlayer.money + this.currentPlayer.currentCheckValue)) {
                            this.preBetControls.preBetValues[PreBetType.call].checked = false; // not enought money for the call
                        }

                        this.preBetControls.preBetValues[PreBetType.checkFold].text = 'page.game.action.fold';
                        this.preBetControls.preBetValues[PreBetType.check].visible = false;
                        this.preBetControls.preBetValues[PreBetType.check].checked = false;
                    }
                }
                if (this.getLimit() === Limit.PL) {
                    // chkAllIn.Content = Dic.Get("GlobalPot");
                    // this.controls.preBetValues[PreBetType.call].visible = true;
                }
                if (this.getLimit() === Limit.FL) {
                    // this.controls.preBetValues[PreBetType.callAny].visible = false;
                }
            } else {
                this.preBetControls.showPreBet = false;
            }
        }
    }

    hidePrePlayButtons() {
        this.preBetControls.preBetValues[PreBetType.call].visible = false;
        this.preBetControls.preBetValues[PreBetType.check].visible = false;
        this.preBetControls.preBetValues[PreBetType.checkFold].visible = false;
        this.preBetControls.preBetValues[PreBetType.callAny].visible = false;
    }

    uncheckPrePlayButtons() {
        this.preBetControls.preBetValues[PreBetType.call].checked = false;
        this.preBetControls.preBetValues[PreBetType.check].checked = false;
        this.preBetControls.preBetValues[PreBetType.checkFold].checked = false;
        this.preBetControls.preBetValues[PreBetType.callAny].checked = false;
    }

    setReplayButtonsVisibility() {
        if (this.isReplay) {
            this.actionControls.showReplayButtons = true;
            this.betControls.showBetButtons = false;
            return;
        } else {
            this.actionControls.showReplayButtons = false;
        }
    }


    playerFoldCards(playerId: number, absoluteMoneyBet: number, playerBalance: number) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === playerId) {


                    this.foldedCardsController.addCard(i, (<Player>this.seats[i]).cardsController.cards.length);

                    (<Player>this.seats[i]).foldCards();
                    (<Player>this.seats[i]).updateBalance(playerBalance);
                    this.checkForNavTablePlayerMoney(playerId, playerBalance);
                    (<Player>this.seats[i]).setStatusText('Fold');
                    this.playSound(this.assetsLoader.sound.Fold);


                    // if it's me clear navbar (cards and info)
                    if ((<Player>this.seats[i]).id === this.memberProfile.Id) {
                        this.gameService.setOnMove(this.id, false);
                        this.gameService.setFolded(this.id, true);


                        this.checkBuyChipsVisibility();




                    }
                }
            }
        }


        const themes = this.config.themes
        if (themes && themes.length > 0) {
            const theme = themes.find(el => el.name === localStorage.getItem('theme'))
            if (theme && theme.sound.fold) {
                this.playSound(this.assetsLoader.sound[theme.name + '-fold']);
            }
        }
    }

    playerBidAnte(playerId: number, absoluteMoneyBet: number = 0, playerBalance: number = 0) {
        this.removeAntePots()

        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === playerId) {

                    // 📚 not needed at player! this.createAntePot(absoluteMoneyBet, (<Pot>this.pots[i]).defaultPosition);

                    // (<Pot>this.pots[i]).updateAmount(absoluteMoneyBet);
                    // (<Pot>this.pots[i]).moveToTarget(this.mainPot.defaultPosition);

                    (<Player>this.seats[i]).updateBalance(playerBalance);
                    this.checkForNavTablePlayerMoney(playerId, playerBalance);
                    (<Player>this.seats[i]).setStatusText('Ante');

                } else {

                }
            }
        }
        this.potsCalculateTotalValueOnTable();
    }

    playerBidSmallBlind(playerId: number, absoluteMoneyBet: number = 0, playerBalance: number = 0) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === playerId) {
                    (<Pot>this.pots[i]).updateAmount(absoluteMoneyBet);
                    (<Player>this.seats[i]).alreadyBetInThisRound = absoluteMoneyBet;
                    (<Player>this.seats[i]).updateBalance(playerBalance);
                    this.checkForNavTablePlayerMoney(playerId, playerBalance);

                } else {

                }
            }
        }
        this.potsCalculateTotalValueOnTable();
    }

    playerBidBigBlind(playerId: number, absoluteMoneyBet: number = 0, playerBalance: number = 0) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === playerId) {
                    (<Pot>this.pots[i]).updateAmount(absoluteMoneyBet);
                    (<Player>this.seats[i]).alreadyBetInThisRound = absoluteMoneyBet;
                    (<Player>this.seats[i]).updateBalance(playerBalance);
                    this.checkForNavTablePlayerMoney(playerId, playerBalance);

                } else {

                }
            }
        }
        this.potsCalculateTotalValueOnTable();
    }

    playerBidCheck(playerId: number, absoluteMoneyBet: number = 0, playerBalance: number = 0) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === playerId) {
                    (<Pot>this.pots[i]).updateAmount(absoluteMoneyBet);
                    (<Player>this.seats[i]).alreadyBetInThisRound = absoluteMoneyBet;
                    (<Player>this.seats[i]).updateBalance(playerBalance);
                    this.checkForNavTablePlayerMoney(playerId, playerBalance);
                    (<Player>this.seats[i]).setStatusText('Check');
                    this.playSound(this.assetsLoader.sound.Check);

                } else {

                }
            }
        }
        this.potsCalculateTotalValueOnTable();
    }

    playerBidBet(playerId: number, absoluteMoneyBet: number = 0, playerBalance: number = 0) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === playerId) {
                    (<Pot>this.pots[i]).updateAmount(absoluteMoneyBet);
                    (<Player>this.seats[i]).alreadyBetInThisRound = absoluteMoneyBet;
                    (<Player>this.seats[i]).updateBalance(playerBalance);
                    this.checkForNavTablePlayerMoney(playerId, playerBalance);
                    (<Player>this.seats[i]).setStatusText('Bet');

                    if (this.assetsLoader.sound['Bet']) {
                        this.playSound(this.assetsLoader.sound['Bet']);
                    } else {
                        this.playSound(this.assetsLoader.sound.Chip);
                    }
                } else {

                }
            }
        }
        this.potsCalculateTotalValueOnTable();
    }

    playerBidCall(playerId: number, absoluteMoneyBet: number = 0, playerBalance: number = 0) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === playerId) {
                    (<Pot>this.pots[i]).updateAmount(absoluteMoneyBet);
                    (<Player>this.seats[i]).alreadyBetInThisRound = absoluteMoneyBet;
                    (<Player>this.seats[i]).updateBalance(playerBalance);
                    this.checkForNavTablePlayerMoney(playerId, playerBalance);
                    (<Player>this.seats[i]).setStatusText('Call');
                    this.playSound(this.assetsLoader.sound.Call);

                } else {

                }
            }
        }
        this.potsCalculateTotalValueOnTable();

        const themes = this.config.themes
        if (themes && themes.length > 0) {
            const theme = themes.find(el => el.name === localStorage.getItem('theme'))
            if (theme && theme.sound.call) {
                this.playSound(this.assetsLoader.sound[theme.name + '-call']);
            }
        }
    }

    playerBidRaise(playerId: number, absoluteMoneyBet: number = 0, playerBalance: number = 0, bidStraddle: boolean = false) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === playerId) {
                    (<Pot>this.pots[i]).updateAmount(absoluteMoneyBet);
                    (<Player>this.seats[i]).alreadyBetInThisRound = absoluteMoneyBet;
                    (<Player>this.seats[i]).updateBalance(playerBalance);
                    this.checkForNavTablePlayerMoney(playerId, playerBalance);
                    (<Player>this.seats[i]).setStatusText('Raise');
                    this.playSound(this.assetsLoader.sound.Rise);

                    if (bidStraddle) {
                        (<Player>this.seats[i]).straddle.visible = true;
                    }
                } else {

                }
            }
        }
        this.potsCalculateTotalValueOnTable();

        const themes = this.config.themes
        if (themes && themes.length > 0) {
            const theme = themes.find(el => el.name === localStorage.getItem('theme'))
            if (theme && theme.sound.betRaise) {
                this.playSound(this.assetsLoader.sound[theme.name + '-bet-raise']);
            }
        }
    }

    playerBidAllIn(playerId: number, absoluteMoneyBet: number = 0, playerBalance: number = 0) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === playerId) {

                    (<Pot>this.pots[i]).updateAmount(absoluteMoneyBet);
                    (<Player>this.seats[i]).alreadyBetInThisRound = absoluteMoneyBet;
                    (<Player>this.seats[i]).updateBalance(playerBalance);
                    this.checkForNavTablePlayerMoney(playerId, playerBalance);
                    (<Player>this.seats[i]).setStatusText('All In');
                    this.playSound(this.assetsLoader.sound.AllIn);


                } else {

                }
            }
        }
        this.potsCalculateTotalValueOnTable();
        this.showChat = false;
        this.disableChat = true;

        const themes = this.config.themes
        if (themes && themes.length > 0) {
            const theme = themes.find(el => el.name === localStorage.getItem('theme'))
            if (theme && theme.sound.allIn) {
                this.playSound(this.assetsLoader.sound[theme.name + '-all-in']);
            }
        }
    }

    returnBackMoneyToPlayer(playerId: number, amountToReturn: number = 0, playerBalance: number = 0) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === playerId) {
                    (<Player>this.seats[i]).updateBalance(playerBalance);
                    this.checkForNavTablePlayerMoney(playerId, playerBalance);

                    // this.container.removeChild(this.pots[i].container);
                    // this.pots[i] = null;

                } else {

                }
            }
        }
        this.potsCalculateTotalValueOnTable();
        this.potTotalText.setAmount(this.isTournament ? `${this.potsOnTableTotalValue - amountToReturn}` : CurrencyPipe.prototype.transform(
            CurrencyDeviderPipe.prototype.transform(
                this.potsOnTableTotalValue - amountToReturn,
                this.currency
            ),
            this.currency)
        );
    }

    gameStatusChanged(gameStatus: GameStatus, cards: any[] = []) {

        this.gameStatus = gameStatus;
        if (this.currentPlayer) {
            this.currentPlayer.alreadyBetInThisRound = 0;
        }
        this.uncheckPrePlayButtons();


        if (gameStatus === GameStatus.WaitingPlayers) {
            this.potTotalText.container.visible = true;
            // reset all players
            for (let i = 0; i < this.seats.length; i++) {
                if (this.seats[i] instanceof Player) {
                    (<Player>this.seats[i]).removeCards();
                    (<Player>this.seats[i]).endTurn();
                    this.betControls.showBetButtons = false;
                    this.setReplayButtonsVisibility();
                }
            }
            this.hideDealer();

            // remove old main cards from table
            // remove old community cards T1 from table
            // remove old community cards T2 from table
            this.tableCardsController.removeAllCards();

            // reset main pot
            this.mainPot.updateAmount(0);
            this.mainPot2.updateAmount(0);
            this.potsCalculateTotalValueOnTable();
        } else if (gameStatus === GameStatus.Flop) {
            // if 3-omaha
            if (this.getVariant() === 8) {
                const cardData: CardData = {
                    Suit: cards[0].Suit,
                    Number: cards[0].Number,
                    Name: cardDecoder(cards[0].Suit, cards[0].Number),
                    IsRabbitHunting: cards[0].IsRabbitHunting,
                    IsPublic: cards[0].IsPublic

                };
                this.tableCardsController.addCommunityCardWithIndex(cardData, 2);
            } else { // for all others
                const tableCardsData: CardData[] = [];
                cards.forEach(card => {
                    const cardData: CardData = {
                        Suit: card.Suit,
                        Number: card.Number,
                        Name: cardDecoder(card.Suit, card.Number),
                        IsRabbitHunting: cards[0].IsRabbitHunting,
                        IsPublic: cards[0].IsPublic

                    };
                    tableCardsData.push(cardData);
                });
                this.tableCardsController.addCommunityCards(tableCardsData);
            }

        } else if (gameStatus === GameStatus.Turn) {
            // if 3-omaha
            if (this.getVariant() === 8) {
                const cardData: CardData = {
                    Suit: cards[0].Suit,
                    Number: cards[0].Number,
                    Name: cardDecoder(cards[0].Suit, cards[0].Number),
                    IsRabbitHunting: cards[0].IsRabbitHunting,
                    IsPublic: cards[0].IsPublic

                };
                this.tableCardsController.addCommunityCardWithIndex(cardData, 3);
            } else { // for all others
                if (this.tableCardsController.communityCards.length >= 3) {
                    const cardData: CardData = {
                        Suit: cards[0].Suit,
                        Number: cards[0].Number,
                        Name: cardDecoder(cards[0].Suit, cards[0].Number),
                        IsRabbitHunting: cards[0].IsRabbitHunting,
                        IsPublic: cards[0].IsPublic

                    };
                    this.tableCardsController.addCommunityCardWithIndex(cardData, 3);
                } else {

                }
            }
        } else if (gameStatus === GameStatus.River) {
            // if 3-omaha
            if (this.getVariant() === 8) {
                const cardData: CardData = {
                    Suit: cards[0].Suit,
                    Number: cards[0].Number,
                    Name: cardDecoder(cards[0].Suit, cards[0].Number),
                    IsRabbitHunting: cards[0].IsRabbitHunting,
                    IsPublic: cards[0].IsPublic

                };
                this.tableCardsController.addCommunityCardWithIndex(cardData, 4);
            } else { // for all others
                if (this.tableCardsController.communityCards.length >= 4) {
                    const cardData: CardData = {
                        Suit: cards[0].Suit,
                        Number: cards[0].Number,
                        Name: cardDecoder(cards[0].Suit, cards[0].Number),
                        IsRabbitHunting: cards[0].IsRabbitHunting,
                        IsPublic: cards[0].IsPublic

                    };
                    this.tableCardsController.addCommunityCardWithIndex(cardData, 4);
                } else {

                }
            }
        } else if (gameStatus === GameStatus.Showdown) {
            this.potTotalText.container.visible = false;
        } else if (gameStatus === GameStatus.ReplaceCards1) {
            this.tripleDrawRaoundInfo.setInfoText('First drawing round');
            // this.hidePlayersReplacedCardsInfo();
        } else if (gameStatus === GameStatus.ReplaceCards2) {
            this.tripleDrawRaoundInfo.setInfoText('Second drawing round');
            //   this.hidePlayersReplacedCardsInfo();
        } else if (gameStatus === GameStatus.ReplaceCards3) {
            this.tripleDrawRaoundInfo.setInfoText('Last drawing round');
            //  this.hidePlayersReplacedCardsInfo();
        } else if (gameStatus === GameStatus.TripleDrawBettingRound1) {
            this.tripleDrawRaoundInfo.setInfoText('First betting round');
            this.playerSelectedCards = []
            this.playerSelectedCardsIndexes = []
        } else if (gameStatus === GameStatus.TripleDrawBettingRound2) {
            this.tripleDrawRaoundInfo.setInfoText('Second betting round');
            this.setAllBackgroundsToGray(1)
        } else if (gameStatus === GameStatus.TripleDrawBettingRound3) {
            this.setAllBackgroundsToGray(2)
            this.tripleDrawRaoundInfo.setInfoText('Third betting round');
        } else if (gameStatus === GameStatus.TripleDrawBettingRound4) {
            this.setAllBackgroundsToGray(3)
            this.tripleDrawRaoundInfo.setInfoText('Last betting round');
        }

        this.foldedCardsController.clearCards();
    }



    setAllBackgroundsToGray(size: number) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                (<Player>this.seats[i]).setBackgroundsToGray(size);
            }
        }
    }

    hidePlayersReplacedCardsInfo() {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                (<Player>this.seats[i]).hideReplacedCardsInfo();
            }
        }
    }

    handStart(handNumber: number) {
        // reset all players
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                (<Player>this.seats[i]).removeCards();


                // enable/disable sitout button
                if ((<Player>this.seats[i]).id === this.memberProfile.Id) {
                    if ((<Player>this.seats[i]).status === PlayerStatus.Sitout) {
                        this.actionControls.showImBackButton = true;
                    } else if ((<Player>this.seats[i]).status === PlayerStatus.SitoutNextHand) {
                        this.actionControls.showImBackButton = false;
                        this.actionControls.checkSeatOutNextHand = true;
                    } else if ((<Player>this.seats[i]).status === PlayerStatus.Ready) {
                        this.actionControls.showImBackButton = false;
                        this.actionControls.checkSeatOutNextHand = false;
                    }
                }
            }
        }

        this.hideDealer();

        // remove old cards from table
        this.tableCardsController.removeAllCards();

        // reset main pot
        this.mainPot.updateAmount(0);
        this.mainPot2.updateAmount(0);

        if (this.currentHandNumber !== handNumber) {
            this.previousHandNumber = this.currentHandNumber;
        }
        this.currentHandNumber = handNumber;

        if (this.hasSexyDealer) {
            this.actionControls.showTipButton = true;
        }
        this.setReplayButtonsVisibility();
        this.hidePrePlayButtons();


        // check for break overlay
        if (this.tourSum) {
            this.pauseOverlay.stopPause();
        }



    }

    removeAntePots() {
        // 📚 remove later since its not needed to be at player
        // clean up ante
        this.antePots.forEach(antePot => {
            this.container.removeChild(antePot.container);
        })
        this.antePots = []
    }

    handEnd(handNumber: number) {
        // end of the game


        this.playerTurnId = undefined;
        this.disableChat = false;
        // reset all players
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                (<Player>this.seats[i]).removeCards();
                (<Player>this.seats[i]).straddle.visible = false;


                // if it's me
                if ((<Player>this.seats[i]).id === this.memberProfile.Id) {
                    // if no money
                    if ((<Player>this.seats[i]).money === 0) {
                        if (this.idTournament === undefined &&
                            !this.isReplay &&
                            (<Player>this.seats[i]).status !== PlayerStatus.LeaveSeat
                        ) {
                            // show buy chips dialog
                            //  this.gameService.buyChips(this.id, this.buyShipsDialogTimeout);

                            let time = this.buyChipsDialogTimestamp ? Math.round((this.buyChipsDialogTimestamp - new Date().getTime()) / 1000) : 240
                            this.gameService.buyChips(this.id, time > 0 ? time : 240);

                        }
                    }

                    // clear navbar
                    this.gameService.setOnMove(this.id, false);
                    this.gameService.setFolded(this.id, false);
                    this.gameService.setCardsInHand(this.id, []);
                }
            }

            if (this.pots[i] != null) {
                this.pots[i].updateAmount(0);
            }
        }

        this.hideDealer();

        // remove old cards from table
        this.tableCardsController.removeAllCards();

        // reset main pot
        this.mainPot.updateAmount(0);
        this.mainPot2.updateAmount(0);

        // hide bet buttons
        this.betControls.showBetButtons = false;
        this.betControls.showFoldButton = false;

        this.actionControls.showTipButton = false;

        this.setReplayButtonsVisibility();
        this.potsCalculateTotalValueOnTable();

        this.uncheckPrePlayButtons();

        this.handTypeText = '';
        this.handTypeDoubleBoardText = '';
        this.foldedCardsController.clearCards();

        this.hidePlayersReplacedCardsInfo();
    }

    playerRevealCard(playerId: number, cards: any[]) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === playerId) {
                    (<Player>this.seats[i]).showCards(cards);

                } else {

                }
            }
        }

        // check for break overlay
        if (this.tourSum) {
            this.pauseOverlay.stopPause();
        }
    }

    moveToPot(pots: any[]) {
        for (let i = 0; i < pots.length; i++) {
            if (i === 0) {
                this.mainPot.updateAmount(pots[i].Amount, pots[i].AmountRake);
            } else if (i === 1) {
                this.mainPot2.updateAmount(pots[i].Amount, pots[i].AmountRake);
            }
        }

        for (let i = 0; i < this.pots.length; i++) {
            if (this.pots[i] != null) {
                // this if is for moveToPot after ante so
                // that real pots do not move
                if (this.pots[i].amount > 0) {
                    if (this.mainPot.amount > 0) {
                        this.pots[i].moveToTarget(this.mainPot.defaultPosition);
                    } else if (this.mainPot2.amount > 0) {
                        this.pots[i].moveToTarget(this.mainPot2.defaultPosition);
                    }
                }
            }
        }
        this.hidePrePlayButtons();
    }

    playerTakeSeat(playerId: number, seatPosition: number = 0, playerData, buyChipsDialogTimeout: number) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === playerId) {

                    this.container.removeChild(this.seats[i].container);
                    this.container.removeChild(this.pots[i].container);
                    this.pots[i] = null;
                    this.seats[i] = null;

                    break;
                }
            }
        }

        this.buyChipsDialogTimestamp = new Date().getTime() + buyChipsDialogTimeout * 1000
        // this.buyShipsDialogTimeout = buyShipsDialogTimeout;
        try {
            this.container.removeChild((<Seat>this.seats[seatPosition]).container);
        } catch (e) {

        }

        const newPlayer = this.addPlayer(seatPosition, playerData);
        const pot = this.addPlayerPot(seatPosition, playerId);


        // this.rearrangePositionsForPlayersPotsSeatsFoldedCards();

        if (playerId === this.memberProfile.Id) {
            this.currentPlayer = newPlayer;
            this.currentPot = pot;
            if (playerData.Money === undefined) {
                //this.gameService.buyChips(this.id, this.buyShipsDialogTimeout); // show chips buy menu

                let time = this.buyChipsDialogTimestamp ? Math.round((this.buyChipsDialogTimestamp - new Date().getTime()) / 1000) : 240
                this.gameService.buyChips(this.id, time > 0 ? time : 240);
            }

            // hide all seats (player has set on table)
            this.hideSeats();



            this.checkBuyChipsVisibility();

            this.setReplayButtonsVisibility();

            // &&&
            this.rearrangePositionsForPlayersPotsSeatsFoldedCards();
        } else {
            if (this.memberProfile.Preferences.PlayerNotes) {
                const notedPlayersIds = this.memberProfile.Preferences.PlayerNotes ? this.getNotedPlayersIds(this.memberProfile.Preferences.PlayerNotes) : [];
                const notedColorsPlayersIds = this.memberProfile.Preferences.PlayerColor ? this.getNotedPlayersIds(this.memberProfile.Preferences.PlayerColor) : [];


                let found = false
                if (notedPlayersIds.length > 0) {
                    notedPlayersIds.forEach(id => {
                        if (id === playerId) {
                            found = true

                            newPlayer.showNote(this.memberProfile.Preferences.PlayerNotes[playerId.toString()], this.memberProfile.Preferences.PlayerColor[playerId.toString()]);
                        }
                    });
                }

                if (!found && notedColorsPlayersIds.length > 0) {
                    notedColorsPlayersIds.forEach(id => {
                        if (id === playerId) {

                            newPlayer.showNote('', this.memberProfile.Preferences.PlayerColor[playerId.toString()]);
                        }
                    });
                }

            }
        }

        // 27 remove from waiting list
        const index = this.waitingList.findIndex(el => el.toLowerCase() === playerData.Name.toLowerCase())
        if (index > -1) {
            this.waitingList.splice(index, 1)
        }
        this.showWaitingList = this.waitingList.findIndex(el => el.toLowerCase() === this.memberProfile.Username.toLowerCase()) > -1


        this.refreshUI()
    }

    playerSitOnFirstAvaiableSeat() {
        let seatNumber;
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Seat) {
                seatNumber = (<Seat>this.seats[i]).id;
            }
        }
        this.dataManager.sendTakeSeat(this.tableSum.Id, seatNumber);
    }

    playerBuyChips(playerId: number, chipsTotal: number) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === playerId) {
                    (<Player>this.seats[i]).updateBalance(chipsTotal);
                    this.checkForNavTablePlayerMoney(playerId, chipsTotal);

                    if (playerId === this.memberProfile.Id) {
                        this.checkBuyChipsVisibility();
                    }
                } else {

                }
            }
        }
    }

    playerLeaveTable(IdTable: number, IdPlayer: number, playerData) {



        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === IdPlayer) {
                    this.container.removeChild(this.seats[i].container);
                    this.seats[i] =
                        new Seat(this.assetsLoader.textures, i, this.seatPositions[i], this, i);
                    this.container.addChild(this.seats[i].container);

                    // if player is sitting on the table don't show seat
                    if (this.currentPlayer !== undefined) {
                        (<Seat>this.seats[i]).hideSeat();
                    }

                    this.container.removeChild(this.pots[i].container);
                    this.pots[i] = null;

                    // if it's me
                    if (IdPlayer === this.memberProfile.Id) {
                        this.betControls.showBetButtons = false; // hide action buttons
                        // this.actionControls.showBuyRebuyChipsButton = false; // disable addChips button
                        this.checkBuyChipsVisibility();
                        this.actionControls.showTipButton = false;
                        this.betControls.showPostBigBlind = false;
                        this.preBetControls.showPreBet = false;
                        this.actionControls.showImBackButton = false;

                        this.currentPlayer = undefined;

                        // if it's tournament table
                        if (this.tourSum !== undefined) {
                            this.actionControls.showLeaveTableButton = true;
                        } else {
                            this.showSeats();
                        }

                        this.handTypeText = '';
                        this.handTypeDoubleBoardText = '';
                        this.setPositionsToNormalForPlayersPotsSeatsFoldedCards();

                        if (this.shouldCloseTable) {
                            this.gameService.closeTable(this.id);
                        }
                    }
                    this.setReplayButtonsVisibility();
                }
            }
        }

        this.potsCalculateTotalValueOnTable();
    }

    sendCardsToPlayer(IdPlayer: number, cardsToSend: any[], allCards: any[]) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === IdPlayer) {
                    (<Player>this.seats[i]).receiveCards(allCards);

                    // if (environment.settings.useAnimatedDealer) {
                    //     this.animDealer.dealCard();
                    // }

                    //const startKamikazePos: Point = { x: 960, y: 10 };

                    const startKamikazePos: Point = { x: 960, y: 480 };

                    //const startKamikazePos: Point = { x: 484, y: 439 };

                    const endKamikazePos: Point = {
                        x: (<Player>this.seats[i]).container.position.x,
                        y: (<Player>this.seats[i]).container.position.y
                    };
                    this.createKamikazeCard(startKamikazePos, endKamikazePos);

                    if (IdPlayer === this.memberProfile.Id) {
                        this.betControls.showPostBigBlind = false;

                        const cardsInHand = [];
                        for (const card of (<Player>this.seats[i]).cardsController.cards) {
                            cardsInHand.push(card.cardData);
                        }
                        this.gameService.setCardsInHand(this.id, cardsInHand);
                    }
                }
            }
        }
    }

    playerSetBankTime(IdPlayer: number, bankTime: number) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === IdPlayer) {
                    (<Player>this.seats[i]).setBankTime(bankTime);
                }
            }
        }
    }

    playerUsingBankTime(IdPlayer: number, bankTime: number) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === IdPlayer) {
                    (<Player>this.seats[i]).startUsingTimeBank(bankTime);
                    if ((<Player>this.seats[i]).id === this.memberProfile.Id
                    ) { // removed condtion:  && this.memberProfile.PlayTimeBankSound
                        // this.playSound(this.assetsLoader.sound.TimeBank);
                        this.playSound(this.assetsLoader.sound.TimeBankTimeRunning);
                        //this.assetsLoader.sound.TimeBankTimeRunning.play();
                    }
                }
            }
        }
    }

   

    playerHandType(handType: number, cards: CardData[], onlyReturnValue?: boolean) {
        console.log('playerHandType', handType, cards)

        if (!onlyReturnValue && this.currentPlayer === undefined || this.currentPlayer === null) { return; }
        if (!onlyReturnValue && this.currentPlayer.getNumbersOfCards() <= 0) { return; }

        let handTypeText = ``
        if (<HandType>handType === HandType.High) {
            handTypeText = `${cardNameDecoder(cards[0].Number)} ${handTypesText[handType]}`
        } else if (<HandType>handType === HandType.Pair) {
            // let cardString = '';
            // cards.forEach(card => {
            //     cardString += cardDecoder(card.Suit, card.Number, true) + ' ';
            // });

            handTypeText = `${handTypesText[handType]}, ${cardNameDecoder(cards[0].Number, true)}s`
        } else if (<HandType>handType === HandType.TwoPair) {

            let numbers = []
            let cardString = '';
            cards.forEach(card => {
                if (numbers.indexOf(card.Number) === -1) {
                    cardString += cardDecoder(null, card.Number, true) + 's ';
                    numbers.push(card.Number)
                }
            });

            handTypeText = `${handTypesText[handType]}, ${cardString}`
        } else if (<HandType>handType === HandType.Tripple) {
            handTypeText = `${handTypesText[handType]}, ${cardNameDecoder(cards[0].Number)}s`
        } else if (<HandType>handType === HandType.Straight) {
            handTypeText = `${handTypesText[handType]}, ${cardNameDecoder(cards[0].Number)} High`
        } else if (<HandType>handType === HandType.Flush) {
            handTypeText = `${handTypesText[handType]}, ${cardNameDecoder(cards[0].Number)} high, ${cardSuitDecoder(cards[0].Suit)}`
        } else if (<HandType>handType === HandType.FullHouse) {

            let numbers = []
            let cardStrings = []
            let cardString = '';
            cards.forEach(card => {
                if (numbers.indexOf(card.Number) === -1) {
                    cardStrings.push({ value: card.Number, text: cardNameDecoder(card.Number) })
                }
            });

            cardStrings = cardStrings.sort((a, b) => { return a.value - b.value > 0 ? 1 : -1 })
            console.log("card strings", cardStrings)

            const cardsSetOne = [];
            const cardsSetTwo = [];

            cardStrings.forEach(card => {
                if (cardsSetOne.length === 0) {
                    cardsSetOne.push(card.value)
                } else if (cardsSetOne.indexOf(card.value) !== -1) {
                    cardsSetOne.push(card.value)
                } else {
                    cardsSetTwo.push(card.value)
                }
            })


            const cardName = ['', 'Ones', 'Twos', 'Threes', 'Fours', 'Fives', 'Sixes', 'Sevens', 'Eights', 'Nines', 'Tens', 'Jacks', 'Queens', 'Kings', 'Aces']
            const firstPair = cardName[cardsSetOne.length > cardsSetTwo.length ? cardsSetOne[0] : cardsSetTwo[0]];
            const lastPair = cardName[cardsSetOne.length > cardsSetTwo.length ? cardsSetTwo[0] : cardsSetOne[0]];



            handTypeText = `${handTypesText[handType]}, ${firstPair} full of ${lastPair}`
        } else if (<HandType>handType === HandType.FourOfaKind) {
            let cardString = '';
            cards.forEach(card => {
                cardString += cardDecoder(card.Suit, card.Number, true) + ' ';
            });

            handTypeText = `${handTypesText[handType]}, ${cardString}`

        } else if (<HandType>handType === HandType.StraightFlush) {
            const royalFlushCheck = [14, 13, 12, 11, 10]
            for (const card of cards) {
                const index = royalFlushCheck.indexOf(card.Number)
                if (index > -1) {
                    royalFlushCheck.splice(index, 1)
                }
            }

            handTypeText = royalFlushCheck.length === 0 ? 'Royal Flush' : `${handTypesText[handType]}, ${cardNameDecoder(cards[0].Number)} High`



        } else if (<HandType>handType === HandType.Badugi1Card) {
            let cardString = '';
            cards.forEach(card => {
                cardString += cardDecoder(card.Suit, card.Number, true) + ' ';
            });

            handTypeText = '1-Card' + ', ' + cardString;
        } else if (<HandType>handType === HandType.Badugi2Cards) {
            let cardString = '';
            cards.forEach(card => {
                cardString += cardDecoder(card.Suit, card.Number, true) + ' ';
            });

            handTypeText = '2-Cards' + ', ' + cardString;
        } else if (<HandType>handType === HandType.Badugi3Cards) {
            let cardString = '';
            cards.forEach(card => {
                cardString += cardDecoder(card.Suit, card.Number, true) + ' ';
            });

            handTypeText = '3-Cards' + ', ' + cardString;
        } else if (<HandType>handType === HandType.Badugi4Cards) {
            let cardString = '';
            cards.forEach(card => {
                cardString += cardDecoder(card.Suit, card.Number, true) + ' ';
            });

            handTypeText = 'Badugi' + ', ' + cardString;
        }



        // nadam se da je ovo ta varijanta. za tripe draw.
        // sada u table summary stize i Variant2. a Varient je 64. ko zna...
        if (this.getVariant() === 64) {
            handTypeText = 'Lo: ' + handTypeText;
        }

        /*
        this.dataManager.getHandStrength(this.tableSum.Variant, this.currentPlayer.getPlayerCardsInShortNotation(),
            this.getCommunityCardsInShortNotation(), this.tableSum.IsHiLo, this.getNumbOfPlayersAtTheTable());
        */

        if (onlyReturnValue) {
            return handTypeText

        } else {
            this.handTypeText = handTypeText
        }

    }

    playerHandTypeDoubleBoardGames(handType: number, cards: CardData[]) {
        this.handTypeDoubleBoardText = this.playerHandType(handType, cards, true);
    }

    playerHandTypeStrength(winChance: number) {
        this.chanceToWin = winChance;
    }

    playerSetBounty(idPlayer: number, bounty: number, isCelebrity: boolean) {

        const player = this.getPlayerById(idPlayer);


        if (player) {
            player.setBounty(
                CurrencyPipe.prototype.transform(
                    CurrencyDeviderPipe.prototype.transform(
                        bounty,
                        this.currency,
                    ),
                    this.currency
                ),
                isCelebrity
            );
        }
    }




    playerSetSpells(idPlayer: number, playerSpells: PlayerSpells) {

        const player = this.getPlayerById(idPlayer);
        console.log('CHECK+++ playerSpells', playerSpells)
        if (player) {
            if (playerSpells?.BountySpell?.SpellVariant !== undefined && playerSpells?.BountySpell?.Status !== undefined) {
                player.setBountySpell(playerSpells.BountySpell.SpellVariant, playerSpells.BountySpell.Status)
            }
            if (playerSpells?.EarnedSpells !== undefined) {
                player.addEarnedBounty(playerSpells.EarnedSpells)
            }

        }
    }




    getCommunityCardsInShortNotation(): string {
        return this.tableCardsController.getCommunityCardsInShortNotation();
    }

    getNumbOfPlayersAtTheTable(): number {
        let playerCount = 0;
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                playerCount++;
            }
        }
        return playerCount;
    }

    getPlayerNameById(IdPlayer: number): string {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === IdPlayer) {
                    return (<Player>this.seats[i]).nameString;
                }
            }
        }

        return 'error';
    }

    getPlayerById(IdPlayer: number): Player {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === IdPlayer) {
                    return (<Player>this.seats[i]);
                }
            }
        }

        return null;
    }

    getPlayerSeatNumberById(IdPlayer: number): number {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === IdPlayer) {
                    return i;
                }
            }
        }

        return -1;
    }

    setDealer(IdPlayer: number) {
        if (IdPlayer === undefined) { return; }
        if (false) { //environment.settings.useTableDealerChip
            const seatNumber = this.getPlayerSeatNumberById(IdPlayer);
            if (seatNumber === -1) { return; }
            this.dealerChip.position.set(this.dealerChipPositions[seatNumber].x, this.dealerChipPositions[seatNumber].y);
            this.dealerChip.visible = true;
            this.dealerChipId = IdPlayer;
        } else {
            for (let i = 0; i < this.seats.length; i++) {
                if (this.seats[i] instanceof Player) {
                    if ((<Player>this.seats[i]).id === IdPlayer) {
                        (<Player>this.seats[i]).dealer.visible = true;
                    }
                }
            }
        }
    }

    hideDealer() {
        if (false) { // environment.settings.useTableDealerChip
            this.dealerChip.visible = false;
        } else {
            for (let i = 0; i < this.seats.length; i++) {
                if (this.seats[i] instanceof Player) {
                    (<Player>this.seats[i]).dealer.visible = false;
                }
            }
        }
    }

    checkForRakeback(pot: Pot) {
        if (false) { return; } //showRakebackAnimation
        if (pot.rakeAmount > 0) {
            const covertedRake = this.isTournament ? pot.rakeAmount : CurrencyDeviderPipe.prototype.transform(pot.rakeAmount, this.currency);
            const covertedRakeText = this.isTournament ? `${covertedRake}` : CurrencyPipe.prototype.transform(covertedRake, this.currency);
            const rakeBackText: FloatingText = new FloatingText(covertedRakeText, pot.defaultPosition);
            this.container.addChild(rakeBackText.container);
        }
    }

    winnerByFold(IdPlayer: number, winMoney: number, balance: number, cards: any[]) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === IdPlayer) {
                    this.checkForRakeback(this.mainPot); // need to be called before moveToTarget for rakeAmount
                    this.mainPot.moveToTarget(this.potPositions[i]);
                    if (this.mainPot.amount === 0) {
                        this.checkForRakeback(this.mainPot2);
                        this.mainPot2.moveToTarget(this.potPositions[i]);
                    }
                    (<Player>this.seats[i]).updateBalance(balance);
                    this.checkForNavTablePlayerMoney(IdPlayer, balance);

                    if (IdPlayer === this.memberProfile.Id) {
                        this.playSound(this.assetsLoader.sound.HandWinning);
                    }
                }
            }
        }
    }

    winnerByStrongestHand(IdPlayer: number, winMoney: number, balance: number, cards: any[]) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === IdPlayer) {
                    this.checkForRakeback(this.mainPot); // need to be called before moveToTarget for rakeAmount
                    this.mainPot.moveToTarget(this.potPositions[i]);
                    if (this.mainPot.amount === 0) {
                        this.checkForRakeback(this.mainPot2);
                        this.mainPot2.moveToTarget(this.potPositions[i]);
                    }
                    (<Player>this.seats[i]).updateBalance(balance);
                    this.checkForNavTablePlayerMoney(IdPlayer, balance);

                    if (IdPlayer === this.memberProfile.Id) {
                        this.playSound(this.assetsLoader.sound.HandWinning);
                    }
                }
            }
        }
    }

    winnerSplit(IdPlayer: number, winMoney: number, balance: number, cards: any[]) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === IdPlayer) {
                    /*this.mainPot.moveToTarget(this.potPositions[i]);
                    if (this.mainPot.amount == 0) {
                        this.mainPot2.moveToTarget(this.potPositions[i]);
                    }*/
                    this.mainPot.updateAmount(0);
                    this.createSplitPot(winMoney, i);

                    (<Player>this.seats[i]).updateBalance(balance);
                    this.checkForNavTablePlayerMoney(IdPlayer, balance);

                    if (IdPlayer === this.memberProfile.Id) {
                        this.playSound(this.assetsLoader.sound.HandWinning);
                    }
                }
            }
        }
    }

    playerStatus(IdPlayer: number, status: number, balance: number, postedStraddle: boolean) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === IdPlayer) {
                    const oldStatus = (<Player>this.seats[i]).status;
                    (<Player>this.seats[i]).status = status;
                    // if it's me
                    if (IdPlayer === this.memberProfile.Id) {
                        this.managerService.clearGameLayoutForTable(this.id)



                        // Sitout / SitoutNextHand
                        this.betControls.showReplaceCardsButtons = false

                        if (status === PlayerStatus.Sitout) {
                            this.actionControls.showImBackButton = true;
                            this.actionControls.checkSeatOutNextHand = true;
                            this.preBetControls.showPreBet = false;
                            // disable all bitting buttons
                            this.betControls.showBetButtons = false;
                        } else if (status === PlayerStatus.SitoutNextHand) {
                            this.actionControls.showImBackButton = false;
                            this.actionControls.checkSeatOutNextHand = true;
                            this.preBetControls.showPreBet = false;
                        } else if (status === PlayerStatus.Ready) { // Ready
                            this.actionControls.showImBackButton = false;
                            this.actionControls.checkSeatOutNextHand = false;
                            // enable all betting buttons if it's my turn
                            if (this.playerTurnId === this.memberProfile.Id) {
                                this.betControls.showBetButtons = true;
                            }
                        } else if (status === PlayerStatus.LeaveSeat) { // LeaveSeat
                            // this.currentPlayer = undefined;
                            // this.betControls.showBetButtons = false;
                            // this.actionControls.showBuyRebuyChipsButton = false;
                            this.checkBuyChipsVisibility();
                            this.actionControls.showImBackButton = false;
                        }
                        this.setReplayButtonsVisibility();

                    }

                    (<Player>this.seats[i]).updateBalance(balance);
                    this.checkForNavTablePlayerMoney(IdPlayer, balance);

                    if (status === PlayerStatus.Sitout) {
                        (<Player>this.seats[i]).setStatus(PlayerGameStatus.sitout);
                    } else if (status === PlayerStatus.LeaveSeat) {
                        (<Player>this.seats[i]).setStatus(PlayerGameStatus.sitout);
                    } else if (status === PlayerStatus.LeftNextHand) {
                        (<Player>this.seats[i]).setStatus(PlayerGameStatus.sitout);
                    } else if (status === PlayerStatus.Ready) {
                        (<Player>this.seats[i]).setStatus(PlayerGameStatus.default);
                    } else if (status === PlayerStatus.Disconnected) {
                        (<Player>this.seats[i]).setStatus(PlayerGameStatus.disconnected);
                    }

                    if (postedStraddle) {
                        (<Player>this.seats[i]).straddle.visible = true;
                    }
                }
            }
        }
        this.checkStraddle()

    }

    playerR2T(IdPlayer: number, status: MemberPreferencesRunItTwice) {
        if (IdPlayer === this.memberProfile.Id) {
            this.actionControls.checkRunItTwice = status;
        }
    }

    potSplitted(pots: any[]) {
        pots.forEach((pot, index) => {
            if (index === 0) {
                this.mainPot.updateAmount(pot.Amount);
            } else if (index === 1) {
                this.mainPot2.updateAmount(pot.Amount);
            }
        });
    }



    processWinner(winners: Winner[], animationTime: number) {


        this.tableCardsController.selectCards([...winners[0].Cards, ...winners[0].Breakers]);

        let winnerCount = 0
        let message = ``

        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {

                for (const win of winners) {
                    if ((<Player>this.seats[i]).id === win.IdPlayer) {
                        winnerCount++

                        if (winnerCount > 1) {
                            message += `, `
                        }

                        message += `${(<Player>this.seats[i]).nameString}`;

                        (<Player>this.seats[i]).cardsController.selectCards([...win.Cards, ...win.Breakers]);
                    }
                }

                if (winners.findIndex(win => (<Player>this.seats[i]).id === win.IdPlayer) === -1) {
                    (<Player>this.seats[i]).cardsController.grayscaleCards();
                }
            }
        }


        const textInfo = (winnerCount === 1 && winners[0].Cards.length === 0) ? `${message} won the pot` : `${message} won with a `;
        const textHandStrength = (winnerCount === 1 && winners[0].Cards.length === 0) ? `` : `${this.playerHandType(winners[0].HandResult, winners[0].Cards, true)}`;

        this.animationText.setConfig(textInfo, textHandStrength, animationTime, this.handsetPortrait);
        this.container.setChildIndex(this.animationText.container, this.container.children.length - 1);
    }

    potResult(pot: PotData) {



        const winners = pot.Winners.map(data => {
            data.Breakers = data.Breakers || []
            return data
        })
        const loosers = pot.Loosers.map(data => {
            data.Breakers = data.Breakers || []
            return data
        })

        // let index = 0
        const animationTime = this.settings.TimeWaitWinnerOfPot ? (+this.settings.TimeWaitWinnerOfPot) * 1000 : 1500
        // timer(0, animationTime).pipe(take(winners.length)).subscribe(() => {
        //     this.processWinner(winners, animationTime)
        //     index++
        // })

        this.processWinner(winners, animationTime)


        const themes = this.config.themes
        if (themes && themes.length > 0) {
            const theme = themes.find(el => el.name === localStorage.getItem('theme'))
            if (theme) {
                this.playSound(this.assetsLoader.sound[theme.name + '-end-of-hand']);
            }
        }

        // for (const win of winners) {
        //     this.tableCardsController.selectCards(win.Cards);
        //     // select player cards
        //     for (let i = 0; i < this.seats.length; i++) {
        //         if (this.seats[i] instanceof Player) {
        //             if ((<Player>this.seats[i]).id === win.IdPlayer) {
        //                 (<Player>this.seats[i]).cardsController.selectCards(win.Cards);
        //             }
        //         }
        //     }

        //     // ToDo: Brekers is undefined sometime
        //     if (!win.Breakers) { win.Breakers = []; }
        //     this.tableCardsController.selectCards(win.Breakers);
        //     // select player cards
        //     for (let i = 0; i < this.seats.length; i++) {
        //         if (this.seats[i] instanceof Player) {
        //             if ((<Player>this.seats[i]).id === win.IdPlayer) {
        //                 (<Player>this.seats[i]).cardsController.selectCards(win.Breakers);
        //             }
        //         }
        //     }
        // }

        // for (let lose of loosers) {
        //     for (let i = 0; i < this.seats.length; i++) {
        //         if (this.seats[i] instanceof Player) {
        //             if ((<Player>this.seats[i]).id === lose.IdPlayer) {
        //                 (<Player>this.seats[i]).cardsController.grayscaleCards();
        //             }
        //         }
        //     }
        // }



        // losers should not raise cards
        /*
        for (let lose of loosers) {
            for (let card of lose.Cards) {
                //select table cards
                for (let tableCard of this.cards) {
                    if (tablecard.cardData.Suit == card.Suit && tablecard.cardData.Number == card.Number) {
                        tableCard.selectCard();
                    }
                }
                //select player cards
                for (let i = 0; i < this.seats.length; i++) {
                    if (this.seats[i] instanceof Player) {
                        if ((<Player>this.seats[i]).id == lose.IdPlayer) {
                            for (let playerCard of (<Player>this.seats[i]).cards) {
                                if (playercard.cardData.Suit == card.Suit && playercard.cardData.Number == card.Number) {
                                    playerCard.selectCard();
                                }
                            }
                        }
                    }
                }
            }
        }*/

    }

    communityCards(cards: any[], communityCardsR2T1: any[], communityCardsR2T2: any[]) {
        // remove old cards from table
        this.tableCardsController.removeAllCards();

        if (cards) {
            // add new cards to table
            const tableCardsData: CardData[] = [];
            cards.forEach(card => {
                const cardData: CardData = {
                    Suit: card.Suit,
                    Number: card.Number,
                    Name: cardDecoder(card.Suit, card.Number),
                    IsRabbitHunting: card.IsRabbitHunting,
                    IsPublic: card.IsPublic

                };
                tableCardsData.push(cardData);
            });
            this.tableCardsController.addCommunityCards(tableCardsData);
        }

        if (communityCardsR2T1) {
            // add new community cards T1 to table
            const tableCardsData: CardData[] = [];
            communityCardsR2T1.forEach(card => {
                const cardData: CardData = {
                    Suit: card.Suit,
                    Number: card.Number,
                    Name: cardDecoder(card.Suit, card.Number),
                    IsRabbitHunting: card.IsRabbitHunting,
                    IsPublic: card.IsPublic

                };
                tableCardsData.push(cardData);
            });
            this.tableCardsController.addR2tCardsT1(tableCardsData);
        }

        if (communityCardsR2T2) {
            // add new community cards T1 to table
            const tableCardsData: CardData[] = [];
            communityCardsR2T2.forEach(card => {
                const cardData: CardData = {
                    Suit: card.Suit,
                    Number: card.Number,
                    Name: cardDecoder(card.Suit, card.Number),
                    IsRabbitHunting: card.IsRabbitHunting,
                    IsPublic: card.IsPublic
                };
                tableCardsData.push(cardData);
            });
            this.tableCardsController.addR2tCardsT2(tableCardsData);
        }
    }

    dealerTipStatus(dealerLevel: number, nextLevelCost: number, dealerChangeCost: number, dealerImageUrl: string) {
        // if (environment.settings.useAnimatedDealer) {
        //     if (!this.animDealer) {
        //         this.addAnimDealer();
        //     }
        //     return;
        // }

        if (this.sexyDealer !== undefined) {
            this.sexyDealer.changeStatus(dealerLevel, nextLevelCost, dealerChangeCost, dealerImageUrl);
        } else {
            this.sexyDealer = new SexyDealer(this.CONST.SexyDealerPosition);

            this.container.addChild(this.sexyDealer.container);
            this.sexyDealer.changeStatus(dealerLevel, nextLevelCost, dealerChangeCost, dealerImageUrl);
        }

        this.hasSexyDealer = true;
        this.setReplayButtonsVisibility();
    }

    tipUpgradeResponse(IdPlayer: number, isTipSuccessful: boolean, tipCost: number, playerBalance: number) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === IdPlayer) {
                    (<Player>this.seats[i]).updateBalance(playerBalance);
                    this.checkForNavTablePlayerMoney(IdPlayer, playerBalance);
                }
            }
        }
    }

    variantChanged(newVariant = 0) {
        if (this.tableSum) {
            this.tableSum.Variant = newVariant;
        }
    }

    limitChanged(newLimit = 0) {
        if (this.tableSum) {
            this.tableSum.Limit = newLimit;
        }
    }

    playerChat(IdPlayer: number, playerMessage: string, emoticonId: number) {
        for (let i = 0; i < this.seats.length; i++) {
            if (this.seats[i] instanceof Player) {
                if ((<Player>this.seats[i]).id === IdPlayer) {
                    (<Player>this.seats[i]).chatMessage(playerMessage, emoticonId);
                }
            }
        }
    }

    playReplay(dt) {
        if (this.replayState === replayStates.init) {
            const playerList = [];
            const cards = [];
            let handNumber = 0;
            let howMuchToSkip = 0;

            for (const handEvent of this.replayEventData) {
                if (handEvent.MsgTypeLog === 'InfoPlayer') {
                    playerList.push(handEvent.PlayerData);
                    howMuchToSkip++;
                } else if (handEvent.MsgTypeLog === 'HandStart') {
                    handNumber = handEvent.Value;
                }
            }

            this.createSeatPositions();
            this.createPlayerPositions();
            this.createPotPositions();
            this.createDealerChipPositions();
            this.addSeatsAndPlayers(playerList, cards, handNumber, -1, []);

            this.replayEventIndex = howMuchToSkip + 1;
            this.replayState = replayStates.playing;
        } else if (this.replayState === replayStates.playing) {
            this.tableTimer += dt;
            if (this.tableTimer > this.replaySpeed) {
                this.tableTimer = 0;
                this.replayNextStep();
            }
        } else if (this.replayState === replayStates.stoped) {
        }
    }

    replayNextStep() {
        this.replayEventData[this.replayEventIndex].IdTable = this.id;


        const fakeServerMsg = { Response: 'ServerMsg', ServerMsg: [this.replayEventData[this.replayEventIndex]] };
        if (fakeServerMsg.ServerMsg[0].MsgType === ServerMessage.SendCardHidden ||
            fakeServerMsg.ServerMsg[0].MsgType === ServerMessage.HandStart ||
            fakeServerMsg.ServerMsg[0].MsgType === ServerMessage.Dealer ||
            fakeServerMsg.ServerMsg[0].MsgType === ServerMessage.BidSmallBlind ||
            fakeServerMsg.ServerMsg[0].MsgType === ServerMessage.BidBigBlind ||
            fakeServerMsg.ServerMsg[0].MsgType === ServerMessage.GameStatusChanged ||
            fakeServerMsg.ServerMsg[0].MsgType === ServerMessage.HandStart) {
            this.replaySpeed = 100;
        } else {
            this.replaySpeed = 250;
        }

        // check for messages to ignore
        switch (fakeServerMsg.ServerMsg[0].MsgType) {
            // case ServerMessage.SendCardHidden:
            case ServerMessage.PlayerBuyChips:
            case ServerMessage.AskPlayerRebuy:
            case ServerMessage.PlayerTakeSeat:
            case ServerMessage.PlayerStatus:
                break;

            default:

                this.gameService.parseWsMsg(fakeServerMsg);
                break;
        }

        // check for pause
        switch (fakeServerMsg.ServerMsg[0].MsgType) {
            case ServerMessage.SendAllCardsToPlayer:
                this.replayStop();
                break;

            default:
                // this.replaySpeed = 100;
                break;
        }

        // next message
        this.replayEventIndex++;

        // check for end
        if (this.replayEventIndex >= this.replayEventData.length) {
            this.replayState = replayStates.stoped;
            this.replayEventIndex = 0;
        }
    }

    ////// Replay commands///////////
    replayPlay() {
        this.replayState = replayStates.playing;
    }
    replayStop() {
        this.replayState = replayStates.stoped;
    }
    replayRestart() {
        this.clearPlayerList();

        // remove old cards from table
        this.tableCardsController.removeAllCards();

        // reset main pot
        this.mainPot.updateAmount(0);
        this.mainPot2.updateAmount(0);

        // hide dealer
        // this.dealer.visible = false;

        this.replayState = replayStates.init;
    }

    replayNext() {
        this.replayNextStep();
    }
    ////////////////////////////////







    // ============================================================================================
    // 📗 Functions
    // ============================================================================================

    setPositionsToNormalForPlayersPotsSeatsFoldedCards() {
        // 📚 this is part that is recreating new positions that got changed on rearrange position

        if (!this.isRearrangedPositionsForPlayersPotsSeatsFoldedCards) {
            // 📚 Helper function to avoid double rotations during ReEntry or merging tables
            return
        }

        this.createSeatPositions();
        this.createPlayerPositions();
        this.createPotPositions();
        this.createDealerChipPositions();
        // this.foldedCardsController.setMaxPlayersOnTable(this.getMaxPlayers());
        this.seats.forEach((seat, index) => {
            if (seat instanceof Player) {
                (<Player>seat).rearrangeElements(this.playerPositions[index], this.useVerticalMode, (<Player>seat).seatNumber);
            } else if (seat instanceof Seat) {
                (<Seat>seat).setPosition(this.seatPositions[index]);
            }
        });
        this.pots.forEach((pot, index) => {
            if (pot instanceof Pot) {
                pot.rearngeElements(this.potPositions[index], pot.seatNumber);
            }
        });
        this.foldedCardsController.setCardPositionsToNormal();
        this.setDealer(this.dealerChipId);
        this.isRearrangedPositionsForPlayersPotsSeatsFoldedCards = false
    }

    rearrangePositionsForPlayersPotsSeatsFoldedCards(forceRearrange?: boolean) {
        console.log("@@1", this.isRearrangedPositionsForPlayersPotsSeatsFoldedCards)
        if (this.isRearrangedPositionsForPlayersPotsSeatsFoldedCards) {
            // 📚 Helper function to avoid double rotations during ReEntry or merging tables
            return
        }
        this.isRearrangedPositionsForPlayersPotsSeatsFoldedCards = true;

        const indexDiff = this.rearrangePositions(); // 📚 its used only here
        console.log("@@2 indexDiff", indexDiff)

        if (indexDiff === 0 && !forceRearrange) { return; }
        this.seats.forEach((seat, index) => {
            if (seat instanceof Player) {
                let fakeSeatIndex = (<Player>seat).seatNumber + indexDiff;
                if (fakeSeatIndex > this.seats.length - 1) {
                    fakeSeatIndex = fakeSeatIndex - this.seats.length;
                }
                if (fakeSeatIndex < 0) {
                    fakeSeatIndex = this.seats.length + fakeSeatIndex;
                }
                (<Player>seat).rearrangeElements(this.playerPositions[index], this.useVerticalMode, fakeSeatIndex);
            } else if (seat instanceof Seat) {
                (<Seat>seat).setPosition(this.seatPositions[index]);

            }
        });
        this.pots.forEach((pot, index) => {
            if (pot instanceof Pot) {
                let fakeSeatIndex = pot.seatNumber + indexDiff;
                if (fakeSeatIndex > this.seats.length - 1) {
                    fakeSeatIndex = fakeSeatIndex - this.seats.length;
                }
                if (fakeSeatIndex < 0) {
                    fakeSeatIndex = this.seats.length + fakeSeatIndex;
                }
                pot.rearngeElements(this.potPositions[index], fakeSeatIndex);
            }
        });
        this.foldedCardsController.rearrangeCards();
        // 🟡 Remove => this.setDealer(this.dealerChipId); on v2 its on player
    }


    rearrangePositions(): number {
        // 📚 its used only from rearrangePositionsForPlayersPotsSeatsFoldedCards() function

        const targetIndex = this.getPlayerTargetIndexPosition();
        const currentIndex = this.getPlayerSeatNumberById(this.memberProfile.Id);

        console.log('@@ rearrangePositions', targetIndex, currentIndex)
        if (currentIndex === -1) { return 0; }
        if (currentIndex === targetIndex) { return 0; }

        let retDiff: number;
        if (currentIndex < targetIndex) {
            const diff = targetIndex - currentIndex;
            retDiff = diff;
            Common.shiftBackwards(diff, this.playerPositions);
            Common.shiftBackwards(diff, this.potPositions);
            Common.shiftBackwards(diff, this.seatPositions);
            Common.shiftBackwards(diff, this.dealerChipPositions);
            Common.shiftBackwards(diff, this.foldedCardsController.foldedCardsPositions);
        } else {
            const diff = currentIndex - targetIndex;
            retDiff = -diff;
            Common.shiftFoward(diff, this.playerPositions);
            Common.shiftFoward(diff, this.potPositions);
            Common.shiftFoward(diff, this.seatPositions);
            Common.shiftFoward(diff, this.dealerChipPositions);
            Common.shiftFoward(diff, this.foldedCardsController.foldedCardsPositions);
        }

        return retDiff;
    }
}
