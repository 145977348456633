import * as PIXI from 'pixi.js';
import { Card } from '../card';
import { CardData } from '../../../../shared/models';
import { Point } from '../../models';
import { AnchorPosition } from '../const';
import { environment } from '../../../../../environments/environment';
import * as CONST from './player-cards-controller-const';
import { VariantType, VariantType2 } from '../../../../shared/enums/poker-types';
import { GameComponent } from '../../game.component';
import { SortCards } from '../../../../shared/enums/sort-card';
import { cardSuit } from '../../../../shared/helpers/card-decoder';

export class PlayerCardsController {
    container: PIXI.Container;
    cards: Card[] = [];
    gameComponent: GameComponent

    showCards: boolean;

    bigCardsContainer: PIXI.Container;
    bigCardsBg: PIXI.Graphics;
    bigCards: Card[] = [];

    SHOWABLE_CARD_SCALE = 2;

    sortCards: SortCards = SortCards.DEFAULT
    addedCards: CardData[] = []
    addedCardsDisabledRotate: boolean = false
    constructor(
        private textures,
        private isLeftSided: boolean,
        private variantType: VariantType2,
        gameComponent: GameComponent,
        sortCards: SortCards
    ) {
        this.container = new PIXI.Container();
        this.gameComponent = gameComponent;
        this.sortCards = sortCards;
        if (false) {
            setTimeout(() => {
                this.addCards([
                    // { Suit: 1, Number: 14, Name: 'dA', IsHidden: true, IsRabbitHunting: undefined },
                    // { Suit: 1, Number: 10, Name: 'dT', IsHidden: true, IsRabbitHunting: undefined },
                    // { Suit: 2, Number: 7, Name: 'c7', IsHidden: true, IsRabbitHunting: undefined },
                    // { Suit: 0, Number: 9, Name: 'h9', IsHidden: true, IsRabbitHunting: undefined },

                    // { Suit: 0, Number: 9, Name: 'h9', IsHidden: true, IsRabbitHunting: undefined },
                    // { Suit: 0, Number: 9, Name: 'h9', IsHidden: true, IsRabbitHunting: undefined },
                    // { Suit: 0, Number: 9, Name: 'h9', IsHidden: true, IsRabbitHunting: undefined },

                    // { Suit: 1, Number: 14, Name: 'dA', IsHidden: false, IsRabbitHunting: true },
                    // { Suit: 1, Number: 14, Name: 'dA', IsHidden: false, IsRabbitHunting: true },
                    // { Suit: 1, Number: 10, Name: 'dT', IsHidden: false, IsRabbitHunting: true },
                    // { Suit: 2, Number: 7, Name: 'c7', IsHidden: false, IsRabbitHunting: true },
                    // { Suit: 0, Number: 9, Name: 'h9', IsHidden: false, IsRabbitHunting: true },
                    // { Suit: 1, Number: 14, Name: 'dA', IsHidden: false, IsRabbitHunting: true },
                    // { Suit: 1, Number: 10, Name: 'dT', IsHidden: false, IsRabbitHunting: true },
                ])

            }, 1000);


        }

        // BIG CARDS  + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +
        this.bigCardsContainer = new PIXI.Container();
        this.bigCardsContainer.visible = false
        this.container.addChild(this.bigCardsContainer)


        this.bigCardsBg = new PIXI.Graphics();
        this.bigCardsBg.beginFill(0x000000, 0.9);
        this.bigCardsBg.drawRoundedRect(
            -118, // x
            0 - 92, // y
            550 + 238, // Width
            300, // Height
            25 // Radius
        );
        this.bigCardsBg.endFill()
        this.bigCardsContainer.addChild(this.bigCardsBg)
        // + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + + +

    }



    setCardSelection(status: boolean) {
        this.cards.forEach(card => {
            card.setCardSelection(status)
        });


        this.bigCards.forEach(card => {
            card.setCardSelection(status)
        });


    }

    update(dt: number) {
        this.cards.forEach(card => {
            card.update(dt);
        });
    }

    setPosition(newPosition: Point, anchorPosition: AnchorPosition, isHidden: boolean) {

        this.container.position.set(newPosition.x, newPosition.y);
    }


    addCards(cardsData: CardData[], disableRotate?: boolean) {
        this.addedCards = [...cardsData]
        this.addedCardsDisabledRotate = disableRotate

        const totalNumberOfCards = cardsData.length
        const cardsPositions: Point[] = this.calculateCardPositions(cardsData.length);
        this.toSortCards(cardsData, this.sortCards)
            //.sort(card => card.IsHidden ? -1 : 1).sort(card => card.IsPublic ? 1 : -1)
            .forEach((cardData, index) => {

                let card = new Card(this.textures['playerCardAtlas'], this.textures['backCard'].texture, this.textures['cardRabbitHunting'].texture, this.gameComponent, cardData);
                if (cardData.IsHidden) {
                    console.log("@card, index, disableRotate, totalNumberOfCards", card, index, disableRotate, totalNumberOfCards)
                    card = this.setHiddenCard(card, index, disableRotate, totalNumberOfCards)
                    if (!disableRotate) {
                        card.container.scale.set(1.4);

                    } else {
                        card.container.scale.set(this.SHOWABLE_CARD_SCALE);

                    }
                    this.container.addChild(card.container);
                    this.cards.push(card);
                    if (!disableRotate) {
                        this.container.setChildIndex(card.container, 0);

                    } else {
                        this.container.setChildIndex(card.container, this.container.children.length - 1);

                    }
                } else {
                    card.container.scale.set(this.SHOWABLE_CARD_SCALE);

                    card.setPosition(cardsPositions[index], AnchorPosition.topLeft);
                    if (!card.cardData.IsPublic && disableRotate) { // disable rotate only 7 Stud
                        card.container.position.y += 30
                    }

                    this.container.addChild(card.container);
                    this.cards.push(card);
                    this.showCards = true


                    //    + + + + + + +
                    let x = index * 86 * 2.2 + 5 * index - 110;

                    let bigCard = new Card(this.textures['playerCardAtlas'], this.textures['backCard'].texture, this.textures['cardRabbitHunting'].texture, this.gameComponent, cardData, null, true);
                    bigCard.setPosition({ x, y: 15 - 92 }, 0);
                    bigCard.container.scale.set(2.2)
                    this.bigCardsContainer.addChild(bigCard.container);
                    this.bigCards.push(bigCard);
                }

            });
    }

    showBigCards() {
        for (const card of this.cards) {
            card.container.visible = false
        }
        this.bigCardsContainer.visible = true
        this.container.setChildIndex(this.bigCardsContainer, this.container.children.length - 1);

    }

    hideBigCards() {
        if (this.variantType === VariantType2.TexasHoldEm || this.variantType === VariantType2.TexasHoldEmRock || this.variantType === VariantType2.CapTexasHoldEm) {
            return
        }

        for (const card of this.cards) {
            card.container.visible = true
            card.onSelectCard(true, false)

        }
        for (const card of this.bigCards.filter(el => el.selected)) {
            const index = this.cards.findIndex(el => el.cardData === card.cardData)

            if (this.cards[index]) {
                this.cards[index].onSelectCard(true, true)
            }
        }

        this.bigCardsContainer.visible = false
        this.container.setChildIndex(this.bigCardsContainer, 0);
    }

    addCard(cardData: CardData, totalNumberOfCards) {
        console.log("@add carD")

        const cardsPositions: Point[] = this.calculateCardPositions(totalNumberOfCards);
        const card = new Card(this.textures['playerCardAtlas'], this.textures['backCard'].texture, this.textures['cardRabbitHunting'].texture, this.gameComponent, cardData);
        card.setPosition(cardsPositions[this.cards.length], AnchorPosition.topLeft);
        // this.adjustCard(card);
        this.container.addChild(card.container);
        this.cards.push(card);
    }

    addHiddenCards(totalNumberOfCards: number, disableRotate?: boolean) {
        for (let i = 0; i < totalNumberOfCards; i++) {
            let card = new Card(
                this.textures['playerCardAtlas'],
                this.textures['backCard'].texture,
                this.textures['cardRabbitHunting'].texture,
                this.gameComponent,
                {
                    Suit: null,
                    Number: null,
                    Name: null,

                    IsHidden: true,
                    IsRabbitHunting: null,
                    IsPublic: false
                });


            card = this.setHiddenCard(card, i, disableRotate, totalNumberOfCards)

            card.container.scale.set(1.4);

            this.container.addChild(card.container);
            this.cards.push(card);

            if (!disableRotate) {
                this.container.setChildIndex(card.container, 0);
            } else {
                this.container.setChildIndex(card.container, this.container.children.length - 1);
            }

        }
    }

    removeAllCards() {

        this.container.removeChildren();
        this.cards = [];

        // ++++
        this.bigCardsContainer.removeChildren();
        this.bigCardsContainer.visible = false;
        this.bigCards = [];

        this.container.addChild(this.bigCardsContainer)
        this.bigCardsContainer.addChild(this.bigCardsBg)

        //                this.setCardSelection(true)

        this.addedCards = [];
        this.addedCardsDisabledRotate = false;
    }

    removeCards(cards: Card[]) {
        cards.forEach(cardToRemove => {
            for (let index = this.cards.length; index >= 0; index--) {
                const currentCard = this.cards[index];
                if (cardToRemove.cardData.Number === currentCard.cardData.Number &&
                    cardToRemove.cardData.Suit === currentCard.cardData.Suit) {
                    this.container.removeChild(currentCard.container);
                    this.cards.splice(index, 1);
                    break;
                }
            }
        });
        this.addedCards = [];
        this.addedCardsDisabledRotate = false;
    }
    grayscaleCards() {
        this.cards
            .forEach(unselectCard => {
                unselectCard.grayscaleCard()
            })
    }

    selectCards(cardsToSelect: any = []) {


        if (cardsToSelect.length === 0) { return }



        this.cards.forEach(card => {
            if (!card || !card.cardData) { return; }
            if (cardsToSelect.findIndex(selectCard => selectCard.Suit === card.cardData.Suit && selectCard.Number === card.cardData.Number) === -1) {
                card.grayscaleCard();
            } else {
                card.ungrayscaleCard();

            }
        });


        // this.cards.filter(card => cardsToSelect.findIndex(selectCard => selectCard.Suit === card.cardData.Suit && selectCard.Number === card.cardData.Number) === -1)
        //     .forEach(unselectCard => {
        //         unselectCard.grayscaleCard()
        //     })


        // ## OLD BLINKING CARDS

        // cardsToSelect.forEach(selectCard => {
        //     if (!selectCard) { return; }
        //     this.cards.forEach(card => {
        //         if (!card || !card.cardData) { return; }
        //         if (card.cardData.Suit === selectCard.Suit && card.cardData.Number === selectCard.Number) {
        //             card.selectCard();
        //         }
        //     });
        // });
    }

    private calculateCardPositions(numberOfCards: number): Point[] {

        /*  
            🔴 Red zone player igraca je 610px, sirina karte je 86px
            
            [------------|------------]
            0          610/2         610

            🔵 Razmak izmedju karata je na 50% od pocetka prethodne karte, kada je 7 karata onda su vise sabijene i onda je na 58%

            [////]
            50%[////]
                50%[/////]

            * Ukupna sirina karata je sirina karte X broj karata - offset koliko su karte povucene vise na levo
            * Formula je 610px total sirina / 2 dobije se centar - ukupna sirina karata / 2 i onda su karte na centru

        */

        let OFFSET = 0.5
        if (numberOfCards === 7) {
            OFFSET = 0.58
        }

        const start = 610 / 2 - ((CONST.cardWidth * this.SHOWABLE_CARD_SCALE * numberOfCards) / 2 - (CONST.cardWidth * this.SHOWABLE_CARD_SCALE * (numberOfCards - 1) * OFFSET) / 2)

        const cardPositionOffset = CONST.cardWidth * this.SHOWABLE_CARD_SCALE - CONST.cardWidth * this.SHOWABLE_CARD_SCALE * OFFSET;

        const cardsPositions: Point[] = [];

        for (let i = 0; i < numberOfCards; i++) {
            const position: Point = { x: start + cardPositionOffset * i, y: 70 };
            cardsPositions.push(position);
        }
        return cardsPositions;
    }


    getCardsInShortNotation(): string {
        let shortNotation = '';
        this.cards.forEach(card => {
            shortNotation += card.cardData.Name + ' ';
        });
        return shortNotation.trim();
    }

    refreshCardsTexture() {
        this.cards.forEach(card => {
            card.refreshCard(this.textures['playerCardAtlas'], this.textures['backCard'].texture);
        });
    }

    setSortCards(sortCards: SortCards) {
        this.sortCards = sortCards;


        this.container.removeChildren();
        this.cards = [];

        // ++++
        this.bigCardsContainer.removeChildren();
        this.bigCardsContainer.visible = false;
        this.bigCards = [];

        this.container.addChild(this.bigCardsContainer)
        this.bigCardsContainer.addChild(this.bigCardsBg)

        this.addCards(this.addedCards, this.addedCardsDisabledRotate)
    }

    changeSide(isLeftSided: boolean) {
        this.isLeftSided = isLeftSided;
    }

    setHiddenCard(card: Card, index: number, disableRotate?: boolean, totalNumberOfCards?: number) {
        if (disableRotate) {
            const cardsPositions: Point[] = this.calculateCardPositions(totalNumberOfCards);
            card.container.position.set(cardsPositions[index].x, cardsPositions[index].y + 30)

            return card
        }


        if (this.isLeftSided) {
            const setX = 75
            const setY = -120

            switch (index) {
                case 0:
                    card.container.position.set(-30 + setX, 20 - setY);
                    card.container.rotation = -0.275 - (index * 0.275)

                    break;
                case 1:
                    card.container.position.set(-62 + 5 + setX, 50 + 20 - setY);
                    card.container.rotation = -0.275 - (index * 0.275)

                    break;

                case 2:
                    card.container.position.set(-85 + 10 + setX, 82 + 40 - setY);
                    card.container.rotation = -0.275 - (index * 0.275)

                    break;

                case 3:
                    card.container.position.set(-98 + 20 + setX, 122 + 60 - setY);
                    card.container.rotation = -0.275 - (index * 0.275)
                    break;

                case 4:
                    card.container.position.set(-105 + 40 + setX, 157 + 80 - setY);
                    card.container.rotation = -0.275 - (index * 0.275)
                    break;

                case 5:
                    card.container.position.set(-110 + 65 + setX, 192 + 95 - setY);
                    card.container.rotation = -0.275 - (index * 0.275)
                    break;

                case 6:
                    card.container.position.set(-105 + 95 + setX, 229 + 100 - setY);
                    card.container.rotation = -0.275 - (index * 0.275)
                    break;
            }
        } else {
            const setX = 420
            const setY = -85

            switch (index) {
                case 0:
                    card.container.position.set(30 + setX, 20 - setY);
                    card.container.rotation = 0.275 + (index * 0.275)

                    break;
                case 1:
                    card.container.position.set(62 + 5 + setX, 50 + 20 - setY - 30);
                    card.container.rotation = 0.275 + (index * 0.275)

                    break;

                case 2:
                    card.container.position.set(85 + 10 + setX + 15, 82 + 40 - setY - 60);
                    card.container.rotation = 0.275 + (index * 0.275)

                    break;

                case 3:
                    card.container.position.set(98 + 20 + setX + 20, 122 + 60 - setY - 80);
                    card.container.rotation = 0.275 + (index * 0.275)
                    break;

                case 4:
                    card.container.position.set(105 + 40 + setX + 15, 157 + 80 - setY - 90);
                    card.container.rotation = 0.275 + (index * 0.275)
                    break;

                case 5:
                    card.container.position.set(110 + 65 + setX, 192 + 95 - setY - 90);
                    card.container.rotation = 0.275 + (index * 0.275)
                    break;

                case 6:
                    card.container.position.set(105 + 95 + setX - 30, 229 + 100 - setY - 80);
                    card.container.rotation = 0.275 + (index * 0.275)
                    break;
            }

        }
        card.container.scale.set(1.4);

        return card
    }




    toSortCards(cards: CardData[], cardSorting: SortCards) {
        console.log("toSortCards", cardSorting, cards)
        const suitOrder: { [key: string]: number } = {
            "Hearts": 0,
            "Diamods": 1,
            "Clubs": 2,
            "Spades": 3
        };

        switch (cardSorting) {
            case SortCards.DEFAULT:
                return cards;
            case SortCards.SUIT:
                console.log('toSortCards', '----', 'SUIT', cards.sort((a, b) => {
                    console.log('toSortCards', '----', 'SUIT', suitOrder[cardSuit[b.Suit]], suitOrder[cardSuit[a.Suit]])
                    // First, compare suit order (descending)
                    const suitComparison = suitOrder[cardSuit[b.Suit]] - suitOrder[cardSuit[a.Suit]];
                    if (suitComparison !== 0) {
                        return suitComparison;
                    }

                    // If suits are the same, sort by number (descending)
                    return b.Number - a.Number;
                }));

                return cards.sort((a, b) => {
                    // First, compare suit order (descending)
                    const suitComparison = suitOrder[cardSuit[b.Suit]] - suitOrder[cardSuit[a.Suit]];
                    if (suitComparison !== 0) {
                        return suitComparison;
                    }

                    // If suits are the same, sort by number (descending)
                    return b.Number - a.Number;
                });

            case SortCards.UP:
                return cards.sort((a, b) => a.Number - b.Number);
            case SortCards.DOWN:
                return cards.sort((a, b) => b.Number - a.Number);
            default:
                return cards;
        }
    }
}
