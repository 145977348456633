import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import * as PIXI from 'pixi.js';
import { Howl } from 'howler';

import { environment } from '../../../environments/environment';
import { Sound } from '../../shared/models/sounds';
import { NoteColors } from '../../shared/enums/note-color';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { LinkHtmlService } from './link-html.service';
import { ASSETS } from '../../../assets/assets';

import { AnimatedGIFLoader } from '@pixi/gif';
import { UserAppConfig } from '../../main/game/models/user-app-config';
import { ConfigService } from './config.service';
PIXI.Loader.registerPlugin(AnimatedGIFLoader);

@Injectable()
export class AssetsLoaderService {

  assets = ASSETS
  pixiLoader$ = new Subject<any>();
  pixiLoader = new PIXI.Loader()
  sound: Sound = {} as Sound;
  textures: { [index: string]: any; } = {};

  skinSettings: any = {};

  reloadObservable$ = new ReplaySubject<string>(1);

  readonly SKIN_SETTINGS = 'top-skin-settings';

  private emoticons: any[] = [

  ]

  config;

  constructor(
    private _link: LinkHtmlService,
    private _iconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer,
    private configService: ConfigService,

  ) {
    this.config = this.configService.config;

    this.skinSettings = {
      playerCardAtlas: this.configService.config.skinSettings.playerCards[0].cardJsonUrl,
      tableCardAtlas: this.configService.config.skinSettings.tableCards[0].cardJsonUrl,


      backCard: `${environment.dataSource}/assets/${this.configService.config.code}/cards/cardBack.png`,
      carpet: `${environment.dataSource}/assets/${this.configService.config.code}/carpets/background.jpg`,
      carpetVertical: `${environment.dataSource}/assets/${this.configService.config.code}/carpets/vertical_background.jpg`,
      table: `${environment.dataSource}/assets/${this.configService.config.code}/tables/table.png`,
      tableVertical: `${environment.dataSource}/assets/${this.configService.config.code}/tables/vertical_table.png`,
    };



    this.assets.icons = this.assets.icons.map(el => {
      el.path = el.path.replace('[$CODE]', `${environment.dataSource}/assets/${this.configService.config.code}`)
      return el
    })

    this.assets.pixi = this.assets.pixi.map(el => {
      el.path = el.path.replace('[$CODE]', `${environment.dataSource}/assets/${this.configService.config.code}`)

      return el
    })

    this.assets.tagLinks = this.assets.tagLinks.map(el => {
      if (el.href) {
        el.href = el.href.replace('[$CODE]', `${environment.dataSource}/assets/${this.configService.config.code}`)
      }
      if (el.content) {
        el.content = el.content.replace('[$TITLE]', this.configService.config.title)

      }
      return el
    })

    console.log("configService AssetsLoaderService ", configService.config)

    this.skinSettings = { ...this.skinSettings, ...this.getSkinSettings() }

    // called once per loaded/errored file
    this.pixiLoader.onProgress.add((data) => {
      this.pixiLoader$.next(data);
    });
    // called once per errored file
    this.pixiLoader.onError.add((data) => {
      this.pixiLoader$.next(data);
    });
    // called once when the queued resources all load.
    this.pixiLoader.onComplete.add((data) => {
      this.pixiLoader$.next(data);
    });
  }

  setEmoticons(data: any[]) {
    this.emoticons = data
  }
  addEmoticons() {
    for (const item of this.emoticons) {
      if (item.Type === 'gif') {
        this.pixiLoader.add(`${item.Id}-emoticon`, item.ImageUrl, { crossOrigin: '*' });
      }

      this.sound[`${item.Id}-emoticon`] = new Howl({ src: [item.SoundUrl] });
    }

  }

  load() {
    //this.addEmoticons();
    this.addTagLinks();
    this.addIcons();
    this.addPixi()

    // start loading pixi assets
    this.pixiLoader.load((loader, resources) => {
      this.textures = resources;
      loader.reset();
    });



    this.initSounds();
  }


  addIcons() {
    for (const icon of this.assets.icons) {
      this._iconRegistry.addSvgIcon(
        icon.name,
        this._sanitizer.bypassSecurityTrustResourceUrl(icon.path)
      );
    }
  }

  addTagLinks() {
    for (const tagLink of this.assets.tagLinks) {
      this._link.addTag(tagLink);
    }
  }

  addPixi() {
    for (const asset of this.assets.pixi) {
      if (!this.skinSettings[asset.name]) {

        this.pixiLoader.add(asset.name, asset.path, { crossOrigin: '*' })//.load(()=>{console.log(asset.name, asset.path)});
      }
    }

    for (const key in Object.keys(NoteColors).slice(Object.keys(NoteColors).length / 2)) {

      this.pixiLoader.add(`playerNoteIcon${NoteColors[key]}`, `assets/common/player/note-colors/${key}.png`, { crossOrigin: '*' });
    }

    for (const resourceName in this.skinSettings) {
      if (this.skinSettings.hasOwnProperty(resourceName) && resourceName !== 'carpet') {

        this.pixiLoader.add(resourceName, this.skinSettings[resourceName], { crossOrigin: '*' });
      }
    }


  }

  private initSounds() {
    if (this.config.code === 'neonpoker-qijy') {
      this.sound.AllIn = new Howl({ src: ["assets/sounds/neon/" + 'allin.wav'] });
      this.sound.Check = new Howl({ src: ["assets/sounds/neon/" + 'check.wav'] });
      this.sound.Chip = new Howl({ src: ["assets/sounds/neon/" + 'chip.wav'] });
      this.sound.Fold = new Howl({ src: ["assets/sounds/neon/" + 'fold.wav'] });
      this.sound.Rise = new Howl({ src: ["assets/sounds/neon/" + 'raise.wav'] });
      this.sound.WaitingOver = new Howl({ src: ["assets/sounds/neon/" + 'waitingover.mp3'] });
      this.sound.TimeBank = new Howl({ src: ["assets/sounds/neon/" + 'timeBank.wav'] });
      this.sound.TimeBankTimeRunning = new Howl({ src: ["assets/sounds/neon/" + 'TimeRunning.wav'] });
      this.sound.YourTurn = new Howl({ src: ["assets/sounds/neon/" + 'yourturn.wav'] });
      this.sound.HandWinning = new Howl({ src: ["assets/sounds/neon/" + 'hand-winning.wav'] });
      this.sound.Lobby = new Howl({ src: ["assets/sounds/neon/" + 'lobby.mp3'] });

    } else if (this.config.code === 'phoenix-wnyz') { //bet 
      this.sound.AllIn = new Howl({ src: ["assets/sounds/phoenix/" + 'allin.mp3'] });
      this.sound.Check = new Howl({ src: ["assets/sounds/phoenix/" + 'check.mp3'] });
      this.sound.Chip = new Howl({ src: ["assets/sounds/0/" + 'chip.wav'] });
      this.sound.Fold = new Howl({ src: ["assets/sounds/phoenix/" + 'fold.mp3'] });
      this.sound.Rise = new Howl({ src: ["assets/sounds/phoenix/" + 'raise.mp3'] });
      this.sound.WaitingOver = new Howl({ src: ["assets/sounds/0/" + 'waitingover.mp3'] });
      this.sound.TimeBank = new Howl({ src: ["assets/sounds/0/" + 'timeBank.mp3'] });
      this.sound.TimeBankTimeRunning = new Howl({ src: ["assets/sounds/0/" + 'TimeRunning.wav'] });
      this.sound.YourTurn = new Howl({ src: ["assets/sounds/0/" + 'yourturn.mp3'] });
      this.sound.HandWinning = null;

     
      this.sound.Call = new Howl({ src: ["assets/sounds/phoenix/" + 'call.wav'] });
      this.sound.Bet = new Howl({ src: ["assets/sounds/phoenix/" + 'bet.mp3'] });

    } else {
      this.sound.AllIn = new Howl({ src: ["assets/sounds/0/" + 'allin.mp3'] });
      this.sound.Check = new Howl({ src: ["assets/sounds/0/" + 'check.mp3'] });
      this.sound.Chip = new Howl({ src: ["assets/sounds/0/" + 'chip.wav'] });
      this.sound.Fold = new Howl({ src: ["assets/sounds/0/" + 'fold.mp3'] });
      this.sound.Rise = new Howl({ src: ["assets/sounds/0/" + 'raise.mp3'] });
      this.sound.WaitingOver = new Howl({ src: ["assets/sounds/0/" + 'waitingover.mp3'] });
      this.sound.TimeBank = new Howl({ src: ["assets/sounds/0/" + 'timeBank.mp3'] });
      this.sound.TimeBankTimeRunning = new Howl({ src: ["assets/sounds/0/" + 'TimeRunning.wav'] });
      this.sound.YourTurn = new Howl({ src: ["assets/sounds/0/" + 'yourturn.mp3'] });
      this.sound.HandWinning = null;

      this.sound.Call = new Howl({ src: ["assets/sounds/0/" + 'call.wav'] });
      this.sound.Bet = new Howl({ src: ["assets/sounds/0/" + 'raise.mp3'] });
    }

    if (this.config.themes && this.config.themes.length > 0) {
      for (const theme of this.config.themes) {

        if (theme.sound.lobby) {
          this.sound[theme.name + '-lobby'] = new Howl({ src: [`assets/theme/${theme.name}/sound/lobby.mp3`] });
        }

        if (theme.sound.endOfHand) {
          this.sound[theme.name + '-end-of-hand'] = new Howl({ src: [`assets/theme/${theme.name}/sound/end-of-hand.mp3`] });
        }

        if (theme.sound.allIn) {
          this.sound[theme.name + '-all-in'] = new Howl({ src: [`assets/theme/${theme.name}/sound/all-in.mp3`] });
        }

        if (theme.sound.betRaise) {
          this.sound[theme.name + '-bet-raise'] = new Howl({ src: [`assets/theme/${theme.name}/sound/bet-raise.mp3`] });
        }

        if (theme.sound.bonusUnlock) { // ##
          this.sound[theme.name + '-bonus-unlock'] = new Howl({ src: [`assets/theme/${theme.name}/sound/bonus-unlock.mp3`] });
        }

        if (theme.sound.call) {
          this.sound[theme.name + '-call'] = new Howl({ src: [`assets/theme/${theme.name}/sound/call.mp3`] });
        }

        if (theme.sound.click) {
          this.sound[theme.name + '-click'] = new Howl({ src: [`assets/theme/${theme.name}/sound/click.mp3`] });
        }

        if (theme.sound.fold) {
          this.sound[theme.name + '-fold'] = new Howl({ src: [`assets/theme/${theme.name}/sound/fold.mp3`] });
        }

        if (theme.sound.tableOpen) {
          this.sound[theme.name + '-table-open'] = new Howl({ src: [`assets/theme/${theme.name}/sound/table-open.mp3`] });
        }

        if (theme.sound.toggle) {
          this.sound[theme.name + '-toggle'] = new Howl({ src: [`assets/theme/${theme.name}/sound/toggle.mp3`] });
        }

      }
    }

    const localStorageUserAppConfig: UserAppConfig = localStorage.getItem('userAppConfig') ? JSON.parse(localStorage.getItem('userAppConfig')) : {}

    Howler.volume(localStorageUserAppConfig?.muteSound ? 0 : 1);
  }

  // ## ------------------------------------
  // ## REFRESH THEME SETTINGS
  // ## ------------------------------------

  private refreshTextures(resources) {
    for (const resourceName in resources) {
      if (resources.hasOwnProperty(resourceName) && resourceName !== 'carpet') {
        if (this.textures.hasOwnProperty(resourceName)) {
          this.textures[resourceName] = resources[resourceName];
        }
      }
    }
  }

  reload(changes: { [index: string]: string; }) {
    PIXI.utils.clearTextureCache()
    this.pixiLoader.reset();

    for (const resourceName in changes) {
      if (changes.hasOwnProperty(resourceName) && resourceName !== 'carpet' && resourceName !== 'carpetVertical') {
        console.log("resourceName", resourceName, changes[resourceName])
        this.pixiLoader.add(resourceName, changes[resourceName], { crossOrigin: true });
      }
    }

    this.pixiLoader.load((loader, resources) => {

      this.refreshTextures(resources);
      loader.reset();
      this.reloadObservable$.next();
    });
  }

  saveSkinSettings(skinSettings: any) {
    localStorage.setItem(this.SKIN_SETTINGS, JSON.stringify({ ...this.getSkinSettings(), ...skinSettings }));
  }

  saveTheme(theme: string) {
    localStorage.setItem('theme', theme);


  }

  removeTheme() {
    localStorage.setItem('theme', '');

    const skinSettings = {
      playerCardAtlas: this.config.skinSettings.playerCards[0].cardJsonUrl,
      tableCardAtlas: this.config.skinSettings.tableCards[0].cardJsonUrl,
      backCard: this.config.skinSettings.backCards[0].cardUrl,
      carpet: this.config.skinSettings.carpets.landscape[0].carpetUrl,
      carpetVertical: this.config.skinSettings.carpets.portrait[0].carpetUrl,
      table: this.config.skinSettings.tables.landscape[0].tableUrl,
      tableVertical: this.config.skinSettings.tables.portrait[0].tableUrl
    };

    this.skinSettings = { ...skinSettings, ...this.getSkinSettings() }

    for (const asset of this.assets.pixi) {
      if (!this.skinSettings[asset.name]) {
        this.skinSettings[asset.name] = asset.path

      }
    }

    this.reload(this.skinSettings)

    return this.skinSettings
  }


  getSkinSettings() {

    let skinTheme = localStorage.getItem('theme')
    if (skinTheme && this.config.themes && this.config.themes.length > 0) {
      return this.getTheme(skinTheme)
    }

    const skinSettingsLocalStorage = localStorage.getItem(this.SKIN_SETTINGS)
    if (skinSettingsLocalStorage && skinSettingsLocalStorage.includes(this.config.brand)) {
      return JSON.parse(skinSettingsLocalStorage)
    } else {
      return {}
    }

  }

  getTheme(themeName: string) {
    const applyData: { [index: string]: string; } = {};

    applyData['backCard'] = `assets/theme/${themeName}/cards/cardBack.png`;

    applyData['dealer'] = `assets/theme/${themeName}/tables/dealer.png`;

    if (this.config.themes && this.config.themes.length > 0) {
      for (const theme of this.config.themes) {
        if (theme.name === themeName && theme.tableLogo) {
          applyData['tableLogo'] = `assets/theme/${themeName}/tables/table_logo.png`;
        }
      }
    }

    if (themeName === 'avax') {
      applyData['tableLogo'] = `assets/theme/${themeName}/tables/table_logo.png`;
    }

    console.log("@get Theme", themeName)

    applyData['table'] = `assets/theme/${themeName}/tables/table.png`;
    applyData['tableVertical'] = `assets/theme/${themeName}/tables/vertical_table.png`;

    applyData['carpet'] = `assets/theme/${themeName}/carpets/background.jpg`;
    applyData['carpetVertical'] = `assets/theme/${themeName}/carpets/vertical_background.jpg`;

    applyData['avatarActive'] = `assets/theme/${themeName}/player/avatar_active.png`;
    applyData['avatarDefault'] = `assets/theme/${themeName}/player/avatar_default.png`;
    applyData['avatarDisconnected'] = `assets/theme/${themeName}/player/avatar_disconnected.png`;
    applyData['avatarFold'] = `assets/theme/${themeName}/player/avatar_fold.png`;
    applyData['avatarSitout'] = `assets/theme/${themeName}/player/avatar_sitout.png`;

    applyData['playerActive'] = `assets/theme/${themeName}/player/player_active.png`;
    applyData['playerDefault'] = `assets/theme/${themeName}/player/player_default.png`;
    applyData['playerDisconnected'] = `assets/theme/${themeName}/player/player_disconnected.png`;
    applyData['playerFold'] = `assets/theme/${themeName}/player/player_fold.png`;
    applyData['playerSitout'] = `assets/theme/${themeName}/player/player_sitout.png`;

    applyData['seatDefault'] = `assets/theme/${themeName}/seat/take_a_seat_default.png`;
    applyData['seatClick'] = `assets/theme/${themeName}/seat/take_a_seat_click.png`;

    applyData['barBackground'] = `assets/theme/${themeName}/timer/barBackground.png`;
    applyData['barCap'] = `assets/theme/${themeName}/timer/barCap.png`;
    applyData['barMiddle'] = `assets/theme/${themeName}/timer/barMiddle.png`;
    applyData['barGlow'] = `assets/theme/${themeName}/timer/barGlow.png`;


    return applyData
  }



}