import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TimeParser } from '../helpers/time-parser';

export interface AddOn {
  countdown: number;
}

export enum AddOnAnswer {
  CANCEL = -1,
  ADD_ON = 1,
}

@Component({
  selector: 'app-game-addon',
  templateUrl: './game-addon.component.html',
  styleUrls: ['./game-addon.component.scss']
})
export class GameAddonComponent implements OnInit {

  @Output() addOnAnswer = new EventEmitter();

  private interval;
  public visible;
  public addOn: AddOn;
  public timeText: string;

  constructor() { }

  ngOnInit() { }

  clickCancel() {
    this.visible = false;
    this.addOnAnswer.emit(AddOnAnswer.CANCEL);
  }

  clickAddOn() {
    this.visible = false;
    this.addOnAnswer.emit(AddOnAnswer.ADD_ON);
  }

  public show(addOn: AddOn) {
    this.addOn = addOn;
    this.visible = true;
    this.timeText = TimeParser.parseTime(this.addOn.countdown);
    this.startInterval();
  }

  private startInterval() {
    this.interval = setInterval(() => {
      --this.addOn.countdown;
      // If the count down is finished, write some text
      if (this.addOn.countdown < 0) {
        clearInterval(this.interval);
        this.addOnAnswer.emit(AddOnAnswer.CANCEL);
        this.visible = false;
        return;
      }
      this.timeText = TimeParser.parseTime(this.addOn.countdown);
    }, 1000);
  }

}

