export enum AccountType {
    CashOnTable = 0x2, // total amount on table / currently getting play
    CashOnHold = 0x4, // total of amount that is locked by an administrator or pending from a deposit
    CashIncome = 0x8, // amount received by affiliate that will be unlock periodially per week
    CashToken = 0x16, // Satellite token received, money is locked and will be used when the token will be used.
    PointsToUnlock = 0x200, // real counter of points,
}

export enum GameLayoutTypes {
    Unknown = 0,
    Desktop = 1,
    Landscape = 2,
    Portrait = 3
}

export enum ErrorCode {
    NoError = 0,
    // Success, // account have been created
    CreateAccountEmailTaken = 1,
    CreateAccountUsernameTaken = 2,
    LoginConnectionDetectedInAnotherDevice = 7, // login rejected because logged from another device
    LoginConnectionFromAnotherDevice = 8, // disconnected because connected from another device
    LoginBlacklist = 9, // the computer is on the blacklist
    LoginUserNotFound = 10,
    LoginWrongPassword = 11,
    LoginWrongCode = 12, // when the user try to login with the forgot password reset code (Str4 parameter from Connect)
    LoginAccountLocked = 13,
    LoginRestrictedAccount = 14,
    UpgradeRequired = 15, // the client version must be upgraded
    LoginSkinNameRequired = 16, // the skin name is required to log in

    ChangePasswordWrongOldPassword = 17,

    BuyChipsUnderMinimumBuyIn = 20,
    BuyChipsOverMaximumBuyIn = 21,
    BuyChipsInsuficientFunds = 22,
    BuyChipsTournamentStarted = 23,
    BuyChipsWhilePlaying = 24,
    HitAndRun = 25, // value1 = requested amount


    LoginSelfExclusion = 29,

    TakeSeatNoAvailable = 30,
    TakeSeatAlreadyTaken = 31,
    TakeSeatInvalidPosition = 32, // on a 4 player table the valid seat are 0 to 3, cannot take seat 4 to 9.

    LoginGuidAlreadyUsed = 34,

    NotConnected = 40,

    ChatRejected = 50,
    TableIsStopped = 59,

    TableDoesntExist = 60, // MTT table disolve // still in used?
    TournamentDoNotExist = 61, // MTT
    TournamentRegistrationClose = 62, // try to register when tournament is started or registration is closed
    TournamentRegistrationFull = 63, // try to register when tournament is already at max player
    TournamentRegistrationNotEnoughFund = 64, // try to register but not enough money
    TournamentRegistrationPrivate = 65, // this tournament is restricted to a list of player
    TournamentRegistrationRequestDeposit = 66, // the player must have complete a deposit before be able to play
    TournamentRegistrationRequireRakeback = 160, // the player must have pay some rakeback in the recent days to participate
    TournamentRegistrationNotStartedYet = 67, // try to register but too early
    SameComputer = 68, // same computer try to join the same table on different account
    CollusionRestriction = 69, // the server do not allowed those individual to play together

    PasswordMismatch = 70,

    // tournament rebuy error
    RebuyRejectedTooManyChips = 71,
    RebuyRejectedMaxLimitAllowed = 72,
    RebuyRejectedTimeLimit = 73,
    AddOnRejectedAlreadyTookThisBreak = 75,

    TransferP2PDisabled = 80,
    TransferP2PLimitExceeding = 81,

    GiftError = 100, // id gift not found, or someone else, or already refund, check description

    TdsWithdrawalRejectedBecauseMoneyInPlay = 110, // TDS withdrawal rejecte because it some money was in play
    EmailError = 200,
    NotFound = 201, // sort of generic
    UsernameRejected = 202,
    UsernameTooShort = 203,
    UsernameTooLong = 204,
    PasswordTooShort = 205,
    AccountNotVerfied = 206, // this operation required the user to be "verified" status

    // gateway error
    DepositRequestAmountTooSmall = 300,
    WithdrawalInProgress = 301, // cannot do a withdrawal because there is already one in common.

    HandNumberNotDefined = 400, // the hand number is not send, should be in "Value" parameter
    HandNumberNotFound = 401, // the hand number is not found on the server, too old? not yet executed?
    HandNumberNotEnded = 402, // hand is still in progress
    HandNumberAccessDenied = 403, // you don't have the permission to view this hand, maybe you didn't participate in the hand?


    BuyTicketNoTicket = 611, // unknown ticket ID
    BuyTicketNotMoney = 612,// the player does not have enough money to buy
    BuyTicketExeededMaxTicketsPerPlayer = 613,
    BuyTicketExeededMaxTicketsPerAllPlayers = 614,
    BuyTicketExeededMaxUnusedTicketsPerPlayer = 615,

    // generic error message
    InsuficientFunds = 1001,
    InvalidParameters = 1002,


    BitcoinError = 8000, // error related to bitcoin implementation

    Unknown = 9999
}

export enum HandType {
    Hidden = 0,
    High = 1,
    Pair = 2,
    TwoPair = 3,
    Tripple = 4,
    Straight = 5,
    Flush = 6,
    FullHouse = 7,
    FourOfaKind = 8,
    StraightFlush = 9,
    Badugi1Card = 100,
    Badugi2Cards = 101,
    Badugi3Cards = 102,
    Badugi4Cards = 103,
}

export enum LobbyFilters {
    LimitNo = 1,
    LimitPot = 2,
    LimitFixed = 4,

    LimitAny = undefined,

    StakeMicro = 1,
    StakeLow = 2,
    StakeMed = 3,
    StakeHigh = 4,
    StakeAny = undefined,

    ShowOnlyWithTip = 0x800, // return only table that have tips enabled

    TypeRing = 1,
    TypeSitnGo = 2,
    TypeTournament = 4,
    TypeSpinAndGo = 16,

    SpeedNormal = 1,
    SpeedFast = 2,
    SpeedAny = undefined,

    VariantHoldem = 1,
    VariantHoldemRock = 2,
    VariantCapTexasHoldEm = 3,
    VariantOmaha3 = 9,
    VariantOmaha = 10,
    VariantOmahaHiLow = 11,
    VariantOmaha5 = 12,
    VariantOmaha5HiLow = 13,
    VariantOmaha6 = 14,
    VariantTriOmaha = 15,

    VariantTripleDraw = 17,
    Badugi = 18,
    Variant7Stud = 20,
    SevenStudHiLo = 21,

    CapOmaha = 16, // Omaha with CAP, where CAP means max you can lose in a single hand, on all bet rounds. (ring only), (pot limit only)
    CapOmaha5 = 22, // Omaha with CAP, where CAP means max you can lose in a single hand, on all bet rounds. (ring only), (pot limit only)
    CapOmaha6 = 23, // Omaha with CAP, where CAP means max you can lose in a single hand, on all bet rounds. (ring only), (pot limit only)
    CapOmaha7 = 27, // Omaha7 with CAP, where CAP means max you can lose in a single hand, on all bet rounds. (ring only), (pot limit only)


    Omaha7 = 24,
    Razz = 25, // Form of 7 stud game but the lowest possible five-card hand is winner
    SixPlusHoldem = 28, // 6+ Holdem

    CapOmaha5HiLow = 30,
    CapSingleDraw = 31, // 6+ Holdem

    FiveCardDraw = 26, // Similar to triple draw but Hi
    Badeucey = 19, // Badeucey = mix of triple draw and badugi

    VariantMixTable = 100,

    LobbyPrivate = 0x400,
    LobbyPublic = 0,
}

export enum Limit {
    NL = 1,
    PL = 2,
    FL = 4
}

export const LimitName: string[] = [
    '',
    'NL',
    'PL',
    '',
    'FL',
];

export enum PlayerStatus {
    Ready = 1,
    Sitout = 2, // the user is currently sit out
    Disconnected = 3, // connection error by the server and may try to reconnect
    SitoutNextHand = 4, // user request to sit out of next hand but stay on the table
    LeftNextHand = 5, // user left the table while playing
    Cashier = 6, // user is out of money almost as sitout
    LeaveSeat = 7,  // user will leave it's seat (ring only)
}

export enum RequestMessage {
    Connect = 1, // Str1 = username, Str2 = password, Str3 = autologin token (if provided)
    Disconnect = 2, // no param
    // First message sent Text: name of the session and Value1: date installed,
    // Str1 = Name, Str2 = Skin/ReferralName, Str3 = Client Version, Str4 = OsVersion
    Version = 3,
    Ping = 4, // calculate the delay and server time

    MemberProfile = 5, // use in websocket message type
    ConnectWithAuthToken = 6,


    UpdateAccount = 8, // use in websocket message type

    // Game xx
    TakeSeat = 10, // Value2=  Position
    ChangeStatus = 11, // Value2 = PlayerStatus
    BuyChips = 12, // Value2 = amount
    Bid = 13, // Value2 = amount
    Muck = 14,
    // agree to pay the BB on next hand just to be included, IdTable is required, Value = 0 will pay bb, otherwise will be canceled
    PayBBOnNextHand = 15,
    SetRunItTwice = 16, // player agree to run it twice
    Chat = 20, // Str1 = message
    TableSetWaiting = 21, // Value2: 1 = join, 0 = leave
    SitoutOnNextBb = 22, // IdTable required, Value 1: sitout, value 0: nothing stay playing
    PlayStatisticsReset = 23, // IdTable required

    TipUpgrade = 24, // upgrade dealer
    TipChange = 25, // change dealer

    ReturnCards = 27,

    AskRabbitHunting = 28,

    CallTimeAccepted = 30,
    CallTimeStatus = 31,

    CasinoInfo = 38, // 3rd part games integration

    BidStraddle = 45,
    AntiBotAnswer = 50,
    // Lobby 1xx
    GetTables = 100, // Value1 = moneyType, Value2: filters [various combined], Value3: filter by number of seats
    GetTableSummary = 101, // value1 = idTable  to rejoin a specific table
    // value1 = idTournament  request the tournament summary of a single tournament, will return a TournamentSummary message
    GetTournamentSummary = 102,
    GetTableDetail = 103, // IdTable = idTable to display player at the table in the lobby
    JoinTable = 104, // IdTable is required, Str1 contain the password if any
    LeaveTable = 105,
    PrivateTablePassword = 106, // Str1 = password
    //  The number of BB to automatically reload. Value2: reload trigger (0:disabled, 1:Min, 2:default, 3:max),
    // Value3 reload chip to (0:min, 1:default, 2:max)
    SetAutoReload = 110,
    SubscribeStatsPlayerOnline = 111, // view how many player are online
    MTTView = 120, // view or not the tournament (receive update)
    MTTRegister = 121, // register or not to particiate in the tournament
    SetMTTAutoRebuy = 123, // set automatic rebuy of chips when out of money during tournaments, must be send to each new session
    SetMTTAutoAddOn = 124, // set automatic purchase of addon during tournaments break must be send to each new session
    // subscribe to receive real time stats in the lobby. Value = 1 to subscribe, 0 to unsubscribe.
    // Can be send right after the authentication
    SubscribeLiveLobby = 125,


    // subscribe to receivel EXTRA real time information from a table such as waiting list and player chips.
    // IdTable is required, can be 0 to unsubscribe
    SubscribeLiveLobbySelected = 126,
    RequestBlindSchedule = 127,

    GetMixTablesDetails = 128,
    GetChatLobbyHistory = 129,
    GetTableFilters = 130,

    EmoticonsGetList = 152, // Shop
    EmotikensGetPackages = 153, // Shop
    EmotikensBuyPackage = 154, // Shop
    EmoticonUnlock = 155,  // Shop

    CurrencyGetPackages = 160, // Shop
    CurrencyBuyPackage = 161, // Shop
    GetTickets = 162,// Shop
    BuyTicket = 163,// Shop

    ShopSettings = 164,

    NewGameLayout = 170,
    CurrencyPackagesResponse = 180,// Shop

    // Account 2xx
    AccountVerifyUsernameUnique = 200,
    AccountVerifyEmailUnique = 201,
    AccountRequestSecurityCode = 202, // the member request his security code because he forgot his password
    AccountChangePassword = 203,
    ImageUpload = 204, // Str1 is the base64 of the image, Value is the type of image (1,2,3,4...)
    AvatarUpload = 206,
    GetReferalCode = 210, // keep to avoid protobuf error
    CheckPromoCode = 210, // validate if the promo code is valid or not

    RequestPhoneCode = 211,

    SubmitGovermentId = 216,
    ReportBug = 250,

    // Cashier 300
    RequestPlayMoney = 300, // temporary?
    GiftUnlock = 310, // user request to unlock a gift or part of a gift. Value = id of the Gift, Value2 = amount requested to be unlocked
    DepositNetTeller = 320,
    // Player initial a deposit for a specific gateway, Value1 = gateway, Value2 = amount, Value3 = currency.
    // A reply will be returned with the url to be navigated too
    DepositInitiate = 321,
    // Player request Value1= gateway, Value2 = amount, Currency must be specified.
    // A reply will be returned with a message WithdrawalRequestResponse an error
    WithdrawalRequest = 322,
    WithdrawalCancel = 323, // will cancel all pending withdrawal request.  Currency must be specified
    TDSTransferPreview = 330, // compute the fee of a TDS transfer, Value positive is transfer OUT of the TDS, negative into
    TDSTransfer = 331,  // do a TDS transfer Value positive is transfer OUT of the TDS, negative into
    RequestTransactionHistory = 332, // request the list of transaction (deposit and withdrawal)

    TransferMoney = 399, // transfer money to another user

    HandReplay = 400, // Value = HandNumber
    HandReplay2 = 401, // Value = HandNumber, return the json form and not in a big text format
    // Value = HandNumber, return previous and future hand around a specific handnumber.
    // If the value is 0, recent hands will be returned
    HandHistory = 402,
    HandStrength = 403,

    SetAutoMuck = 601, // set option to auto muck
    SetJumpToTable = 602, // Jumpt to table when it's your turn to play (Value = 1 or 0)
    SetPlayerNotes = 603, // Value = idPlayer, Str1 = note, the server will truncate it to 1KB max

    SetPlayerColor = 608,

    // From [int/currency] the currency of the exchange that represent the source.
    // To [int/currency] the currency of the exchange that represent the destination.
    // Requesting exchange rate for USD to Rupees, server return the ratio 50 between the 2.
    // Send: {"type":604,"Currency":"1","Value":"4"}
    ExchangeRate = 604,
    SelfExclude = 606,
    SetStraddle = 607,

    UseSpellRequest = 701,

    GetAuthToken = 900,
    InvalidateAuthToken = 901,
    PlayerAlignmentInitiate = 902
}

export enum ServerMessage {
    Info = 0, // entire table information. Received when you join the table for instance or get move to to another table (tournament)

    GameStart = 1, // Tournament: Value: Number of players, Value2: Official time started (unix date format)
    GameEnd = 2, // Tournament: event when game is over. No parameter.
    HandStart = 3, // [IdTable],  Value = id of the hand, Value2: # number player playing the hand.
    GameStatusChanged = 4, // [IdTable], Value = new status of the game. See GameStatus enum

    // both message have the same format.  [IdTable], [IdPlayer], Value = timebank balance is second
    TimeBankStatus = 8, // player new timebank balance (after hand is over)
    TimeBankUsing = 9, // player start using his time bank (during player bidding round when out of time)

    // player bid / action, all those messages have the same format.
    // [IdTable], [IdPlayer], Value = absolute MoneyBet for the current round (not cumulative). Value2: Player balance
    Fold = 10,
    BidAnte = 11,
    BidSmallBlind = 12,
    BidBigBlind = 13,
    BidCheck = 14,
    BidBet = 15,
    BidCall = 16,
    BidRaise = 17,
    BidAllIn = 18,
    BidStraddle = 45,
    ReportBugResponse = 250,

    // server action
    Dealer = 20, // set the dealer player id, Value= the position of the dealer
    PlayerTurnChange = 21, // See https://github.com/ElninhoConsulting/Html5V2/wiki/Poker-Game-Engine-Api#player-turn-change

    // send a card to a player, everyone received it, actually add the card to the player hand,
    // Value: is the seat the card is send from (dealer) Value2: is the total number of cards expected to be sent.
    // Cards: array will always contains a single card.
    // If the cards has property IsHidden: the card value is not important as those are hidden.
    // else the card is fully visible to everyone 7stud for instance have that.
    SendCardHidden = 22,

    SendAllCardsToPlayer = 23, // [Private msg] Those are your cards. [IdTable], [IdPlayer],[Cards], Value: your balance
    RevealCard = 24, // player publicaly reveal it's card. [IdTable], [IdPlayer], [Cards]
    CardDiscard = 25, // Remove player card (after find the dealer for instance)  [IdTable], [IdPlayer]

    // when overbidding return the extra to the player. [IdTable], [IdPlayer], Value: amt returned to the player. Value2: player Balance
    ReturnBackMoney = 26,
    // move money from players bid to the central pot. [IdTable], Values: array of all the pots value.
    // Can be multiple pot in case of all-in.
    MoveToPot = 27,
    RunningTwice = 28, // the running twice rule is applied. [IdTable]

    // list of community cards to show (this is used to rollback card in running twice for instance),
    // [IdTable], [Cards]: community cards share for both series, [CommunityCardsR2T1] community cards serie 1,
    // [CommunityCardsR2T2]: commmunity cards serie 2
    CommunityCards = 29,

    // full result of a pot winner. Pot: Contains a complex object with
    // PotIndex: index of the pot, in case multiple pot get awarded
    // Winners: List of hands/players winning that pot
    // Loosers: List of hands/players losing that pot
    //    both winners and lossers array contain:
    //          IdPlayer: The id of the player
    //          MoneyWin: how much money this player has won for that pot
    //          Cards & Breakers: cards use to win (so they can be hilight)
    //          HandResult: see https://github.com/ElninhoConsulting/Html5V2/wiki/Poker-Game-Engine-Api#handtype
    // NbPlayer: number of player who contributed to the pot
    // IsR2T: if true the pot is part of the run 2 times, the PotIndex will determine if it's the first or second series
    // OmahaHi:if true, the pot is splitted in half and this pot is the 'hi' pot
    // OmahaLow:if true, the pot is splitted in half and this pot is the 'low' pot
    PotResult = 30,

    // Next 3 messages use the same format
    // [IdTable], [IdPlayer], Value: money win, Value2: balance, Cards: cards used to win if showed.
    WinnerByFold = 31, // player win because everyone else folded
    WinnerByStrongestHand = 32, // player win because he got the best hand
    WinnerSplit = 33, // player win part of the pot because he got a tie best hand

    PlayerTurnCardsReturnChange = 38,
    SkipNextHand = 39, // [Private msg] send to client if he will be skipped on next hand because he didn't agree to pay the BB

    //  [Private msg] asked if player want to show his cards because everyone else folded. Value: nb seconds to answer message 'Muck' #14
    AskShowCard = 40,

    ReplaceCardsPeriodOver = 41,

    // player purchase chips,[IdTable], [IdPlayer], Value= nb chips total, value2: 1=rebuy, 2=addon. value3: 1 = automatic
    PlayerBuyChips = 42,
    PlayerLeave = 43, // user leave the table, [IdTable], [IdPlayer], [PlayerData]: player profile including name
    PlayerStatus = 44, // player status change: see: https://github.com/ElninhoConsulting/Html5V2/wiki/Poker-Game-Engine-Api#sitout--im-back

    // take a physical place on the table  [IdTable], [IdPlayer], Value: seat index, [PlayerData]: player profile including name
    PlayerTakeSeat = 46,
    PlayerSetRunItTwice = 47, // player set the option to use Run it twice or not. [IdTable], [IdPlayer], Value = 1: enable, 0: disable
    PrivateTablePasswordResponse = 48, // response to PrivateTablePassword, Value = 1:password ok, 0: wrong password

    TournamentCanceled = 50, // tournament has been canceled because not enought players. [IdTournament]
    TournamentEndResult = 51, // player finish the tournament. [IdTournament], Value = position (1based), Value2 = amount won

    BlindLevel = 52, // Blind level increase. [IdTournament], Value: blind level, Value2: small blind amount, Value3: ante amount

    GamePause = 53, // Tournament break. [IdTournament], Value3: number of seconds. Ignore parameter Value and Value2 if present.

    // (mix table only) the variant (texas, omaha) of the table changed. [IdTable],
    // Value: new variant https://github.com/ElninhoConsulting/Html5V2/wiki/Poker-Game-Engine-Api#varianttype.
    VariantChanged = 54,
    // (mix table only) the limit (no limit, pot limit) of the table changed. [IdTable],
    // Value: new limit https://github.com/ElninhoConsulting/Html5V2/wiki/Poker-Game-Engine-Api#lobby-filters
    LimitChanged = 55,
    DealerTipStatus = 62, // info for dealer strip
    AskPlayerReEntry = 64,
    SpinNGoMultiplier = 65,

    OfferRabbitHunting = 71, // Rabbit Hunting game extension
    StartedRabbitHunting = 72,

    FreeRabbitHunting = 77,
    ChargedRabbitHunting = 76,

    DisconnectionProtectionTime = 82,
    RabbitHuntingPrize = 83,


    PotSplitted = 80, // When pot is splitted into 2 (R2T or Omahah Hi/lo). [IdTable], [Pots]
    EndOfHand = 81,

    // stats about the player play for a particular table.
    // [IdTable], [IdPlayer], Values[0 = NbHandsPlayed,  1 = NbHandsWon, 2 =TotalBet,  3 = TotalWon].
    // Can be reset by sending PlayStatisticsReset #23
    PlayerPlayStatistic = 100,


    // ****** Tournament ******
    // player assigned or swith table
    // [IdTournament], [IdPlayer], [PlayerData], IdTable: old table the player was from (if moved to another table).
    // Value: id of the new table the player is assigned to.
    MTTTableAssigned = 56,

    // player is out of chips, ask for rebuy. value2 = NbSeconds to buy, must send BuyChips with a value > 0 to accept, value < 0 to reject
    AskPlayerRebuy = 59,
    // player do not rebuy, this notification is to let OTHER people know the server is no longer waiting on that player to continue.
    PlayerDoNotRebuy = 60,
    AskPlayerAddOn = 61, // ask the player if he want to do an add-on

    HandType = 70, // used to show player hand strength
    HandTypeDoubleBoardGames = 74, // used to show player hand strength

    AskQueston = 90, // Government ID
    OfferExternalAlingment = 91,

    EmotikensPackages = 154,
    EmotikensBuyPackageReponse = 155,
    EmoticonUnlockResponse = 156,

    NewGameLayoutResponse = 170,

    CurrencyPackages = 180,
    GetTicketsResponse = 182,
    BuyTicketResponse = 183,
    ShopSettingsResponse = 184,

    ExchangeRate = 101,

    DepositInitiateResponse = 321, // https://github.com/ElninhoConsulting/Html5V2/wiki/Poker-Game-Engine-Api#deposit

    TDSTransferPreviewResponse = 330,
    TDSTransferResponse = 331,

    HandReplay = 400, // response to hand replay. Value: hand number, Text: the whole hand replay in lines of json.

    UseSpellResponse = 701,
    SpellExecuted = 702,
    ActivateSpellRequest = 703,
    NoSpellZone = 706,
    
    GetAuthToken = 900,

    // Priority message can be process out of order as they arrive, all other messages bellow 1000 must be process
    // in the order they arrived.
    PRIORITY_MESSAGE = 1000, // [Marker] not used

    // chat message from player or admin. [IdTable], [IdPlayer], Text: chat message,
    // PlayerData: name of the player who send the message, if null it's admin
    Chat = 1001,
    // https://github.com/ElninhoConsulting/Html5V2/wiki/Poker-Game-Engine-Api#display-online-players-ringtournament
    StatsPlayerOnline = 1002,
    // player new balance (to update the lobby). [IdPlayer], Value: Balane, Value2: currency, Values[0]: account type
    // https://github.com/ElninhoConsulting/Html5V2/wiki/Poker-Game-Engine-Api#accounttype
    PlayerBalance = 1003,
    Reinstall = 1004, // request to client to be re-installed from another url. DESKTOP ONLY. Maybe android? Text: new url
    // join or leave the waiting list. [IdTable], [IdPlayer], Value: 1= join, 0 = leave, Text: list of players name separated by comma
    PlayerWaiting = 1047,

    MTTStarting = 1070, // message for viewers that the tournament is starting. [IdTournament], Value2: Date starting (unit timeformat)
    // message for viewer of the tournament that blind changed. This help determine when late registration is over. Value: perdiod number.
    MTTBlindChanged = 1071,

    AccountCreateResponse = 1100, // reply of sending MemberProfile with no id
    AccountUsernameUniqueResponse = 1101, // reply to VerifyUsernameUnique
    AccountEmailUniqueResponse = 1102, // reply to VerifyEmailUnique
    AccountSecurityCodeVerified = 1103, // notification to the user that he just confirm his email with the link
    AccountPasswordChangeResponse = 1104,
    AccountUpdateResponse = 1105,
    CheckPromoCodeResponse = 1106, // reply to CheckPromoCode if the agent code is valid
    AccountRequestSecurityCodeResponse = 1107, // response to lost password AccountRequestSecurityCode

    PlayerAlignmentInitiateResponse = 1111,

    SetMTTAutoRebuyReponse = 1123, // response to SetMTTAutoRebuy
    SetMTTAutoAddOnReponse = 1124, // response to SetMTTAutoAddOn


    ImageUploadResponse = 1125, // response to SetMTTAutoAddOn

    // Deposit
    DepositNetTellerResponse = 1200,

    // response to RequestPhoneCode, Value: 1 ok, -1 not set, -2 invalid, -3 already verified, -4 locked, -5 error. Check Text for details
    RequestPhoneCodeResponse = 1211,
    // response to SubmitPhoneCode, Value: 1 ok, -1 not set, -2 invalid, -3 already verified, -4 locked, -5 wrong code.
    // Check Text for details
    SubmitPhoneCodeResponse = 1212,

    AccountSubmitGovermentIdResponse = 1216,

    // Dealer strip
    TipUpgradeResponse = 1224,

    RequestSelfExclusionResponse = 1226,

    TransferMoneyResponse = 1250,
    // withdrawal
    RequestWithdrawalResponse = 1260,

    Error = 1300, // see https://github.com/ElninhoConsulting/Html5V2/wiki/Poker-Game-Engine-Api#error-code

    AdminMsg = 1400, // server send message to all clients.  Text: message content
    AdminShuttingDown = 1401, // when the server shut down, display a warning. Text: message content
    Debug = 1402, // debug information, ignore

    MessageInfo = 1403,  // msg to display. see https://github.com/ElninhoConsulting/Html5V2/wiki/Poker-Game-Engine-Api#popup-timed
    // replay to Ping. Value: Server time in .net format, Value2: the time you send. Compare to your local time to know the network delay
    Pong = 1502,
    // when player request rebuy during a hand. If received this message it means the server confirmed the rebuy will be done on next hand.
    TournamentAddChipsResult = 1501,
    ConnectResponse = 2001,

}

export enum TransactionsHistoryStatus {
    NotDefined = 0, // should not be seen/error
    Confirmed = 1, // the transaction has been processed completely
    Pending = 2, // the transaction is pending from administrative review. In the case of withdrawal it can usually be cancelled
    Cancelled = 3, // the transaction has been rejected, cancel or refunded
}

export enum VariantType {
    HoldEm = 0,
    Omaha = 1,
    Omaha5 = 2, /* Omaha with 5 cards instead of 4 */
    SevenStud = 4,
    // the combination will be from 3 cards, exactly 2 cards from player's 4 cards and only 1 from the table. so best combination is AAA
    TriOmaha = 8,
    Omaha6 = 16,
    TexasHoldEmRock = 32,
    MixTable = 256
}

export enum VariantType2 {
    TexasHoldEm = 1,
    TexasHoldEmRock = 2, // (ring only)
    CapTexasHoldEm = 3, // CAP, where CAP means max you can lose in a single hand. (ring only), (not limit only)
    Omaha3 = 9, // Omaha with 3 cards
    Omaha = 10,
    OmahaHiLo = 11, /* Omaha with hi/lo winners */
    Omaha5 = 12, /* Omaha with 5 cards instead of 4 */
    Omaha5HiLo = 13, /* Omaha with 5 cards instead of 4 with hi/lo winners */
    Omaha6 = 14, /* Omaha with 6 cards instead of 4 */
    TriOmaha = 15, // the combination will be from 3 cards, exactly 2 cards from player's 4 cards and only 1 from the table. so best combination is AAA
    CapOmaha = 16, // Omaha with CAP, where CAP means max you can lose in a single hand, on all bet rounds. (ring only), (pot limit only)
    CapOmaha5 = 22, // Omaha with CAP, where CAP means max you can lose in a single hand, on all bet rounds. (ring only), (pot limit only)
    CapOmaha6 = 23, // Omaha with CAP, where CAP means max you can lose in a single hand, on all bet rounds. (ring only), (pot limit only)
    CapOmaha7 = 27, // Omaha7 with CAP, where CAP means max you can lose in a single hand, on all bet rounds. (ring only), (pot limit only)
    Omaha7 = 24, // Omaha with 7 cards instead of 4
    TripleDraw = 17, // 2/7 triple draw
    SingleDraw = 29, // 2/7 single draw - subvariant of the triple draw but with only one draw round
    Badugi = 18, // Badugi
    Badeucey = 19, // Badeucey = mix of triple draw and badugi
    SevenStud = 20, // player receives 7 cards, 2 face down - private, 4 face up - everyone can see, and 7th card face down - private, no public/community cards. (Fix limit only. and has ante)
    SevenStudHiLo = 21, // hybrid of 7 stud and Omaha hi/lo
    Razz = 25, // Form of 7 stud game but the lowest possible five-card hand is winner
    FiveCardDraw = 26, // Similar to triple draw but Hi
    SixPlusHoldem = 28, // 6+ Holdem
    MixTable = 100 // the variant is changing every hand and sent by the server.
    

}

export enum TournamentStatus2 {
    Hidden = 0,                         // the tournament is not visible at all
    Announced = 1,                      // visible in the lobby but cannot register yet
    RegistrationOpen = 2,               // you can register, tournament not started
    RunningWithLateRegistration = 3,    // tournament running with late registration still accepted
    RunningRegistrationClosed = 4,      // tournament running, can no longer register
    Completed = 5,                      // tournament is over
    Canceled = 6                        // has been canceled (not enought players registered)
}

export enum WaitingListCommand {
    Leave = 0,
    Join = 1
}
