import { Injectable, NgZone } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { ENavItem } from '../nav/nav-item-type';
import { ServerMessage, VariantType2 } from '../../shared/enums/poker-types';
import { MatDialog } from '@angular/material/dialog';
import { BuyChips } from '../../shared/models/buy-chips';
import { BuyChipsComponent } from './dialogs/buy-chips/buy-chips.component';
import { GameComponent } from './game.component';
import * as PIXI from 'pixi.js';
// import { Store } from '@ngrx/store';
import { State } from '../../app.reducers';
import { AppActions } from '../../app.actions';
import { getActiveGames } from './store/game-store.reducer';

import { AuthService } from '../../auth/auth.service';
import { GenericTextDialog } from '../../core/dialogs/generic-text-dialog/generic-text-dialog.component';
import { NotificationsService } from '../../core/notifications/notifications.service';
import { DialogTournamentEndResultComponent } from '../tournament/presenters/dialog-tournament-end-result/dialog-tournament-end-result.component';
import { TournamentEndResult, CardData } from '../../shared/models';
import { GameEvents } from './models/game-events';
import { LoggerService } from '../../core/services/logger.service';
import { DataManagerService } from '../../core/services/data-manager.service';
import { WindowResizeService } from '../../core/services/window-resize.service';
import { AssetsLoaderService } from '../../core/services/assets-loader.service';
import { CurrencyMultiplierPipe } from '../../shared/utils/currency-multiplier.pipe';
import { environment } from '../../../environments/environment';
import { cardDecoder } from '../../shared/helpers/card-decoder';
import { CurrencyPipe } from '../../shared/utils/currency.pipe';
import { CurrencyDeviderPipe } from '../../shared/utils/currency-devider.pipe';
import { MainService } from '../main.service';
import { Currency, GameStatus } from '../../shared/enums';
import { TranslateService } from '@ngx-translate/core';
import { CallTimeStatus, ManagerService } from '../../core/services/manager.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BountySpellErrorCodes, BountySpellVariant } from '../../shared/models/bounty-spell';
import { AntibotComponent } from './dialogs/antibot/antibot.component';

@Injectable()
export class GameService {

  private subscription: Subscription = new Subscription;
  private resizeSubscription: Subscription;

  public gameWidthHorizontal = 1920;
  public gameHeightHorizontal = 930;
  public gameWidthVertical = 1080;
  public gameHeightVertical = 1600;
  public minimumGameRatio = 0.2;
  public maximumGameRatio = 1;
  // public renderer: PIXI.Application;
  public gameComponents: GameComponent[] = [];

  private gameWidth;
  private gameHeight;

  currentGameComponent;

  private dialogRef;

  public appliedRatio = 1;
  antiBotDialog;
  constructor(
    private logger: LoggerService,
    public authService: AuthService,
    public zone: NgZone,
    public dialog: MatDialog,
    // private store: Store<State>,
    private dataManager: DataManagerService,
    private notificationsService: NotificationsService,
    private windowResizeService: WindowResizeService,
    private loaderService: AssetsLoaderService,
    public mainService: MainService,
    private translateService: TranslateService,

    private managerService: ManagerService,

    private breakpointObserver: BreakpointObserver

  ) {
    // const ticker = PIXI.Ticker.shared;
    // // Set this to prevent starting this ticker when listeners are added.
    // // By default this is true only for the PIXI.ticker.shared instance.
    // ticker.autoStart = false;
    // // FYI, call this to ensure the ticker is stopped. It should be stopped
    // // if you have not attempted to render anything yet.
    // ticker.stop();

    // this.breakpointObserver
    //   .observe([Breakpoints.HandsetPortrait]).subscribe(result => {
    //     if (result.matches) {
    //       this.gameWidth = this.gameWidthVertical;
    //       this.gameHeight = this.gameHeightVertical;
    //     } else {
    //       this.gameWidth = this.gameWidthHorizontal;
    //       this.gameHeight = this.gameHeightHorizontal;

    //     }
    //   })


    // if (environment.settings.useVerticalMode) {
    //   this.gameWidth = this.gameWidthVertical;
    //   this.gameHeight = this.gameHeightVertical;
    // } else {
    //   this.gameWidth = this.gameWidthHorizontal;
    //   this.gameHeight = this.gameHeightHorizontal;
    // }

    // this.zone.runOutsideAngular(() => {
    //   this.renderer = new PIXI.Application(this.gameWidth, this.gameHeight, { transparent: true });

    //   requestAnimationFrame(() => {
    //     this.updateTables();
    //   });
    // });

    this.dataManager.messageReceivedObservable.subscribe(data => {
      this.parseWsMsg(data);
    });

    // this.mainService.splitScreenModeState.subscribe(isSplitmode => {
    //   setTimeout(() => {
    //     this.resizeGameRenderer();
    //   }, 200);
    // });

    // this.resizeSubscription = this.windowResizeService.onResize$.subscribe(size => this.resizeGameRenderer());

    this.loaderService.reloadObservable$.subscribe(() => {
      for (const gameComponent of this.gameComponents) {
        //    gameComponent.updateTextures();
        gameComponent.rotateTable()
      }
    });



  }

  // updateTables() {
  //   this.zone.runOutsideAngular(() => {
  //     this.gameComponents.forEach(gc => {
  //       gc.updateTable(gc.getDeltaTime());
  //     });
  //     requestAnimationFrame(() => this.updateTables());
  //   });
  // }

  // resizeGameRenderer() {
  //   return new Promise<void>((resolve, reject) => {



  //     let activeGameComponent: GameComponent;
  //     const size = { innerWidth: window.innerWidth, innerHeight: window.innerHeight };
  //     activeGameComponent = this.getActiveGameComponent();
  //     if (!activeGameComponent) { return; }

  //     // if (activeGameComponent.availableSpace) {

  //     //   size.innerWidth = activeGameComponent.availableSpace.nativeElement.offsetWidth;
  //     //   size.innerHeight = activeGameComponent.availableSpace.nativeElement.offsetHeight;
  //     // }

  //     if (size.innerWidth > 1279) {
  //       size.innerHeight -= 50;
  //     }

  //     const newWidth = size.innerWidth;
  //     const newHeight = size.innerHeight;
  //     this.appliedRatio = 0;
  //     const widthRatio = newWidth / this.gameWidth;
  //     const heightRatio = (newHeight / (this.gameHeight)); // TODO: see why is better to add +200
  //     if (widthRatio < heightRatio) {
  //       this.appliedRatio = widthRatio;
  //     } else {
  //       this.appliedRatio = heightRatio;
  //     }

  //     if (this.appliedRatio > this.maximumGameRatio) {
  //       this.appliedRatio = this.maximumGameRatio;
  //     }
  //     if (this.appliedRatio < this.minimumGameRatio) {
  //       this.appliedRatio = this.minimumGameRatio;
  //     }

  //     // if (this.mainService.is2x2Active) {
  //     //   this.appliedRatio *= 0.5;
  //     // }

  //     // if (this.renderer != null) {
  //     //   this.renderer.view.style.width = this.gameWidth * appliedRatio + 'px';
  //     // }





  //     // this.gameComponents.forEach(gc => {
  //     //   gc.resizeRenderer(this.appliedRatio);
  //     // });

  //     resolve()
  //   });
  // }


  setOnMove(tableId: number, onMove: boolean = false) {
    this.managerService.setOnMove(tableId, onMove)

    // this.store.dispatch(new AppActions.GameAction.SetOnMove(tableId, onMove));
    this.jumpToTable();
  }

  setFolded(tableId: number, folded: boolean = true) {
    this.managerService.setFolded(tableId, folded)

    // this.store.dispatch(new AppActions.GameAction.SetFolded(tableId, folded));
  }

  setCardsInHand(tableId: number, cardsInHand: CardData[] = []) {
    this.managerService.setCardsInHand(tableId, cardsInHand)

    //  this.store.dispatch(new AppActions.GameAction.SetCardsInHand(tableId, cardsInHand));
  }

  setPlayerMoney(tableId: number, playerMoney: number) {
    this.managerService.setPlayerMoney(tableId, playerMoney)
    //   this.store.dispatch(new AppActions.GameAction.SetPlayerMoney(tableId, playerMoney));
  }

  jumpToTable() {
    if (this.authService.user.memberProfile && this.authService.user.memberProfile.Preferences.JumpToTable) {
      this.managerService.activeGames$.pipe(
        take(1),
        map(data => Object.keys(data).map(id => data[id]))
      ).subscribe(activeGames => {

        let activeAndOnMove = false;
        let tableIdOnMove = null;

        for (let i = 0; i < activeGames.length; i++) {
          if (activeGames[i] && activeGames[i].isActive && activeGames[i].onMove) {
            activeAndOnMove = true;
            break;
          } else if (activeGames[i] && activeGames[i].onMove && !tableIdOnMove) {
            tableIdOnMove = activeGames[i].table.IdTable;
          }
        }
        if (!activeAndOnMove && !!tableIdOnMove) {
          this.managerService.setActiveGame(tableIdOnMove)
          //    this.store.dispatch(new AppActions.GameAction.SetActiveGame({ tableId: tableIdOnMove }));
        }
      });
    }

    // if (this.authService.user.memberProfile && this.authService.user.memberProfile.Preferences.JumpToTable) {
    //   const games$ = this.store.select(getActiveGames).pipe(take(1)).subscribe(activeGames => {
    //     let activeAndOnMove = false;
    //     let tableIdOnMove = null;

    //     for (let i = 0; i < activeGames.length; i++) {
    //       if (activeGames[i] && activeGames[i].isActive && activeGames[i].onMove) {
    //         activeAndOnMove = true;
    //         break;
    //       } else if (activeGames[i] && activeGames[i].onMove && !tableIdOnMove) {
    //         tableIdOnMove = activeGames[i].table.IdTable;
    //       }
    //     }
    //     if (!activeAndOnMove && !!tableIdOnMove) {
    //       this.store.dispatch(new AppActions.GameAction.SetActiveGame({ tableId: tableIdOnMove }));
    //     }
    //   });
    // }
  }

  buyChips(tableId: number, dialogTimeout: number, onlyVerify = false) {

    const gameComponent = this.getGameById(tableId);

    const minTableChips = gameComponent.getBlind() * gameComponent.tableSum.TakeSeatMin * 2;
    const maxTableChips = gameComponent.getBlind() * gameComponent.tableSum.TakeSeatMax * 2;
    const defaultTableChips = gameComponent.getBlind() * gameComponent.tableSum.TakeSeatDefault * 2;
    let minBuy = minTableChips > 0 ? minTableChips : CurrencyMultiplierPipe.prototype.transform(0.1, gameComponent.currency);
    let maxBuy = this.authService.user.playerBalance[gameComponent.currency.Id].availableMoney > maxTableChips
      ? maxTableChips
      : this.authService.user.playerBalance[gameComponent.currency.Id].availableMoney;
    let defaultBuy = maxBuy > defaultTableChips ? defaultTableChips : maxBuy;

    if (!onlyVerify) {
      if (!gameComponent.currentPlayer) {
        return;
      }

      if (gameComponent.currentPlayer.money >= maxTableChips) {
        this.notificationsService.showError('You have maximum number of chips to play on this table');
        return;
      }

      minBuy = minTableChips > gameComponent.currentPlayer.money ?
        minTableChips - gameComponent.currentPlayer.money : CurrencyMultiplierPipe.prototype.transform(0.1, gameComponent.currency);

      maxBuy = this.authService.user.playerBalance[gameComponent.currency.Id].availableMoney > maxTableChips
        ? maxTableChips - gameComponent.currentPlayer.money
        : this.authService.user.playerBalance[gameComponent.currency.Id].availableMoney;

      defaultBuy = maxBuy > defaultTableChips ? defaultTableChips : maxBuy;
    }




    if (this.authService.user.playerBalance[gameComponent.currency.Id] !== undefined
      && this.authService.user.playerBalance[gameComponent.currency.Id].availableMoney >= minBuy) {
      if (onlyVerify) {
        return true
      }
      const buyChips: BuyChips = {
        available: this.authService.user.playerBalance[gameComponent.currency.Id].availableMoney,
        value: Math.round(defaultBuy),
        min: minBuy,
        max: maxBuy,
        step: gameComponent.getBlind(),
        currency: gameComponent.currency,
        dialogTimeout: dialogTimeout,
      };

      if (this.dialogRef) { return; }
      this.dialogRef = this.dialog.open(BuyChipsComponent, { data: buyChips, width: '460px', disableClose: true });
      this.dialogRef.afterClosed().subscribe((chipsAmount) => {
        if (chipsAmount) {
          this.dataManager.sendBuyChips(tableId, chipsAmount);
          this.dataManager.sendImBack(tableId);
        } else {
          if (gameComponent.currentPlayer && !gameComponent.currentPlayer.money) {
            // ## User will take a seat but without chips, this is for auto leave: this.actionLeaveSeat(tableId);
            this.actionLeaveSeat(tableId);
            gameComponent.checkBuyChipsVisibility()
          }
        }
        this.dialogRef = undefined;
      });
    } else if (this.authService.user.playerBalance[gameComponent.currency.Id].availableMoney === 0) {

      const minAmountToDisplay = gameComponent.isTournament ? `${minTableChips}` : CurrencyPipe.prototype.transform(
        CurrencyDeviderPipe.prototype.transform(minTableChips, gameComponent.currency),
        gameComponent.currency);

      const dialogRef = this.dialog.open(GenericTextDialog);

      combineLatest([
        this.translateService.get([
          'dialog.notEnoughFundsToAddChips.title',
          'dialog.notEnoughFundsToAddChips.text',
          'dialog.notEnoughFundsToAddChips.button.ok',
        ]),
        this.managerService.gatewaysInfo$
      ])
        .pipe(take(1))
        .subscribe(([trans, gatewaysInfo]) => {
          dialogRef.componentInstance.textHeader = trans['dialog.notEnoughFundsToAddChips.title'];
          // dialogRef.componentInstance.textBody = trans['dialog.notEnoughFundsToAddChips.text'];
          dialogRef.componentInstance.textBody = `Minimum amount required: ${minAmountToDisplay}`; //trans['dialog.notEnoughFundsToAddChips.text'];

          dialogRef.componentInstance.enableClose = true;
          dialogRef.componentInstance.enableAccept = gatewaysInfo.deposit.length > 0;
          dialogRef.componentInstance.textAcceptBtn = 'Close'
          dialogRef.componentInstance.textAcceptBtn = `Deposit now`;

          dialogRef.afterClosed().subscribe(clicked => {
            console.log("CLICKED", clicked)
            //this.actionLeaveSeat(tableId);

            if (clicked === 'ok') {
              this.managerService.setActiveTab(ENavItem.Cashier)
            } else {
              if (onlyVerify) {
                return false
              } else {
                this.actionLeaveSeat(tableId);

              }
            }
          });
        });

    } else {
      const dialogRef = this.dialog.open(GenericTextDialog);



      const minAmountToDisplay = gameComponent.isTournament ? `${minTableChips}` : CurrencyPipe.prototype.transform(
        CurrencyDeviderPipe.prototype.transform(minTableChips, gameComponent.currency),
        gameComponent.currency);

      combineLatest([
        this.translateService.get([
          'dialog.notEnoughFundsToAddChips.title',
          'dialog.notEnoughFundsToAddChips.text',
          'dialog.notEnoughFundsToAddChips.button.ok',
        ]),
        this.managerService.gatewaysInfo$
      ])
        .pipe(take(1))
        .subscribe(([trans, gatewaysInfo]) => {
          dialogRef.componentInstance.textHeader = trans['dialog.notEnoughFundsToAddChips.title'];
          dialogRef.componentInstance.textBody = `Minimum amount required: ${minAmountToDisplay}`; //trans['dialog.notEnoughFundsToAddChips.text'];

          dialogRef.componentInstance.enableClose = true;
          dialogRef.componentInstance.enableAccept = gatewaysInfo.deposit.length > 0;
          dialogRef.componentInstance.textAcceptBtn = 'Close'
          dialogRef.componentInstance.textAcceptBtn = `Deposit now`;

          dialogRef.afterClosed().subscribe(clicked => {
            console.log("CLICKED", clicked)
            //this.actionLeaveSeat(tableId);

            if (clicked === 'ok') {
              this.managerService.setActiveTab(ENavItem.Cashier)
            } else {
              return false
            }
          });
        });




    }
  }

  actionLeaveSeat(tableId: number) {
    this.dataManager.sendLeaveSeat(tableId);
    this.managerService.leaveSeat(tableId)
    // this.store.dispatch(new AppActions.GameAction.LeaveSeat(tableId));

  }

  actionLeaveTable(tableId: number) {
    this.dataManager.sendLeaveTable(tableId);

    // ToDo show another active table or re-route to lobby if there is no active table
  }

  closeTable(tableId: number) {
    // this.store.dispatch(new AppActions.GameAction.RemoveGame({ tableId: tableId }));
    this.managerService.removeGame(tableId)
    if (this.mainService.is2x2Active && this.gameComponents.length > 2) {
      return;
    }

    if (this.mainService.is2x2Active && this.gameComponents.length === 2) {
      this.mainService.disable2x2();
      return;
    }

    if (this.mainService.is2x2Active && this.gameComponents.length < 2) {
      this.mainService.disable2x2();
    }

    this.managerService.setActiveTab(ENavItem.Lobby)
    //  this.store.dispatch(new AppActions.NavAction.ChangeLayout(ENavItem.Lobby));
  }

  playerSitOnFirstAvaiableSeat(tableId: number) {

    const gameComponent = this.getGameById(tableId);
    if (gameComponent) {
      gameComponent.playerSitOnFirstAvaiableSeat();
    }
  }

  getActionControls(idTable: number) {
    const gameComponent = this.getGameById(idTable);
    if (gameComponent) {
      return gameComponent.actionControls
    } else {
      return null
    }
  }

  parseWsMsg(msg) {

    if (msg.Response === 'AntiBotQuestion') {
      if (!this.antiBotDialog) {
        this.antiBotDialog = this.dialog.open(AntibotComponent, { width: '300px' });

        this.antiBotDialog.afterClosed().subscribe((data) => {
          this.antiBotDialog = null;
        });
      }
    }
    else if (msg.Response === 'CallTimeStatus') {

      const data: CallTimeStatus[] = msg.CallTimeStatus.map(el => {
        // el.ActivatedAt = new Date(el.ActivatedAt)
        return el as CallTimeStatus
      })

      for (const callTimeStatus of data) {
        const gameComponent = this.getGameById(callTimeStatus.IdTable);
        if (gameComponent) {
          gameComponent.setPlayerGameStatus(callTimeStatus)
        }
      }


    }
    else if (msg.Response === 'BountySpellExecuted') {
      const data = msg.BountySpellExecuted;
      console.log("BountySpellExecuted MSG", data.IdTournament, msg.IdTable)
      const gameComponent = data.IdTournament ? this.getGameByTourId(data.IdTournament) : this.getGameById(msg.IdTable); //msg table id is mock
      if (!gameComponent) {
        return
      }
      data.InitiatorPlayer.avatar = data.InitiatorPlayer.AvatarURL ?
        `${this.managerService.config.httpUrl}/avatar/${data.InitiatorPlayer.AvatarURL}` : `${environment.dataSource}/assets/${this.managerService.config.code}/player/avatar.png`





      if (data.AffectedPlayers) {


        for (const affectedPlayer of data.AffectedPlayers) {
          affectedPlayer.avatar = affectedPlayer.AvatarURL ?
            `${this.managerService.config.httpUrl}/avatar/${affectedPlayer.AvatarURL}` : `${environment.dataSource}/assets/${this.managerService.config.code}/player/avatar.png`

        }

        data.immunityPlayers = data.AffectedPlayers.filter(player => player.UsedImmunity) ?? []
        if (data.Spell === BountySpellVariant.SuddenDeath) {
          data.shotestStackPlayer = data.AffectedPlayers.filter(player => !player.UsedImmunity).sort((a, b) => {
            return a.Chips > b.Chips ? -1 : 1
          })[0]
        }
      }

      let spellName = ''
      console.log("BountySpellExecuted MSG", msg, gameComponent, this.getGameById(msg.IdTable))

      if (data.Spell === BountySpellVariant.RobAPlayer) {


        const affectedPlayer = data.AffectedPlayers.find(player => player.Affected)
        if (!affectedPlayer.UsedImmunity) {
          data.InitiatorPlayer.Chips -= data.Chips
          data.InitiatorPlayer.robbedAmount = data.Chips
          affectedPlayer.Chips += data.Chips
          affectedPlayer.robbedAmount = data.Chips
        }
        gameComponent.startRobAPlayerAnimation(data.AffectedPlayers, data.InitiatorPlayer)
        return
      }

      switch (data.Spell) {
        case BountySpellVariant.SuddenDeath:
          data.description = 'Everyone loses chips in the amount of the shortest stack player on the tournament. Minus one chip.'
          spellName = 'Sudden Death'
          break;


        case BountySpellVariant.Resurrection:
          data.description = `${data.InitiatorPlayer.Name} has used Resurrection spell to recover all the chips he lost in the all in.`
          spellName = 'Resurrection'
          break;

        case BountySpellVariant.SkipBlindOnce:
          data.description = `By using this spell, ${data.InitiatorPlayer.Name} will skip one round of blinds.`
          spellName = 'Skip Blind Once'
          break;

        case BountySpellVariant.RotateSeats:
          data.isRotateSeats = true;
          data.affectedPlayer = data.AffectedPlayers[0]



          data.description = `${data.InitiatorPlayer.Name} used Rotate Seat spell to switch seats with ${data.affectedPlayer.Name} player`
          spellName = 'Rotate Seats'
          break;

        // case BountySpellVariant.Immunity:
        //   data.description = `${data.immunityPlayer.Name} blocked by using Immunity spell`
        //   break;


        /**
         * Immunity

            In this case, the server does not send a special message, 
            but the information about whether the player used the 
            immunity spell against executed spell is part of the 
            CustomMessageType.BountySpellExecuted = 52 message. The property 
            "UsedImmunity" of the affected player is true in case the 
            affected player used immunity spell.
         */

        case BountySpellVariant.ScanSpells:
          data.description = `Everyones hidden spells are now revealed.`
          break;

        default:
          break;
      }


      gameComponent.onActivateSpellRequest(data)

      // // IMMUNITY
      // data.AffectedPlayers.filter(player => player.UsedImmunity).sort((a, b) => {
      //   return a.Chips > b.Chips ? -1 : 1
      // }).forEach(player => {



      //   data.description = `${player.Name} blocked ${spellName} by using Immunity spell`
      //   data.isImmunity = true;
      //   data.immunityPlayer = player

      //   gameComponent.onActivateSpellRequest(data)

      // })





    }


    else if (msg.Response === 'MixTableVariantUpdate') {
      console.log("MixTableVariantUpdate MSG", msg)
      const gameComponent = this.getGameById(msg.MixTableVariantUpdate.IdTable);
      console.log("MixTableVariantUpdate gameComponent", msg.MixTableVariantUpdate.IdTable, gameComponent)

      if (gameComponent) {
        gameComponent.announceMixTableGame(msg.MixTableVariantUpdate)
      }
    }

    else if (msg.Response === 'TournamentInformation') {


      const gameComponent = this.getGameByTourId(msg.TournamentInformation.IdTournament);
      if (gameComponent) {
        gameComponent.setNbRebuy(msg.TournamentInformation.Players)
      }

      if (msg.TournamentInformation.Players) {
        const players = msg.TournamentInformation.Players;
        for (const player of players) {

          const gameComponent = this.getGameById(player.IdTable);
          if (gameComponent) {
            console.log("++player", player, player.CelebrityPlayer)
            gameComponent.playerSetBounty(player.IdPlayer, player.Bounty, player.CelebrityPlayer);

            gameComponent.playerSetSpells(player.IdPlayer, player.PlayerSpells);

          }
        }
      }

    } else if (msg.Response === 'ServerMsg') {
      for (const item of msg.ServerMsg) {

        if (item.MsgType === ServerMessage.Info) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {

            gameComponent.initTableParams(item.Info);
            gameComponent.gameHistoryEvent({ eventSource: 'Game', eventData: GameEvents.TableInit });

            for (const player of item.Info.Players.filter(el => el && el.PlayerCallTime)) {

              const playerCallTime = player.PlayerCallTime;
              // playerCallTime.ActivatedAt = new Date(playerCallTime.ActivatedAt)

              gameComponent.setPlayerGameStatus(playerCallTime)
            }
          }
        } else if (item.MsgType === ServerMessage.UseSpellResponse) {
          console.log("@UseSpellResponse", item)

          switch (item.Value2) {
            case BountySpellErrorCodes.NoError:
              break;
            case BountySpellErrorCodes.TableTypeIsNotTournament:
              this.notificationsService.showError('TableTypeIsNotTournament')
              break;
            case BountySpellErrorCodes.UnknownPlayer:
              this.notificationsService.showError('UnknownPlayer')

              break;
            case BountySpellErrorCodes.PlayerHasNoSpell:
              this.notificationsService.showError('PlayerHasNoSpell')

              break;
            case BountySpellErrorCodes.RejectedBuyNumberOfActivePlayers:
              this.notificationsService.showError('RejectedBuyNumberOfActivePlayers')

              break;
            case BountySpellErrorCodes.RejectedOtherPlayerRequestedFirst:
              this.notificationsService.showError('RejectedOtherPlayerRequestedFirst')

              break;
            case BountySpellErrorCodes.RotationRejectedPlayerDoesntExist:
              this.notificationsService.showError('RotationRejectedPlayerDoesntExist')

              break;
            case BountySpellErrorCodes.UnknownSpellVariant:
              this.notificationsService.showError('UnknownSpellVariant')

              break;
            case BountySpellErrorCodes.UnsupportedSpell:
              this.notificationsService.showError('UnsupportedSpell')

              break;
            case BountySpellErrorCodes.RejectedByImmunitySpell:
              this.notificationsService.showError('RejectedByImmunitySpell')

              break;
            case BountySpellErrorCodes.RejectedSameSpellTypeAlreadyActivated:
              this.notificationsService.showError('RejectedSameSpellTypeAlreadyActivated')

              break;
            case BountySpellErrorCodes.RejectedOtherPlayerActivatedRobAPlayer:
              this.notificationsService.showError('RejectedOtherPlayerActivatedRobAPlayer')

              break;
            case BountySpellErrorCodes.RejectedOtherPlayerActivatedSuddenDeath:
              this.notificationsService.showError('RejectedOtherPlayerActivatedSuddenDeath')

              break;
            default:
              break;
          }

        } else if (item.MsgType === ServerMessage.ActivateSpellRequest) {
          /*
          For "Sudden Death", "Rob A Player", "Resurrection", "Skip Blind Once", "Rotate Seats" 
          the server broadcast ServerMsgType.ActivateSpellRequest (703) message to the all players 
          in the tournament with the parameters:

          Type - type of message is ServerMstType.ActivateSpellRequest
          IdTable - the id of the table
          IdTournament - the id of the tournament
          IdPlayer - the id of the player who activated the spell
          Value - the variant of spell that is activated
          Where "Rotate Seats" has one more parameter:

          Value2 - the id of the player with whom the place is rotated
          */
          // TABLE CHAT TO TO

          // console.log('@ActivateSpellRequest')
          // const gameComponent = this.getGameByTourId(item.IdTournament);
          // gameComponent.onActivateSpellRequest(item)
        } else if (item.MsgType === ServerMessage.SpellExecuted) {








        } else if (item.MsgType === ServerMessage.NoSpellZone) {
          const gameComponent = this.getGameByTourId(item.IdTournament);
          if (gameComponent) {
            gameComponent.noSpellZoneStart()
          }
        }

        else if (item.MsgType === ServerMessage.GameStart) {
          // Not yet implemented
        } else if (item.MsgType === ServerMessage.GameEnd) { // kraj ruke
          // Not yet implemented
        } else if (item.MsgType === ServerMessage.HandStart) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.setJackpotValue(item.Values[5])
            gameComponent.handStart(item.Value);
            gameComponent.gameHistoryEvent({ eventSource: 'Game', eventData: GameEvents.HandStart });

          }


        }

        else if (item.MsgType === ServerMessage.StartedRabbitHunting) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.betControls.showOfferRabbitHunting = false;

            gameComponent.startedRabbitHuntingPlayerId = item.IdPlayer



            gameComponent.gameHistoryEvent({
              eventSource: gameComponent.getPlayerNameById(item.IdPlayer),
              eventData: GameEvents.RequestedRabbitHunting,
            });
          }
        }

        else if (item.MsgType === ServerMessage.GameStatusChanged) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.gameStatusChanged(item.Value, item.Cards);
            gameComponent.betControls.showReplaceCardsButtons = false

            if (item.Cards) {
              const cardsString = [];
              item.Cards.forEach(card => {
                cardsString.push(cardDecoder(card.Suit, card.Number));
              });

              let tempEventData;
              if (<GameStatus>item.Value === GameStatus.Flop) {
                tempEventData = GameEvents.DealtFlop;
              } else if (<GameStatus>item.Value === GameStatus.Turn) {
                tempEventData = GameEvents.DealtTurn;
              } else if (<GameStatus>item.Value === GameStatus.River) {
                tempEventData = GameEvents.DealtRiver;
              }

              gameComponent.gameHistoryEvent({
                eventSource: 'Game',
                eventData: tempEventData,
                cards: cardsString,
              });




              if (gameComponent.startedRabbitHuntingPlayerId) {

                item.Cards.forEach(card => {
                  const cardData: CardData = {
                    Suit: card.Suit,
                    Number: card.Number,
                    Name: cardDecoder(card.Suit, card.Number),
                    IsRabbitHunting: card.IsRabbitHunting, IsPublic: card.IsPublic

                  };

                  gameComponent.tableCardsController.addCommunityCardWithIndex(cardData, gameComponent.tableCardsController.communityCards.length);

                });


                gameComponent.showRabbitHunting()
                gameComponent.startedRabbitHuntingPlayerId = null
              }
            }

            if (item.Value === GameStatus.TripleDrawBettingRound1 ||
              item.Value === GameStatus.ReplaceCards1 ||
              item.Value === GameStatus.TripleDrawBettingRound2 ||
              item.Value === GameStatus.ReplaceCards2 ||
              item.Value === GameStatus.TripleDrawBettingRound3 ||
              item.Value === GameStatus.ReplaceCards3
            ) {
              gameComponent.setCardSelection(true)
            } else {
              gameComponent.setCardSelection(false)
            }
          }
        } else if (item.MsgType === ServerMessage.TimeBankStatus) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.playerSetBankTime(item.IdPlayer, item.Value);
          }
        } else if (item.MsgType === ServerMessage.TimeBankUsing) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.playerUsingBankTime(item.IdPlayer, item.Value);
          }
        } else if (item.MsgType === ServerMessage.Fold) { // ## OK
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.playerFoldCards(item.IdPlayer, item.Value, item.Value2);
            gameComponent.gameHistoryEvent({
              eventSource: gameComponent.getPlayerNameById(item.IdPlayer),
              eventData: GameEvents.PlayerFold
            });
          }
        } else if (item.MsgType === ServerMessage.BidAnte) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.playerBidAnte(item.IdPlayer, item.Value, item.Value2);

            const money = gameComponent.isTournament ? item.Value : CurrencyPipe.prototype.transform(
              CurrencyDeviderPipe.prototype.transform(item.Value, gameComponent.currency),
              gameComponent.currency);

            gameComponent.gameHistoryEvent({
              eventSource: gameComponent.getPlayerNameById(item.IdPlayer),
              eventData: GameEvents.PlayerBidAnte + ' [' + money + ']'
            });
          }
        } else if (item.MsgType === ServerMessage.BidSmallBlind) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.playerBidSmallBlind(item.IdPlayer, item.Value, item.Value2);

            const money = gameComponent.isTournament ? item.Value : CurrencyPipe.prototype.transform(
              CurrencyDeviderPipe.prototype.transform(item.Value, gameComponent.currency),
              gameComponent.currency);

            gameComponent.gameHistoryEvent({
              eventSource: gameComponent.getPlayerNameById(item.IdPlayer),
              eventData: GameEvents.PlayerBidSmallBlind + ' [' + money + ']'
            });
          }
        } else if (item.MsgType === ServerMessage.BidBigBlind) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.playerBidBigBlind(item.IdPlayer, item.Value, item.Value2);

            const money = gameComponent.isTournament ? item.Value : CurrencyPipe.prototype.transform(
              CurrencyDeviderPipe.prototype.transform(item.Value, gameComponent.currency),
              gameComponent.currency);

            gameComponent.gameHistoryEvent({
              eventSource: gameComponent.getPlayerNameById(item.IdPlayer),
              eventData: GameEvents.PlayerBidBigBlind + ' [' + money + ']'
            });
          }
        } else if (item.MsgType === ServerMessage.BidCheck) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.playerBidCheck(item.IdPlayer, item.Value, item.Value2);

            let money = '';
            if (item.Value) {
              money = gameComponent.isTournament ? item.Value : CurrencyPipe.prototype.transform(
                CurrencyDeviderPipe.prototype.transform(item.Value, gameComponent.currency),
                gameComponent.currency);
              money = ' [' + money + ']';
            }
            gameComponent.gameHistoryEvent({
              eventSource: gameComponent.getPlayerNameById(item.IdPlayer),
              eventData: GameEvents.PlayerBidCheck + money
            });
          }
        } else if (item.MsgType === ServerMessage.BidBet) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.playerBidBet(item.IdPlayer, item.Value, item.Value2);

            const money = gameComponent.isTournament ? item.Value : CurrencyPipe.prototype.transform(
              CurrencyDeviderPipe.prototype.transform(item.Value, gameComponent.currency),
              gameComponent.currency);

            gameComponent.gameHistoryEvent({
              eventSource: gameComponent.getPlayerNameById(item.IdPlayer),
              eventData: GameEvents.PlayerBidBet + ' [' + money + ']'
            });
          }
        } else if (item.MsgType === ServerMessage.BidCall) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.playerBidCall(item.IdPlayer, item.Value, item.Value2);

            const money = gameComponent.isTournament ? item.Value : CurrencyPipe.prototype.transform(
              CurrencyDeviderPipe.prototype.transform(item.Value, gameComponent.currency),
              gameComponent.currency);

            gameComponent.gameHistoryEvent({
              eventSource: gameComponent.getPlayerNameById(item.IdPlayer),
              eventData: GameEvents.PlayerBidCall + ' [' + money + ']'
            });
          }
        } else if (item.MsgType === ServerMessage.BidRaise) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.playerBidRaise(item.IdPlayer, item.Value, item.Value2);

            const money = gameComponent.isTournament ? item.Value : CurrencyPipe.prototype.transform(
              CurrencyDeviderPipe.prototype.transform(item.Value, gameComponent.currency),
              gameComponent.currency);

            gameComponent.gameHistoryEvent({
              eventSource: gameComponent.getPlayerNameById(item.IdPlayer),
              eventData: GameEvents.PlayerBidRaise + ' [' + money + ']'
            });
          }
        }
        else if (item.MsgType === ServerMessage.BidStraddle) {

          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.playerBidRaise(item.IdPlayer, item.Value, item.Value2, true);

            const currency = gameComponent.isTournament ? Currency.Tournament : gameComponent.currency;
            const money = CurrencyPipe.prototype.transform(
              CurrencyDeviderPipe.prototype.transform(item.Value, currency),
              currency);

            gameComponent.gameHistoryEvent({
              eventSource: gameComponent.getPlayerNameById(item.IdPlayer),
              eventData: 'posted straddle [' + money + ']'
            });
          }
        }
        else if (item.MsgType === ServerMessage.BidAllIn) {


          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.playerBidAllIn(item.IdPlayer, item.Value, item.Value2);

            const money = gameComponent.isTournament ? item.Value : CurrencyPipe.prototype.transform(
              CurrencyDeviderPipe.prototype.transform(item.Value, gameComponent.currency),
              gameComponent.currency);

            gameComponent.gameHistoryEvent({
              eventSource: gameComponent.getPlayerNameById(item.IdPlayer),
              eventData: GameEvents.PlayerBidAllIn + ' [' + money + ']'
            });
          }
        } else if (item.MsgType === ServerMessage.Dealer) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.setDealer(item.IdPlayer);
            gameComponent.gameHistoryEvent({
              eventSource: gameComponent.getPlayerNameById(item.IdPlayer),
              eventData: GameEvents.PlayerDealer
            });
          }
        } else if (item.MsgType === ServerMessage.PlayerTurnChange) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {


            gameComponent.playerTurnChange(
              item.IdPlayer,
              item.Value,
              item.Value2, // minimumRaise
              item.Value3,
              item.Values[0],
              item.Values[1],
              item.Values[2],
              item.Values[3],
              item.Values[4],
              item.Values[5],
              item.Values[6]
            );




          }
        } else if (item.MsgType === ServerMessage.SendCardHidden) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            // gameComponent.sendHiddenCardToPlayer(item.IdPlayer, item.Cards, item.Value2, item.AllCards);
            gameComponent.sendCardsToPlayer(item.IdPlayer, item.Cards, item.AllCards);


            if (gameComponent.gameStatus === GameStatus.TripleDrawBettingRound1) {
              gameComponent.setCardSelection(true)
            }

          }
          // } else if (item.MsgType === ServerMessage.SendAllCardsToPlayer) {
          //   const gameComponent = this.getGameById(item.IdTable);
          //   if (gameComponent) {
          //     gameComponent.playerGetsAllCards(item.IdPlayer, item.Cards);
          //   }
        } else if (item.MsgType === ServerMessage.RevealCard) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.playerRevealCard(item.IdPlayer, item.Cards);

            const cardsString = [];
            if (item.Cards) {
              item.Cards.forEach(card => {
                cardsString.push(cardDecoder(card.Suit, card.Number));
              });
            }

            gameComponent.gameHistoryEvent({
              eventSource: gameComponent.getPlayerNameById(item.IdPlayer),
              eventData: GameEvents.PlayerRevealCard,
              cards: cardsString,
            });
          }
        } else if (item.MsgType === ServerMessage.CardDiscard) {
          // Not yet implemented
        } else if (item.MsgType === ServerMessage.ReturnBackMoney) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.returnBackMoneyToPlayer(item.IdPlayer, item.Value, item.Value2);
          }
        } else if (item.MsgType === ServerMessage.MoveToPot) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.moveToPot(item.Pots);
            // gameComponent.gameHistoryEvent({
            //   eventSource: 'Game',
            //   eventData: GameEvents.MoveToPot
            // });
          }
        } else if (item.MsgType === ServerMessage.RunningTwice) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            // ToDo: do something when run it twice occure
          }
        } else if (item.MsgType === ServerMessage.CommunityCards) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.communityCards(item.Cards, item.CommunityCardsR2T1, item.CommunityCardsR2T2);
            const cardsString = [];
            if (item.Cards) {
              item.Cards.forEach(card => {
                cardsString.push(cardDecoder(card.Suit, card.Number));
              });
            }

            if (item.CommunityCardsR2T1) {
              item.CommunityCardsR2T1.forEach(card => {
                cardsString.push(cardDecoder(card.Suit, card.Number));
              });
            }

            if (item.CommunityCardsR2T2) {
              item.CommunityCardsR2T2.forEach(card => {
                cardsString.push(cardDecoder(card.Suit, card.Number));
              });
            }

            gameComponent.gameHistoryEvent({
              eventSource: 'Game',
              eventData: GameEvents.CommunityCards,
              cards: cardsString,
            });
          }
        } else if (item.MsgType === ServerMessage.PotResult) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.potResult(item.Pot);
          }
        } else if (item.MsgType === ServerMessage.WinnerByFold) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.winnerByFold(item.IdPlayer, item.Value, item.Value2, item.Cards);
            gameComponent.gameHistoryEvent({
              eventSource: gameComponent.getPlayerNameById(item.IdPlayer),
              eventData: GameEvents.WinnerByFold
            });
          }
        } else if (item.MsgType === ServerMessage.WinnerByStrongestHand) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.winnerByStrongestHand(item.IdPlayer, item.Value, item.Value2, item.Cards);

            const cardsString = [];
            if (item.Cards) {
              item.Cards.forEach(card => {
                cardsString.push(cardDecoder(card.Suit, card.Number));
              });
            }

            gameComponent.gameHistoryEvent({
              eventSource: gameComponent.getPlayerNameById(item.IdPlayer),
              eventData: GameEvents.WinnerByStrongestHand,
              cards: cardsString,
            });
          }
        } else if (item.MsgType === ServerMessage.WinnerSplit) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.winnerSplit(item.IdPlayer, item.Value, item.Value2, item.Cards);
            gameComponent.gameHistoryEvent({
              eventSource: gameComponent.getPlayerNameById(item.IdPlayer),
              eventData: GameEvents.WinnerSplit
            });
          }
        } else if (item.MsgType === ServerMessage.PlayerTurnCardsReturnChange) {


          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            // 01           gameComponent.enableCardSelection(item.IdPlayer)
            gameComponent.playerTurnCardsReturnChange(item.IdPlayer, item.Values[0], item.Value);


          }

        } else if (item.MsgType === ServerMessage.SkipNextHand) {

          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.skipNextHand();
          }
        } else if (item.MsgType === ServerMessage.AskShowCard) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.askShowCard(item.IdPlayer, item.Value);
          }
        } else if (item.MsgType === ServerMessage.ReplaceCardsPeriodOver) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.replaceCardPeriodOver(item.IdPlayer, item.Value);
            let eventData = '';
            if (!item.Value) {
              eventData = GameEvents.StandPat;
            } else {
              eventData = GameEvents.DiscardedCards + ' ' + item.Value + ' Cards';
            }
            gameComponent.gameHistoryEvent({
              eventSource: gameComponent.getPlayerNameById(item.IdPlayer),
              eventData: eventData,
            });
          }
        } else if (item.MsgType === ServerMessage.PlayerBuyChips) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.playerBuyChips(item.IdPlayer, item.Value);
          }

        } else if (item.MsgType === ServerMessage.PlayerLeave) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.gameHistoryEvent({
              eventSource: gameComponent.getPlayerNameById(item.IdPlayer),
              eventData: GameEvents.PlayerLeaveTable
            });
            gameComponent.playerLeaveTable(item.IdTable, item.IdPlayer, item.PlayerData);
          }
        } else if (item.MsgType === ServerMessage.PlayerStatus) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.playerStatus(item.IdPlayer, item.Value, item.Value2, item.PostedStraddle);
          }
        } else if (item.MsgType === ServerMessage.PlayerTakeSeat) {

          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.playerTakeSeat(item.IdPlayer, item.Value, item.PlayerData, item.Value2);
            gameComponent.gameHistoryEvent({
              eventSource: gameComponent.getPlayerNameById(item.IdPlayer),
              eventData: GameEvents.PlayerTakeSeat
            });

            if (item.PlayerCallTime) {
              const playerCallTime = item.PlayerCallTime;
              // playerCallTime.ActivatedAt = new Date(playerCallTime.ActivatedAt)

              gameComponent.setPlayerGameStatus(playerCallTime)

            }
          }
        } else if (item.MsgType === ServerMessage.DisconnectionProtectionTime) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.setPlayerDisconnectProtection(item);
          }
        } else if (item.MsgType === ServerMessage.PlayerSetRunItTwice) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.playerR2T(item.IdPlayer, item.Value ?? 0);
          }
        } else if (item.MsgType === ServerMessage.TournamentCanceled) {
          // Not yet implemented
        } else if (item.MsgType === ServerMessage.TournamentEndResult) {
          // Not yet implemented


        } else if (item.MsgType === ServerMessage.BlindLevel) {
          const gameComponent = this.getGameByTourId(item.IdTournament);
          if (gameComponent) {
            gameComponent.changeBlindLevel(item.Value, item.Value2, item.Value3);
          }
        } else if (item.MsgType === ServerMessage.GamePause) {
          const gameComponent = this.getGameByTourId(item.IdTournament);
          if (gameComponent) {
            gameComponent.gamePause(item);
            gameComponent.gameHistoryEvent({
              eventSource: 'Game',
              eventData: GameEvents.GamePause
            });
          }
        } else if (item.MsgType === ServerMessage.VariantChanged) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.variantChanged(item.Value);
            gameComponent.gameHistoryEvent({
              eventSource: 'Game',
              eventData: GameEvents.VariantChanged
            });
          }
        } else if (item.MsgType === ServerMessage.LimitChanged) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.limitChanged(item.Value);
            gameComponent.gameHistoryEvent({
              eventSource: 'Game',
              eventData: GameEvents.LimitChanged
            });
          }
        } else if (item.MsgType === ServerMessage.PotSplitted) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.potSplitted(item.Pots);
          }
        } else if (item.MsgType === ServerMessage.EndOfHand) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.handEnd(item.Value);
            gameComponent.gameHistoryEvent({
              eventSource: 'Game',
              eventData: GameEvents.EndOfHand
            });
            gameComponent.removeRabbitHunting()
            gameComponent.removeAntePots()

            gameComponent.startedRabbitHuntingPlayerId = null
            gameComponent.betControls.showOfferRabbitHunting = null
            gameComponent.betControls.rabbitHuntingPrize = null

          }
        } else if (item.MsgType === ServerMessage.HandType) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.playerHandType(item.Value, item.Cards);
          }
        } else if (item.MsgType === ServerMessage.HandTypeDoubleBoardGames) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.playerHandTypeDoubleBoardGames(item.Value, item.Cards);

          }
        }else if (item.MsgType === ServerMessage.PlayerPlayStatistic) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.setPlayerStatistics(
              item.IdPlayer,
              item.Values[0],
              item.Values[1],
              item.Values[2],
              item.Values[3]);
          }
        } else if (item.MsgType === ServerMessage.MTTTableAssigned) {
          // Not yet implemented
        } else if (item.MsgType === ServerMessage.AskPlayerRebuy) {
          let gameComponent: GameComponent;
          if (item.IdTournament) {
            gameComponent = this.getGameByTourId(item.IdTournament);
          } else {
            gameComponent = this.getGameById(item.IdTable);
          }
          if (gameComponent) {


            gameComponent.askPlayerRebuy(item.Values, item.Value2);
          }
        } else if (item.MsgType === ServerMessage.PlayerDoNotRebuy) {
          // Not yet implemented
        } else if (item.MsgType === ServerMessage.AskPlayerAddOn) {
          // If value doesn't exist we need to send ws req to get updated value
          if(!item.Value) {
            this.dataManager.joinTable(item.IdTable);
            return;
          }
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.askPlayerAddOn(item.IdPlayer, item.Value);
          }
        } else if (item.MsgType === ServerMessage.Chat) {

          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.chatMessage(item);
          } else if (item.IdTable == 0 || item.IdTable === undefined) {
            this.mainService.lobbyChat$.next(item);
          }
        } else if (item.MsgType === ServerMessage.PlayerBalance) {
          // Not yet implemented
        } else if (item.MsgType === ServerMessage.Reinstall) {
          // Not yet implemented
        } else if (item.MsgType === ServerMessage.PlayerWaiting) {
          // Not yet implemented
        } else if (item.MsgType === ServerMessage.MTTStarting) {
          // Not yet implemented
        } else if (item.MsgType === ServerMessage.MTTBlindChanged) {
          // Not yet implemented
        } else if (item.MsgType === ServerMessage.DealerTipStatus) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.dealerTipStatus(
              item.Value,
              item.Value2,
              item.Value3,
              item.Text
            );
          }
        } else if (item.MsgType === ServerMessage.TipUpgradeResponse) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.tipUpgradeResponse(
              item.IdPlayer,
              item.Value,
              item.Value2,
              item.Value3
            );
          }
        } else if (item.MsgType === ServerMessage.PrivateTablePasswordResponse) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            if (!item.Value) {
              gameComponent.showPasswordDialog();
            }
          }
        } else if (item.MsgType === ServerMessage.SpinNGoMultiplier) {
          const gameComponent = this.getGameByTourId(item.IdTournament);
          if (gameComponent) {
            gameComponent.showSpinAndGo(item.Values, item.Value);
          }
        } else if (item.MsgType === ServerMessage.OfferRabbitHunting) {
          const gameComponent = this.getGameById(item.IdTable);
          if (gameComponent) {
            gameComponent.betControls.showOfferRabbitHunting = true
          }
        } else if (item.MsgType === ServerMessage.RabbitHuntingPrize) {
          if (item.Value > 0) {
            const gameComponent = this.getGameById(item.IdTable);
            if (gameComponent) {
              gameComponent.betControls.rabbitHuntingPrize = {
                prize: item.Value,
                currencyId: item.Value2,
                chargingType: item.Value3,
                currency: this.managerService.currencyInfo.find(el => el.Id === item.Value2)
              }
            }
          }

        }
      }
    } else if (msg.Response === 'HandStrengthResponse') {

    } else if (msg.Response === 'LiveLobbyRingSelected') {

      const gameComponent = this.getGameById(msg.LiveLobbyRingSelected.IdTable);
      if (gameComponent) {
        gameComponent.updateWaitingList(msg.LiveLobbyRingSelected.WaitingListName, msg.LiveLobbyRingSelected.Players);
      } else {

      }
    } else if (msg.Response === 'MemberPreference') {
      this.authService.user.memberProfile.Preferences = msg.MemberPreference;
      // this.store.dispatch(new AppActions.AuthAction.MemberPreferenceReceived(msg.MemberPreference));
      this.managerService.updateUserPreference(msg.MemberPreference)

      if (msg.MemberPreference.PlayerNotes) {
        for (let i = 0; i < this.gameComponents.length; i++) {
          this.gameComponents[i].markNotedPlayers(msg.MemberPreference.PlayerNotes, msg.MemberPreference.PlayerColor);
        }
      }
    } else if (msg.Response === 'TournamentInformation') {

      // if (msg.TournamentInformation.Players) {
      //   const players = msg.TournamentInformation.Players;
      //   for (const player of players) {

      //     const gameComponent = this.getGameById(player.IdTable);
      //     if (gameComponent) {
      //       gameComponent.playerSetBounty(player.IdPlayer, player.Bounty, !!player.CelebrityPlayer);
      //     }
      //   }
      // }
    } else if (msg.Response === 'LiveLobbyTournament') {
      const gameComponent = this.getGameByTourId(msg.LiveLobbyTournament.IdTournament);
      if (gameComponent) {
        gameComponent.setBlind(msg.LiveLobbyTournament.Blind)
        gameComponent.setBlindIncreaseTimer(msg.LiveLobbyTournament.BlindIncreaseIn);
        gameComponent.setGameBreakTimer(msg.LiveLobbyTournament.BreakIn);
        gameComponent.setRebuyEndIn(msg.LiveLobbyTournament.ReBuyEndIn)
      }
    }
  }

  getGameById(IdTable: number): GameComponent {
    for (let i = 0; i < this.gameComponents.length; i++) {
      if (this.gameComponents[i].id === IdTable) {
        return this.gameComponents[i];
      }
    }
    return null;
  }

  getGameByTourId(tournamentId: number, IdTable?: number): GameComponent {
    for (let i = 0; i < this.gameComponents.length; i++) {
      if (this.gameComponents[i].idTournament === tournamentId) {
        return this.gameComponents[i];
      }
    }
    return null;
  }

  getActiveGameComponent(): GameComponent {

    for (let index = 0; index < this.gameComponents.length; index++) {
      if (this.gameComponents[index].isActive) {
        return this.gameComponents[index];
      }
    }
  }
}
